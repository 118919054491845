import React from 'react';
import authenticatedUserInformationService from '../../services/authenticatedUserInformation.service';
import { Route, Redirect } from 'react-router';

const PrivatRoute = (props) => {

  let privatRoute = null;

  if (authenticatedUserInformationService.isUserLogedIn()) {
    if (props.forAllReles) {
      privatRoute = <Route {...props} />
    } else {
      const userRole = authenticatedUserInformationService.getUserRole();
      if (props.forRole.toLowerCase() === userRole.toLowerCase()) {
        privatRoute = <Route {...props} />
      } else {
        privatRoute = <Redirect to='/loading' />
      }
    }
  } else {
    privatRoute = <Redirect to='/' />
  }

  return (
    privatRoute
  );
}

PrivatRoute.defaultProps = {
  forAllReles: false
}

export default PrivatRoute;