import axios from "axios";
import { PROFILE_SERVICE_API, SERVER_IP } from "../path";
import AuthenticatedUserInformationService from "./authenticatedUserInformation.service";

class CitiesStatesService {

    accessToken = AuthenticatedUserInformationService.getUserToken();

    getAllStates = async() => {
        try {
            const response = await axios({
                method: "GET",
                url: `${PROFILE_SERVICE_API}/states`,
                headers: { Authorization: "Bearer " + this.accessToken }
                
            })
            if (response.status === 200) {
                let statesOptions = [];
                response.data.states.forEach((state) => {
                    statesOptions.push({
                        value: state,
                        label: state
                    })
                });
                return statesOptions;
            }
        } catch (error) {
            console.log(error)
        }
    }

    getAllCitiesInState = async(state) => {
        try {
            const response = await axios({
                method: "GET",
                url: `${PROFILE_SERVICE_API}/states/cities?state=${state}`,
                headers: { Authorization: "Bearer " + this.accessToken },
            })
            if (response.status === 200) {
                let citiesInStateOptions = [];
                response.data.cities.forEach((city) => {
                    citiesInStateOptions.push({
                        value: city,
                        label: city
                    })
                });
                return citiesInStateOptions;              
            }
        } catch (error) {
            console.log(error)
        }
    }

}

export default new CitiesStatesService();
