export const basicAxiosErrorHandler = (errorHandler, error) => {
  if (error.response) {
    errorHandler(error.response.errorCode);
  }
  else if (error.request) {
    errorHandler(error.request);
  } else {
    errorHandler(error);
  }
}

export const axiosConsloeLogErrorHandler = (error) => {
  if (error.response) {
    console.log(error.response.errorCode);
  }
  else if (error.request) {
    console.log(error.response.errorCode);
  } else {
    console.log(error.response.errorCode);
  }
}