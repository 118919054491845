export const START_FETCHING_CURRENT_SPECIALIST = 'START_FETCHING_CURRENT_SPECIALIST';
export const SUCCESS_FETCHING_CURRENT_SPECIALIST = 'SUCCESS_FETCHING_CURRENT_SPECIALIST';
export const ERROR_FETCHING_CURRENT_SPECIALIST = 'ERROR_FETCHING_CURRENT_SPECIALIST';
export const START_UPDATING_CURRENT_SPECIALIST = 'START_UPDATING_CURRENT_SPECIALIST';
export const SUCCESS_UPDATING_CURRENT_SPECIALIST = 'SUCCESS_UPDATING_CURRENT_SPECIALIST';
export const ERROR_UPDATING_CURRENT_SPECIALIST = 'ERROR_UPDATING_CURRENT_SPECIALIST';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_MIDDLE_NAME = 'SET_MIDDLE_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_DEGREE = 'SET_DEGREE';
export const SET_EDUCATIONAL_INSTITUTION = 'SET_EDUCATIONAL_INSTITUTION';
export const SET_DATE_OF_BIRTH = 'SET_DATE_OF_BIRTH';
export const SET_SSN = 'SET_SSN';
export const SET_PHONE_NUMBER = 'SET_PHONE_NUMBER';
export const SET_SHORT_DESCRIPTION = 'SET_SHORT_DESCRIPTION';
export const SET_FULL_DESCRIPTION = 'SET_FULL_DESCRIPTION';
export const SET_STREET = 'SET_STREET';
export const SET_APT = 'SET_APT';
export const SET_CITY = 'SET_CITY';
export const SET_STATE = 'SET_STATE';
export const SET_ZIP_CODE = 'SET_ZIP_CODE';
export const SET_TIME_ZONE = 'SET_TIME_ZONE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_DEFAULT_PHOTO = 'SET_DEFAULT_PHOTO';
export const SET_PHOTO = 'SET_PHOTO';
export const SET_INHOUSE = 'SET_INHOUSE';
