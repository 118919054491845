import { expressionReg } from "../../const/regular";

const blacklist = [
    '123456789',
    '111111111',
    '222222222',
    '333333333',
    '444444444',
    '555555555',
    '666666666',
    '777777777',
    '888888888',
    '999999999',
    '000000000'
];

export function isSsnValid(ssn) {
  if (!expressionReg.test(ssn)) {
    return false;
  }

  return blacklist.indexOf(ssn.replace(/\D/g, '')) === -1;
}