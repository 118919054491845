import {
    DELETE_PHOTO,
    NEW_CONCERNS,
    NEW_DATE,
    NEW_LAST_NAME,
    NEW_NAME,
    NEW_PARENT_LAST_NAME,
    NEW_PARENT_NAME,
    NEW_STRENGTHS,
    NEW_TIME_ZONE,
    SET_CURRENT_USER,
    UPDATE_USER_PHOTO,
    USER_BIRTHDAY,
    START_FETCHING_CURRENT_USER,
    ERROR_FETCHING_CURRENT_USER
} from "../types/editCurrentUser";


export const setCurrentUser = (currentUser) => ({
    type: SET_CURRENT_USER,
    payload: { currentUser }
})

export const startFetchingCurrentUser = () => ({
    type: START_FETCHING_CURRENT_USER
})

export const errorFetchingCurrentUser = (error) => ({
    type: ERROR_FETCHING_CURRENT_USER,
    payload: error
})

export const changeFirstName = (newName) => ({
    type: NEW_NAME,
    payload: { newName }
})

export const changeLastName = (newLastName) => ({
    type: NEW_LAST_NAME,
    payload: { newLastName }
})

export const changeStrengths = (newStrengths) => ({
    type: NEW_STRENGTHS,
    payload: { newStrengths }
})

export const changeConcerns = (newConcerns) => ({
    type: NEW_CONCERNS,
    payload: { newConcerns }
})

export const changeDate = (date) => ({
    type: NEW_DATE,
    payload: { date }
})

export const changeParentFirstName = (newName) => ({
    type: NEW_PARENT_NAME,
    payload: { newName }
})

export const changeParentLastName = (newName) => ({
    type: NEW_PARENT_LAST_NAME,
    payload: { newName }
})

export const changeTimeZone = (timeZone, timeZoneName) => ({
    type: NEW_TIME_ZONE,
    payload: { timeZone, timeZoneName }
})

export const setUserBirthday = (currentUser) => ({
    type: USER_BIRTHDAY,
    payload: { currentUser }
})

export const updateUserPhoto = (currentUserPhoto) => ({
    type: UPDATE_USER_PHOTO,
    payload: { currentUserPhoto }
})

export const setDeleteUserPhoto = () => ({
    type: DELETE_PHOTO
})