export const START_FETCHING_CURRENT_USER = 'START_FETCHING_CURRENT_USER'
export const ERROR_FETCHING_CURRENT_USER = 'ERROR_FETCHING_CURRENT_USER'
export const SET_CURRENT_USER = 'GET_CURRENT_USER'
export const SET_ERROR_NAME_EDIT_USER = 'SET_ERROR_NAME_EDIT_USER'
export const NEW_NAME = 'NEW_NAME'
export const NEW_LAST_NAME = 'NEW_LAST_NAME'
export const NEW_STRENGTHS = 'NEW_STRENGTHS'
export const NEW_CONCERNS = 'NEW_CONCERNS'
export const NEW_DATE = 'NEW_DATE'
export const NEW_PARENT_NAME = 'NEW_PARENT_NAME'
export const NEW_PARENT_LAST_NAME = 'NEW_PARENT_LAST_NAME'
export const NEW_TIME_ZONE = 'NEW_TIME_ZONE'
export const DELETE_PHOTO = 'DELETE_PHOTO'
export const UPDATE_USER_PHOTO = 'UPDATE_USER_PHOTO'
export const USER_BIRTHDAY = 'USER_BIRTHDAY'