import dateFnsFormat from "date-fns/format";
import moment from "moment-timezone";

export const formatDateToMMDDYYYY = (date) => {
  if (date) {
    const newDate = date.slice(0, 10);
    const newArr = newDate.split("-");
    const dateForInput = `${newArr[1]}/${newArr[2]}/${newArr[0]}`;
    return dateForInput;
  }
};

export const formatDateToMMDDYYYYwithoutZero = (date) => {
  if (date) {
    const newDate = date.slice(0, 10);
    const newArr = newDate.split("-");
    const dateForInput = `${newArr[1]}/${newArr[2]}/${newArr[0]}`;
    return dateForInput;
  }
};

export const formatDateToYYYYMMDDT = (date) => {
  if (date) {
    return dateFnsFormat(new Date(date), "yyyy-MM-dd'T'HH:mm:ss.SSS");
  }
};

export const createDateFormatMMDDYYYY = () => {
  const date = new Date();
  let month = String(date.getMonth() + 1);
  let day = String(date.getDate());
  const year = String(date.getFullYear());

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return `${month}/${day}/${year}`;
};

export const formatTimeZone = (timeZone, currentDate) => {
  const dateObj = {
    "-5": "America/New_York",
    "-6": "America/Chicago",
    "-7": "America/Los_Angeles",
    "-8": "America/Anchorage",
    "-10": "Pacific/Honolulu",
  };
  if (currentDate) {
    let date = new Date(currentDate);
    let local = moment.tz(date, dateObj[timeZone]);
    return local.format();
  } else {
    let date = new Date();
    let local = moment.tz(date, dateObj[timeZone]);
    return local.format();
  }
  // date.toLocaleString("en-US", { timeZone: dateObj[timeZone] });
};
