import React from "react";

function PlayButtonSVG() {
  return (
    <svg
      width="30"
      height="31"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3002_11748)">
        <path
          d="M15 28.7617C11.519 28.7617 8.18064 27.3789 5.71922 24.9175C3.25781 22.4561 1.875 19.1177 1.875 15.6367C1.875 12.1558 3.25781 8.81736 5.71922 6.35594C8.18064 3.89453 11.519 2.51172 15 2.51172C18.481 2.51172 21.8194 3.89453 24.2808 6.35594C26.7422 8.81736 28.125 12.1558 28.125 15.6367C28.125 19.1177 26.7422 22.4561 24.2808 24.9175C21.8194 27.3789 18.481 28.7617 15 28.7617ZM15 30.6367C18.9782 30.6367 22.7936 29.0564 25.6066 26.2433C28.4196 23.4303 30 19.615 30 15.6367C30 11.6585 28.4196 7.84316 25.6066 5.03012C22.7936 2.21707 18.9782 0.636719 15 0.636719C11.0218 0.636719 7.20644 2.21707 4.3934 5.03012C1.58035 7.84316 0 11.6585 0 15.6367C0 19.615 1.58035 23.4303 4.3934 26.2433C7.20644 29.0564 11.0218 30.6367 15 30.6367Z"
          fill="#FCFCFC"
        />
        <path
          d="M11.7581 10.1157C11.9115 10.0368 12.0836 10.0016 12.2556 10.0142C12.4277 10.0268 12.5929 10.0866 12.7331 10.187L19.2956 14.8745C19.4172 14.9612 19.5162 15.0757 19.5846 15.2084C19.6529 15.3412 19.6886 15.4883 19.6886 15.6376C19.6886 15.7869 19.6529 15.934 19.5846 16.0668C19.5162 16.1995 19.4172 16.314 19.2956 16.4007L12.7331 21.0882C12.5929 21.1886 12.4278 21.2483 12.2559 21.2609C12.0839 21.2734 11.9119 21.2384 11.7586 21.1595C11.6053 21.0806 11.4767 20.961 11.387 20.8138C11.2973 20.6666 11.2499 20.4975 11.25 20.3251V10.9501C11.2498 10.7778 11.2972 10.6087 11.3868 10.4615C11.4764 10.3143 11.6049 10.1947 11.7581 10.1157Z"
          fill="#FCFCFC"
        />
      </g>
      <defs>
        <clipPath id="clip0_3002_11748">
          <rect
            width="30"
            height="30"
            fill="white"
            transform="translate(0 0.636719)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default PlayButtonSVG;
