import React, { useEffect, useState } from "react";
import style from "../SpecialistInfoDetailPage/specialistInfoDetailPage.module.scss";
import { SERVER_IP } from "../../path";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import AuthenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import { useHistory } from "react-router-dom";
import paymentService from "../../services/payment.service";
import BlueButton from "../uiComponents/buttons/BlueButton/BlueButton";
import Avatar from "../uiComponents/Avatar/Avatar";

function GroupInfoDetailPage({
  degree,
  linkText = "See all groups",
  photoUrl,
}) {
  const history = useHistory();
  const fromWhere = history.location.state?.fromWhere
    ? history.location.state?.fromWhere
    : "null";
  const { groupId } = useParams();
  let userInfo = AuthenticatedUserInformationService.getUserInfo();

  const [isActiveButton, setIsActiveButton] = useState({
    active: true,
    style: "disabled",
  });

  const [redirectLink, setRedirectLink] = useState("false");

  const buyLessons = () => {
    window.location = redirectLink;
  };

  function getLessons() {
    let bundleInfo = JSON.parse(localStorage.getItem("infoForSession"));
    let infoForPay = {
      studentId: "",
      planId: "",
      groupId: "",
    };
    if (userInfo?.access_token) {
      infoForPay = {
        studentId: userInfo?.profile.sub,
        planId: bundleInfo?.bundleId,
        groupId: groupId,
      };
      try {
        paymentService.getGroupSessionPaymentLink(infoForPay.studentId, infoForPay.groupId, infoForPay.planId)
          .then((response) => {
            setIsActiveButton({
              active: false,
              style: "",
            });
            return response;
          })
          .then((response) => {
            setRedirectLink(response?.data?.url);
          })
          .catch((error) => {
            setRedirectLink('none');
          });
      } catch (error) {
        console.log(error);
      }
    }
  }

  let rateInfo = JSON.parse(localStorage.getItem("infoForSession"));
  const [showDefaulPhoto, setShowDefaultPhoto] = useState("false");
  let path;
  if (fromWhere === "buy") {
    linkText = "See all groups";
    path = "/find-group";
  } else {
    linkText = "Back to sessions";
    path = "/sessions";
  }



  useEffect(() => {
    if (fromWhere === "buy") {
      getLessons();
    }
  }, []);




  useEffect(() => {
    if (photoUrl) {
      setShowDefaultPhoto(false);
    } else {
      setShowDefaultPhoto(true);
    }
  }, [photoUrl]);

  return (
    <div className={style.wrapper}>
      <Link to={path}>&lt; {linkText}</Link>
      {showDefaulPhoto && (
        <Avatar
          className={style.imgDetailInfo}
          photoUrl="/img/avatar.png"
          alt="logo of group"
        />
      )}
      {!showDefaulPhoto && (
        <Avatar
          className={style.imgDetailInfo}
          photoUrl={photoUrl}
          alt="logo of group"
        />
      )}
      {fromWhere === "buy" ? (
        <>
          <p className={style.price}>{`\$${rateInfo.price}`}</p>
          <p>
            {rateInfo.lessonCount} sessions, twice a week {degree}
          </p>
          <p>Session rate: {rateInfo.sessionRate}</p>
          {redirectLink !== "false" ? (
            <BlueButton
              className={style[isActiveButton.style]}
              onClick={buyLessons}
              disabled={isActiveButton.active}>
              Book group
            </BlueButton>
            // <button
            //   className={style[isActiveButton.style]}
            //   onClick={buyLessons}
            //   disabled={isActiveButton.active}
            // >
            //   Book group
            // </button>
          ) : (
            ""
          )}
        </>
      ) : null}
    </div>
  );
}

export default GroupInfoDetailPage;
