import React from "react";

function SixthBenefitsSVG() {
  return (
    <svg
      width="315"
      height="315"
      viewBox="0 0 315 315"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M157.5 0C115.702 0 81.8182 33.8839 81.8182 75.6818C81.8182 79.0708 79.0708 81.8182 75.6818 81.8182C33.8839 81.8182 0 115.702 0 157.5C0 199.298 33.8839 233.182 75.6818 233.182C79.0708 233.182 81.8182 235.929 81.8182 239.318C81.8182 281.116 115.702 315 157.5 315C199.298 315 233.182 281.116 233.182 239.318C233.182 235.929 235.929 233.182 239.318 233.182C281.116 233.182 315 199.298 315 157.5C315 115.702 281.116 81.8182 239.318 81.8182C235.929 81.8182 233.182 79.0708 233.182 75.6818C233.182 33.8839 199.298 0 157.5 0Z"
        fill="#CBF5F9"
      />
    </svg>
  );
}

export default SixthBenefitsSVG;
