import React from "react";

function EighthBenefitsSVG() {
  return (
    <svg
      width="337"
      height="323"
      viewBox="0 0 337 323"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 170.474L0 322.52H116.349L116.349 170.474C116.349 141.46 139.8 117.921 168.5 117.921C197.195 117.921 220.651 141.46 220.651 170.474V322.52H337V170.474C337 77.0038 261.471 0.891449 168.5 0.891449C75.5291 0.891449 0 77.0038 0 170.474Z"
        fill="#F9CFCB"
      />
    </svg>
  );
}

export default EighthBenefitsSVG;
