import {
  ERROR_FETCHING_CURRENT_ADMIN,
  START_FETCHING_CURRENT_ADMIN,
  SUCCESS_FETCHING_CURRENT_ADMIN,
  SET_FIRST_NAME,
  SET_MIDDLE_NAME,
  SET_LAST_NAME,
  SET_TIME_ZONE,
  SET_PHOTO,
  SET_EMAIL,
  SET_DEFAULT_PHOTO,
  ERROR_UPDATING_CURRENT_ADMIN,
  START_UPDATING_CURRENT_ADMIN,
  SUCCESS_UPDATING_CURRENT_ADMIN,
} from "../types/currentAdmin";

export const startFetchingCurrentAdmin = () => ({
  type: START_FETCHING_CURRENT_ADMIN
});

export const successFetchingCurrentAdmin = (currentAdmin) => ({
  type: SUCCESS_FETCHING_CURRENT_ADMIN,
  payload: currentAdmin
});

export const errorFetchingCurrentAdmin = (error) => ({
  type: ERROR_FETCHING_CURRENT_ADMIN,
  payload: error
});

export const setFirstName = (firstName) => ({
  type: SET_FIRST_NAME,
  payload: firstName
});

export const setMiddleName = (middleName) => ({
  type: SET_MIDDLE_NAME,
  payload: middleName
});

export const setLastName = (lastName) => ({
  type: SET_LAST_NAME,
  payload: lastName
});

export const setEmail = (email) => ({
  type: SET_EMAIL,
  payload: email
});

export const setDefaultPhoto = () => ({
  type: SET_DEFAULT_PHOTO
})

export const setPhoto = (photoUrl) => ({
  type: SET_PHOTO,
  payload: photoUrl
})

export const setTimeZone = (timeZoneName, timeZone) => ({
  type: SET_TIME_ZONE,
  payload: {
    timeZoneName: timeZoneName,
    timeZone: timeZone
  }
})

export const startUpdatingCurrentAdmin = () => ({
  type: START_UPDATING_CURRENT_ADMIN
})

export const successUpdatingCurrentAdmin = () => ({
  type: SUCCESS_UPDATING_CURRENT_ADMIN
})

export const errorUpdatingCurrentAdmin = (error) => ({
  type: ERROR_UPDATING_CURRENT_ADMIN,
  payload: error
})