import React, { useEffect } from "react";
import HeadlineBlock from "../uiComponents/TextComponents/HeadlineBlock/HeadlineBlock";
import TextBlock from "../uiComponents/TextComponents/TextBlock/TextBlock";
import LogoBar from "../LogoBar/LogoBar";
import style from "./PageAfterRegister.module.scss";

const PageAfterRegister = (props) => {
  //обнуление навбара
  useEffect(() => {
    props.setNoNavBar(true);
    return function () {
      props.setNoNavBar(false);
    };
  }, []);

  return (
    <>
      <LogoBar />
      <div className={style.mainBlock}>
        <HeadlineBlock small marginTop="52px">
          Thank you!
        </HeadlineBlock>
        <TextBlock big marginTop="60px">
          Thanks for regestering with VRIENDS.
        </TextBlock>
        <TextBlock big>
          You’ll receive an email with documents that{" "}
          <b>need to be signed within 48 hours,</b>
        </TextBlock>
        <TextBlock big>so you can start enjoying our services.</TextBlock>
        <TextBlock big marginTop="40px">
          Best,
        </TextBlock>
        <TextBlock big>The VRIENDS Team</TextBlock>
      </div>
    </>
  );
};

export default PageAfterRegister;
