import React, { useEffect, useState } from "react";
import authenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import style from "../GroupDetailInfoCard/GroupDetailInfoCard.module.scss";

import TitleDescription from "../TitleDescription/TitleDescription";
import { SESSION_SERVICE_API } from "../../path";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import DateTimeLoose from "../DateTimeLoose/DateTimeLoose";
import GroupTimeSlots from "../GroupTimeSlots/GroupTimeSlots";
import GroupInfoDetailPage from "../GroupInfoDetailPage/GroupInfoDetailPage";
import FeedBack from "../FeedBack/FeedBack";
import { useHistory } from "react-router-dom";
import sessionService from "../../services/session.service";
import Footer from "../Footer/Footer";
import AuthFooter from "../AuthFooter/AuthFooter";
import { useLocation } from "react-router-dom";

var Scroll = require("react-scroll");
var scroll = Scroll.animateScroll;

function GroupDetailInfoCard() {
  const location = useLocation();
  const [pathname, setPathname] = useState();
  const [linkText, setLinkText] = useState();
  console.log(location.state.fromWhere);
  const isLogged = authenticatedUserInformationService.isUserLogedIn();
  const history = useHistory();
  const fromWhere = history.location.state?.fromWhere
    ? history.location.state?.fromWhere
    : "null";
  let accessToken = authenticatedUserInformationService.getUserToken();
  const { groupId } = useParams();
  const { currentUser } = useSelector((state) => state.currentUser.info);
  const [info, setInfo] = useState([]);
  const [workingDays, setWorkingDays] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [isShowSelect, setIsShowSelect] = useState(false);
  const [currentDate, setCurrentDate] = useState({
    currentYear: new Date().getFullYear(),
    currentMonth: new Date().getMonth() + 1,
  });
  const [timeZone, setTimeZone] = useState("-5");
  const [disabled, setDisabled] = useState(true);
  const [styleButton, setStyleButton] = useState("button");
  const [activeTimeSlot, setActiveTimeSlot] = useState("");
  const [timeZoneName, setTimeZoneName] = useState("");
  const [timeSlotsCurrentDay, setTimeSlotsCurrentDay] = useState([]);
  const [isLoadingTimeSlots, setIsLoadingTimeSlots] = useState(false);

  useEffect(() => {
    if (location.state.fromWhere === "buy") {
      setPathname("/find-group");
      setLinkText("< See all groups");
    } else {
      setPathname("/sessions");
      setLinkText("< Back to sessions");
    }
    scroll.scrollToTop();
    currentUser?.timeZone
      ? setTimeZone(currentUser?.timeZone)
      : setTimeZone(-5);
  }, [currentUser]);

  useEffect(() => {
    if (timeZone) {
      sessionService.getDetailInfoGroup(groupId, timeZone).then((response) => {
        setInfo(response.data);
        setTimeZone(currentUser?.timeZone);
        switch (info.timeZoneName) {
          case 0:
            setTimeZoneName("Central DayLight Time");
            break;
          case 1:
            setTimeZoneName("Mountain Daylight Time ");
            break;
          case 2:
            setTimeZoneName("Mountain Standart Time");
            break;
          case 3:
            setTimeZoneName("Pacific DayLight Time ");
            break;
          case 4:
            setTimeZoneName("Alaska DayLight Time ");
            break;
          case 5:
            setTimeZoneName("Hawaii-Aleutian Standart Time ");
            break;
          default:
            setTimeZoneName("Central DayLight Time");
        }
        if (!response || !response.data) {
          setWorkingDays([]);
        } else {
          setWorkingDays(response.data?.startTimes);
          setTimeSlotsCurrentDay(response.data?.startTimes);
        }
      });
    }
  }, [timeZone]);

  function getTimeSlots() {
    if (info?.startTimes?.length === 0) {
      setTimeSlotsCurrentDay([]);
    } else {
      // setTimeSlotsCurrentDay(info?.startTimes);
      setIsLoadingTimeSlots(true);
    }
  }

  useEffect(() => {
    if (accessToken) {
      setIsShowSelect(false);
    } else {
      setIsShowSelect(true);
    }
  }, []);

  return (
    <>
      <div className={style.container}>
        <div className={style.DescM}>
          <TitleDescription
            type={info.type}
            description={info.description}
            pathname={pathname}
            linkText={linkText}
          />
        </div>
        <div className={style.wrapperLeft}>
          <GroupInfoDetailPage photoUrl={info.photoUrl} />
        </div>
        <div className={style.wrapperRight}>
          <div className={style.DescC}>
            <TitleDescription type={info.type} description={info.description} />
          </div>
          <DateTimeLoose
            workingDays={workingDays}
            startDate={startDate}
            setStartDate={setStartDate}
            timeZone={timeZone}
            setTimeZone={setTimeZone}
            setDisabled={setDisabled}
            setStyleButton={setStyleButton}
            setActiveTimeSlot={setActiveTimeSlot}
            isShowSelect={isShowSelect}
            timeZoneName={timeZoneName}
            setIsLoadingTimeSlots={setIsLoadingTimeSlots}
            getTimeSlots={getTimeSlots}
            currentDate={currentDate}
            setCurrentDate={setCurrentDate}
          />
          {isLoadingTimeSlots && (
            <p className={style.headingTimeSlots}>Session time</p>
          )}
          <div className={style.gridBlock}>
            <GroupTimeSlots
              startDate={startDate}
              isLoadingTimeSlots={isLoadingTimeSlots}
              time={timeSlotsCurrentDay}
            />
          </div>
          {fromWhere === "buy" ? <FeedBack /> : null}
        </div>
      </div>
      <hr className={style.hr} />
      {!isLogged ? <Footer /> : <AuthFooter />}
    </>
  );
}

export default GroupDetailInfoCard;
