import { 
    START_FETCHING_CURRENT_SPECIALIST, 
    SUCCESS_FETCHING_CURRENT_SPECIALIST, 
    ERROR_FETCHING_CURRENT_SPECIALIST,
    SET_FIRST_NAME,
    SET_MIDDLE_NAME,
    SET_LAST_NAME,
    SET_APT,
    SET_CITY,
    SET_DATE_OF_BIRTH,
    SET_DEGREE,
    SET_EDUCATIONAL_INSTITUTION,
    SET_EMAIL,
    SET_FULL_DESCRIPTION,
    SET_PHONE_NUMBER,
    SET_SHORT_DESCRIPTION,
    SET_SSN,
    SET_STATE,
    SET_STREET,
    SET_TIME_ZONE,
    SET_ZIP_CODE,
    SET_PHOTO,
    SET_DEFAULT_PHOTO,
    ERROR_UPDATING_CURRENT_SPECIALIST,
    START_UPDATING_CURRENT_SPECIALIST,
    SUCCESS_UPDATING_CURRENT_SPECIALIST,
    SET_INHOUSE
  } from "../types/currentSpecialist";

  export const startFetchingCurrentSpecialist = () => ({
    type: START_FETCHING_CURRENT_SPECIALIST
  });
  
  export const successFetchingCurrentSpecialist = (currentSpecialist) => ({
    type: SUCCESS_FETCHING_CURRENT_SPECIALIST,
    payload: currentSpecialist 
  });
  
  export const errorFetchingCurrentSpecialist = (error) => ({
    type: ERROR_FETCHING_CURRENT_SPECIALIST,
    payload: error 
  });

  export const setFirstName = (firstName) => ({
    type: SET_FIRST_NAME,
    payload: firstName 
  });

  export const setMiddleName = (middleName) => ({
    type: SET_MIDDLE_NAME,
    payload: middleName 
  });

  export const setLastName = (lastName) => ({
    type: SET_LAST_NAME,
    payload: lastName 
  });

  export const setApt = (apt) => ({
    type: SET_APT,
    payload: apt 
  });

  export const setCity = (city) => ({
    type: SET_CITY,
    payload: city 
  });

  export const setDateOfBirth = (dateOfBirth) => ({
    type: SET_DATE_OF_BIRTH,
    payload: dateOfBirth + "T00:00:00.000"
  });

  export const setDegree = (degree) => ({
    type: SET_DEGREE,
    payload: degree 
  });

  export const setEducationalInstitution = (educationalInstitution) => ({
    type: SET_EDUCATIONAL_INSTITUTION,
    payload: educationalInstitution 
  });

  export const setEmail = (email) => ({
    type: SET_EMAIL,
    payload: email 
  });

  export const setFullDescription = (fullDescription) => ({
    type: SET_FULL_DESCRIPTION,
    payload: fullDescription 
  });

  export const setPhoneNumber = (phoneNumber) => ({
    type: SET_PHONE_NUMBER,
    payload: phoneNumber 
  });

  export const setShortDescription = (shortDescription) => ({
    type: SET_SHORT_DESCRIPTION,
    payload: shortDescription 
  });

  export const setSsn = (ssn) => ({
    type: SET_SSN,
    payload: ssn 
  });

  export const setSpecialistState = (specialistState) => ({
    type: SET_STATE,
    payload: specialistState 
  });

  export const setStreet = (street) => ({
    type: SET_STREET,
    payload: street 
  })

  export const setZipCode = (zipCode) => ({
    type: SET_ZIP_CODE,
    payload: zipCode 
  })

  export const setDefaultPhoto = () => ({
    type: SET_DEFAULT_PHOTO
  })

  export const setPhoto = (photoUrl) => ({
    type: SET_PHOTO,
    payload: photoUrl
  })

  export const setTimeZone = (timeZoneName, timeZone) => ({
    type: SET_TIME_ZONE,
    payload:{
      timeZoneName: timeZoneName,
      timeZone: timeZone
    }
  })

  export const startUpdatingCurrentSpecialist = () => ({
    type: START_UPDATING_CURRENT_SPECIALIST
  })

  export const successUpdatingCurrentSpecialist = () => ({
    type: SUCCESS_UPDATING_CURRENT_SPECIALIST
  })

  export const errorUpdatingCurrentSpecialist = (error) => ({
    type: ERROR_UPDATING_CURRENT_SPECIALIST,
    payload: error
  })

  export const setIsInHouse = (isInHouse) => ({
    type: SET_INHOUSE,
    payload: isInHouse
  })

