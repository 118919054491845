import React from "react";
import { Field } from "formik";
import "../Select/select.css";

export function Select(props) {
  const { name, options, defaultValue, style, onFocus } = props;
  return (
    <div className="select">
      <Field
        as="select"
        id={name}
        name={name}
        className={style}
        onFocus={onFocus}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.key}
            </option>
          );
        })}
      </Field>
    </div>
  );
}
