import React from "react";

const FifthBenefitsSVG = (props) => {
  return (
    <svg
      width="323"
      height="323"
      viewBox="0 0 323 323"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M165.67 155.816C248.252 160.136 314.039 229.591 315 315H0C0.961484 229.591 66.7474 160.136 149.33 155.816C67.836 151.552 2.6989 83.8591 0.0720502 0H314.929C312.302 83.8591 247.165 151.552 165.67 155.816Z"
        fill="#F2C94C"
        fillOpacity="0.8"
      />
    </svg>
  );
};

FifthBenefitsSVG.defaultProps = {
  width: 238,
  height: 280,
};

export default FifthBenefitsSVG;

<svg
  width="315"
  height="315"
  viewBox="0 0 315 315"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M165.67 155.816C248.252 160.136 314.039 229.591 315 315H0C0.961484 229.591 66.7474 160.136 149.33 155.816C67.836 151.552 2.6989 83.8591 0.0720502 0H314.929C312.302 83.8591 247.165 151.552 165.67 155.816Z"
    fill="#F2C94C"
    fillOpacity="0.8"
  />
</svg>;
