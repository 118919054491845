import React from "react";
import { useMemo } from "react";
import authenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import style from "./AuthFooter.module.scss";

function AuthFooter() {
  const role = authenticatedUserInformationService.getUserRole();

  return (
    <footer className={style.footer}>
      <span>Vriends</span>
      <div>
        {role !== "Specialist" ? (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://vriends-public.s3.us-west-1.amazonaws.com/2022_0526_Terms_of_Use_Client+Website.pdf"
          >
            Terms of Use
          </a>
        ) : (
          <a
            rel="noreferrer"
            target="_blank"
            href="https://vriends-public.s3.us-west-1.amazonaws.com/2022+0419+Terms+of+Use_Provider.pdf"
          >
            Terms of Use
          </a>
        )}
        <a
          className={style.second__link}
          rel="noreferrer"
          target="_blank"
          href="https://vriends-public.s3.us-west-1.amazonaws.com/2022_0419_Privacy_Policy.pdf"
        >
          Privacy Policy
        </a>
      </div>
    </footer>
  );
}

export default AuthFooter;
