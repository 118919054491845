import React, { useEffect, useState, useRef } from "react";
import ReactPlayer from "react-player";
import style from "../VideoLanding/videoLanding.module.scss";
import "./landingPage.scss";
import { Carousel } from "react-responsive-carousel";
import PlayButtonSVG from "../../svg/PlayButtonSVG";

function VideoLanding() {
  const [videoUrl, setVideoUrl] = useState(
    "https://vriends-public.s3.us-west-1.amazonaws.com/logovideo1402.mp4"
  );

  const poster = "../img/newPoster-compressed.jpg";

  const ref = useRef(null);
  return (
    <div className={style.video__wrapper}>
      <ReactPlayer
        light={poster}
        className={style.startVideo}
        url={videoUrl}
        controls={true}
        playing={true}
        height={"300px"}
        playIcon={
          <div className={style.btnPlay}>
            <div className={style.text__btn__wrapper}>
              <h2>Let's be VRIENDS</h2>
              <PlayButtonSVG />
            </div>
          </div>
          // <button className={style.btnPlay}>
          //   Watch the film
          //   <img
          //     className={style.btnPlayImg}
          //     src="../img/playIcon.png"
          //     alt="Button play"
          //   />
          // </button>
        }
        ref={ref}
        onEnded={() => ref.current.showPreview()}
      />
      {/* <Carousel
        className="wrapper"
        infiniteLoop
        autoPlay
        interval={700000}
        //showArrows={true}
        showStatus={false}
        showThumbs={false}
        // {...getConfigurableProps()}
      >
        <div className={style.blockVideo}>
          <ReactPlayer
            light={poster}
            className={style.startVideo}
            url={videoUrl[0]}
            controls={true}
            playing={true}
            height={"300px"}
            playIcon={
              <div className={style.btnPlay}>
                <PlayButtonSVG />
              </div>
              // <button className={style.btnPlay}>
              //   Watch the film
              //   <img
              //     className={style.btnPlayImg}
              //     src="../img/playIcon.png"
              //     alt="Button play"
              //   />
              // </button>
            }
            ref={ref}
            onEnded={() => ref.current.showPreview()}
          />
        </div>
        <div className={style.blockVideo}>
          <ReactPlayer
            light={poster}
            className={style.startVideo}
            url={videoUrl[1]}
            controls={true}
            playing={true}
            height={"300px"}
            playIcon={
              <div className={style.btnPlay}>
                <PlayButtonSVG />
              </div>
            }
            ref={ref}
            onEnded={() => ref.current.showPreview()}
          />
        </div>
        <div className={style.blockVideo}>
          <ReactPlayer
            light={poster}
            className={style.startVideo}
            url={videoUrl[2]}
            controls={true}
            playing={true}
            height={"300px"}
            playIcon={
              <div className={style.btnPlay}>
                <PlayButtonSVG />
              </div>
            }
            ref={ref}
            onEnded={() => ref.current.showPreview()}
          />
        </div>
        <div className={style.blockVideo}>
          <ReactPlayer
            light={poster}
            className={style.startVideo}
            url={videoUrl[3]}
            controls={true}
            playing={true}
            height={"300px"}
            playIcon={
              <div className={style.btnPlay}>
                <PlayButtonSVG />
              </div>
            }
            ref={ref}
            onEnded={() => ref.current.showPreview()}
          />
        </div>
      </Carousel> */}
    </div>
  );
}

export default VideoLanding;
