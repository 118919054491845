import style from './styledReactStars.module.scss'
import React from 'react';
import ReactStars from "react-rating-stars-component";
import StarSVG from '../../../svg/StarSVG/StarSVG.js';

const StyledReactStars = (props) => {
  return (
    //className должен содержать в себе тег span с margin-right для увеличения расстояния между звёздами
    <div className={props.className}>
      <ReactStars
        emptyIcon={<StarSVG color='#C4C4C4' size={props.size} />}
        filledIcon={<StarSVG color={props.starsColor} size={props.size} />}
        count={props.count}
        size={props.size}
        value={props.value}
        edit={props.edit}
      />
    </div>
  );
}

StyledReactStars.defaultProps = {
  starsColor: '#F2C94C'
}
export default StyledReactStars;