import { 
  DELETE_PHOTO, 
  NEW_CONCERNS, 
  NEW_DATE, 
  NEW_LAST_NAME, 
  NEW_NAME, 
  NEW_PARENT_LAST_NAME, 
  NEW_PARENT_NAME, 
  NEW_STRENGTHS, 
  NEW_TIME_ZONE, 
  SET_CURRENT_USER, 
  UPDATE_USER_PHOTO, 
  USER_BIRTHDAY,
  START_FETCHING_CURRENT_USER,
  ERROR_FETCHING_CURRENT_USER 
} from "../types/editCurrentUser";

export const editCurrentUserReducer = (state = {}, action) => {
  const { type, payload } = action;

  switch (type) {
      case SET_CURRENT_USER: {
        const { currentUser }  = payload
        return {
          ...state, 
          info: {  currentUser },
          loading: false,
          error: false
        }
      }
      case START_FETCHING_CURRENT_USER: {
        return {
          ...state,
          loading: true,
          error: false 
        }
      }
      case ERROR_FETCHING_CURRENT_USER: {
        return {
          ...state,
          loading: false,
          error: payload 
        }
      }
      case NEW_NAME: {
        const {newName} = payload
        state.info.currentUser.studentFirstName = newName
        return {...state, info: state.info }
      }
      case NEW_LAST_NAME: {
        const {newLastName} = payload
        state.info.currentUser.studentLastName = newLastName
        return {...state, info: state.info }
      }
      case NEW_STRENGTHS: {
        const {newStrengths} = payload
        state.info.currentUser.strengths = newStrengths
        return {...state, info: state.info }
      }
      case NEW_CONCERNS: {
        const {newConcerns} = payload
        state.info.currentUser.concerns = newConcerns
        return {...state, info: state.info }
      }
      case NEW_DATE: {
        const { date } = payload
        state.info.currentUser.dateOfBirthday = date
        return {...state, info: state.info }
      }
      case NEW_PARENT_NAME: {
        const {newName} = payload
        state.info.currentUser.parentFirstName = newName
        return {...state, info: state.info }
      }
      case NEW_PARENT_LAST_NAME: {
        const {newName} = payload
        state.info.currentUser.parentLastName = newName
        return {...state, info: state.info }
      }
      case NEW_TIME_ZONE: {
        const {timeZone, timeZoneName} = payload
        state.info.currentUser.timeZone = timeZone;
        state.info.currentUser.timeZoneName = timeZoneName;
        return {...state, info: state.info }
      }
      case DELETE_PHOTO: {
        state.info.currentUser.photoUrl = '/img/avatar.png'
        return {...state, info: state.info }
      }
      case UPDATE_USER_PHOTO: {
        const { currentUserPhoto } = payload
        state.info.currentUser.photoUrl = currentUserPhoto
        return {...state, info: state.info }
      }
      case USER_BIRTHDAY: {
        const { currentUser } = payload
        const newDate = currentUser.dateOfBirthday.slice(0, 10)
        const newArr = newDate.split('-')
        const dateForInput = `${newArr[1]}/${newArr[2]}/${newArr[0]}`
        return {...state, birthday: dateForInput }
      }
      default: {
          return state;
      }
  }
}