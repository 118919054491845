import style from "../LandingPage/landingPage.module.scss";
import VideoLanding from "../VideoLanding/VideoLanding";
import CarouselLanding from "../CarouselLanding/CarouselLanding";
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";
import FirstBenefitsSVG from "../../svg/FirstBenefitsSVG/FirstBenefitsSVG";
import FifthBenefitsSVG from "../../svg/FifthBenefitsSVG/FifthBenefitsSVG";
import SecondBenefitsSVG from "../../svg/SecondBenefitsSVG/SecondBenefitsSVG";
import BlueButton from "../uiComponents/buttons/BlueButton/BlueButton";
import ThirdBenefitsSVG from "../../svg/ThirdBenefitsSVG/ThirdBenefitsSVG";
import FourthBenefitsSVG from "../../svg/FourthBenefitsSVG/FourthBenefitsSVG";
import SixthBenefitsSVG from "../../svg/SixthBenefitsSVG/SixthBenefitsSVG";
import SeventhBenefitsSVG from "../../svg/SeventhBenefitsSVG/SeventhBenefitsSVG";
import EighthBenefitsSVG from "../../svg/EighthBenefitsSVG/EighthBenefitsSVG";
import DownloadSVG from "../../svg/DownloadSVG/DownloadSVG";
import LogoSVG from "../../svg/LogoSVG/LogoSVG";

// const isFreeTrial = "isFreeTrial";
function LandingPage() {
  return (
    <>
      <div className={style.langingPage__wrapper}>
        <div className={style.video__foreword__container}>
          <VideoLanding />
          <div className={style.foreword}>
            <h3>Welcome to VRiends</h3>
            <p>
              Virtual reality metaverse experience that helps adolescents (13+),
              young people, and adults make friends and learn essential life
              skills, all while having fun in a safe environment under the
              guidance of a trained facilitator. On VRiends, individuals gain
              the confidence they need to lead fuller, happier lives.
            </p>
          </div>
        </div>
        <section className={style.flexContainer}>
          <img
            className={style.glassImg}
            src="/img/glass.jpg"
            alt="VR glasses"
          />
          <div className={style.containerTxt}>
            <div className={style.mobileTxt__wrapper}>
              <h3>What We Do</h3>
              <p className={style.containerTxtSecond}>
                VRiends uses state-of-the-art virtual reality (VR) technology
                to&nbsp;change people’s lives in the real world. We do this by
                combining a truly innovative VR platform with human interaction.
                Every VRiends session is led by a highly qualified specialist
                who helps young learners fully engage with the experience while
                seamlessly learning critical social and life skills, and even
                making new friends.
              </p>
            </div>
            <div className={style.BlueButton__wrapper}>
              <Link to={"/contact-us"}>
                <BlueButton
                // onClick={() =>
                //   localStorage.setItem(isFreeTrial, "isFreeTrial")
                // }
                >
                  Contact us
                </BlueButton>
              </Link>
            </div>
          </div>
        </section>
        <hr className={style.mobile__hr} />
        <div className={style.benefitsBlock}>
          <h3>Benefits</h3>
          <section className={style.flexBenefits}>
            <div className={style.first__benefits__wrapper}>
              <FirstBenefitsSVG height={224} width={359} />
              <h3 className={style.first__benefits__title}>
                Better access <br /> to services
              </h3>
            </div>
            <div className={style.second__benefits__wrapper}>
              <span className={style.second__benefit__SVG}>
                <SecondBenefitsSVG height={224} width={310} />
              </span>
              <h3 className={style.second__benefits__title}>
                Highly qualified specialists
              </h3>
            </div>
          </section>

          <section className={style.flexBenefits}>
            <div className={style.third__benefits__wrapper}>
              <ThirdBenefitsSVG />
              <h3 className={style.third__benefits__title}>
                Engaging <br /> and&nbsp;immersive experience
              </h3>
            </div>
            <div className={style.fourth__benefits__wrapper}>
              <span className={style.fourth__benefit__SVG}>
                <FourthBenefitsSVG />
              </span>
              <h3 className={style.fourth__benefits__title}>
                The chance to&nbsp;learn new skills
              </h3>
            </div>
          </section>

          <section className={style.flexBenefits}>
            <div className={style.fifth__benefits__wrapper}>
              <FifthBenefitsSVG />
              <h3 className={style.fifth__benefits__title}>
                Time&nbsp;and&nbsp;cost savings
              </h3>
            </div>
            <div className={style.sixth__benefits__wrapper}>
              <span className={style.sixth__benefit__SVG}>
                <SixthBenefitsSVG />
              </span>
              <h3 className={style.sixth__benefits__title}>
                A truly safe environment
              </h3>
            </div>
          </section>

          <section className={style.flexBenefits}>
            <div className={style.seventh__benefits__wrapper}>
              <SeventhBenefitsSVG />
              <h3 className={style.seventh__benefits__title}>
                Evidence-based curriculum
              </h3>
            </div>
            <div className={style.eighth__benefits__wrapper}>
              <span className={style.eighth__benefit__SVG}>
                <EighthBenefitsSVG />
              </span>
              <h3 className={style.eighth__benefits__title}>
                The&nbsp;opportunity to&nbsp;make&nbsp;friends
              </h3>
            </div>
          </section>

          {/* <hr className={style.mobile__hr} /> */}

          <div className={style.getApp__block}>
            <h3 className={style.getApp__title}>Get VRiends</h3>
            <div className={style.getApp__section}>
              <h3 className={style.app_blockName}>Main VR app</h3>
              <div className={style.download__block}>
                <a
                  className={style.app__link}
                  href="https://www.oculus.com/experiences/quest/5502056649879498/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className={style.download__link}>
                    <div className={style.link__textarea}>
                      Download on the Meta Store
                    </div>
                    <div className={style.download__svg}>
                      <DownloadSVG />
                    </div>
                  </div>
                </a>
                <img
                  className={style.qrImg}
                  src="/img/VR.png"
                  alt="Main_App_QR"
                />
              </div>
              <h3 className={style.app_blockName}>Mobile companion app</h3>
              <div className={style.download__block}>
                <a
                  className={style.app__link}
                  href="https://apps.apple.com/app/vriends-companion/id1606167811"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className={style.download__link}>
                    <div className={style.link__textarea}>
                      Download on the AppStore
                    </div>
                    <div className={style.download__svg}>
                      <DownloadSVG />
                    </div>
                  </div>
                </a>
                <img
                  className={style.qrImg}
                  src="/img/ios.png"
                  alt="IOS_App_QR"
                />
              </div>
              <div className={style.download__block}>
                <a
                  className={style.app__link}
                  href="https://play.google.com/store/apps/details?id=com.vriends.companionandroid"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className={style.download__link}>
                    <div className={style.link__textarea}>
                      Download on the Google Play
                    </div>
                    <div className={style.download__svg}>
                      <DownloadSVG />
                    </div>
                  </div>
                </a>
                <img
                  className={style.qrImg}
                  src="/img/googlePlay.png"
                  alt="Android_App_QR"
                />
              </div>
              <h3 className={style.app_blockName}>Instructional videos</h3>
              <div className={style.download__block}>
                <a
                  className={style.app__link}
                  href="https://www.youtube.com/@VriendsCorp/videos"
                  rel="noreferrer"
                  target="_blank"
                >
                  <div className={style.download__link}>
                    <div className={style.link__textarea}>
                      Look on the YouTube
                    </div>
                    <div className={style.download__svg}>
                      <img
                        className={style.YTImg}
                        src="/img/YouTubeElement.png"
                        alt="YouTube_Logo"
                      />
                    </div>
                  </div>
                </a>
                <img
                  className={style.qrImg}
                  src="/img/youtube.png"
                  alt="YT_QR"
                />
              </div>
            </div>
          </div>

          <div className={style.flexType}>
            <div className={style.wrapperGroup}>
              <img
                className={style.groupImg}
                src="/img/group.png"
                alt="group session main picture"
              />
              <div className={style.groupTxt}>
                <h3>Group Sessions</h3>
                <p>
                  These sessions include up to three, custom-matched peers and a
                  highly qualified specialist to guide learners through a shared
                  experience that provides opportunities to practice social and
                  life skills.
                </p>
              </div>
              <div className={style.centerButton}>
                <Link to={"/find-group"}>
                  <BlueButton>See groups</BlueButton>
                </Link>
              </div>
            </div>
            <hr className={style.mobile__hr} />
            <div className={style.wrapperOne}>
              <img
                className={style.oneOnOneImg}
                src="/img/one-on-one.png"
                alt="group session main picture"
              />
              <div className={style.oneTxt}>
                <h3>One-on-one Sessions</h3>
                <p>
                  These sessions provide learners with private access to one of
                  our specialists in a private VR session, and may be used to
                  work on specific skills and challenges
                </p>
              </div>
              <div className={style.centerButton}>
                <Link to={"/rate"}>
                  <BlueButton>See specialists</BlueButton>
                </Link>
              </div>
            </div>
          </div>
          <div className={style.quoteBlock}>
            <CarouselLanding />
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default LandingPage;
