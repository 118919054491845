import axios from "axios";
import { SERVER_IP } from "../../path";
import {PROFILE_SERVICE_API} from "../../path";
import AuthenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import {
  startFetchingCurrentSpecialist,
  errorFetchingCurrentSpecialist,
  successFetchingCurrentSpecialist,
  startUpdatingCurrentSpecialist,
  errorUpdatingCurrentSpecialist,
  successUpdatingCurrentSpecialist
} from "../actionCreators/specialist.actionCreator";

let accessToken = AuthenticatedUserInformationService.getUserToken();

let currentSpecialistId = AuthenticatedUserInformationService.getUserId();


export const getCurrentSpecialist = () => async(dispatch) => {
  try {
    dispatch(startFetchingCurrentSpecialist());
    const response = await axios({
      method: 'GET',
      url: `${PROFILE_SERVICE_API}/Specialist?id=${currentSpecialistId}`,
      headers: { Authorization: "Bearer " + accessToken }
    });
    const currentSpecialist = response.data;
    dispatch(successFetchingCurrentSpecialist(currentSpecialist))
    if (response.status === 200) {
      if (response.data.photoUrl === null) {
        response.data.photoUrl = '/img/avatar.png'
        dispatch(successFetchingCurrentSpecialist(currentSpecialist))
      }
    }
  } catch (error) {
    dispatch(errorFetchingCurrentSpecialist(error));
  }
}

export const updateCurrentSpecialist = (currentSpecialist) => async(dispatch) => {
  try {
    dispatch(startUpdatingCurrentSpecialist());
    const response = await axios({
      method: 'PUT',
      url: `${PROFILE_SERVICE_API}/Specialist`,
      //url: `${PROFILE_SERVICE_API}/Specialist?id=${specialistId}`,
      headers: { Authorization: "Bearer " + accessToken },
      data: currentSpecialist
    });
    dispatch(successUpdatingCurrentSpecialist())
  } catch (error) {
    dispatch(errorUpdatingCurrentSpecialist(error));
  }
}

export const getSpecialistById = (specialistId) => async(dispatch) => {
  try {
    dispatch(startFetchingCurrentSpecialist());
    const response = await axios({
      method: 'GET',
      url: `${PROFILE_SERVICE_API}/Specialist?id=${specialistId}`,
      headers: { Authorization: "Bearer " + accessToken }
    });
    const currentSpecialist = response.data;
    dispatch(successFetchingCurrentSpecialist(currentSpecialist))
    if (response.status === 200) {
      if (response.data.photoUrl === null) {
        response.data.photoUrl = '/img/avatar.png'
        dispatch(successFetchingCurrentSpecialist(currentSpecialist))
      }
    }
  } catch (error) {
    dispatch(errorFetchingCurrentSpecialist(error));
  }
}
