import React from 'react';

const StarSVG = (props) => {
  return (
    <div>
      <svg
        width={props.size}
        height={props.size * 0.95}
        viewBox="0 0 30 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15 0.5L18.3677 10.8647H29.2658L20.4491 17.2705L23.8168 27.6353L15 21.2295L6.18322 27.6353L9.55093 17.2705L0.734152 10.8647H11.6323L15 0.5Z"
          fill={props.color}
        />
      </svg>
    </div>
  );
}

StarSVG.defaultProps = {
  size: 30,
  color: '#F2C94C'
}

export default StarSVG;