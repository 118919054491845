import React from "react";

function DownloadSVG(props) {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.7829 40.1406L3.44159 18.7121H46.1243L24.7829 40.1406Z"
        fill="white"
      />
      <rect
        x="15.1016"
        y="0.851562"
        width="18.4821"
        height="23.5714"
        fill="white"
      />
      <rect
        x="2.78125"
        y="42.2812"
        width="44.0051"
        height="7.14286"
        fill="white"
      />
    </svg>
  );
}

DownloadSVG.defaultProps = {
  width: 50,
  height: 50,
};

export default DownloadSVG;
