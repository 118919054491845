import React from "react";

function FourthBenefitsSVG() {
  return (
    <svg
      width="288"
      height="273"
      viewBox="0 0 288 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.601709 0L287.398 0C295.681 60.7163 215.7 75.3158 215.7 136.5C215.7 197.684 295.681 212.283 287.398 273H0.601709C-7.68183 212.283 72.3003 197.684 72.3003 136.5C72.3003 75.3158 -7.68183 60.7163 0.601709 0Z"
        fill="#67B4F3"
      />
    </svg>
  );
}

export default FourthBenefitsSVG;
