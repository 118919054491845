import React from "react";

function ThirdBenefitsSVG() {
  return (
    <svg
      width="357"
      height="273"
      viewBox="0 0 357 273"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M178.5 0C277.083 0 357 69.6693 357 155.61C303.601 155.61 260.312 193.348 260.312 239.9C260.312 256.9 260.312 273 260.312 273H96.688C96.688 273 96.688 256.9 96.688 239.9C96.688 193.348 53.3988 155.61 0 155.61C0 69.6693 79.9167 0 178.5 0Z"
        fill="#B791D2"
      />
    </svg>
  );
}

export default ThirdBenefitsSVG;
