import {
  GET_SESSIONS_BUNDLES,
  SET_CURRENT_SPECIALIST_FROM_SESSIONS,
  SET_SPECIALIST_WORKING_DAYS
} from "../types/session.types";

export const sessionsReducer = (state = {}, action) => {
  const { type, payload, error } = action

  switch (type) {
    case GET_SESSIONS_BUNDLES: {
      const { currentUserSessions } = payload
      return { ...state, sessions: currentUserSessions }
    }
    case SET_CURRENT_SPECIALIST_FROM_SESSIONS: {
      const { response } = payload
      return { ...state, currentSpecialistFromSessions: response }
    }
    case SET_SPECIALIST_WORKING_DAYS: {
      const { workingDays } = payload
      return { ...state, workingDays: workingDays }
    }
    default: {
      return state;
    }
  }
}