export const START_FETCHING_CURRENT_ADMIN = 'START_FETCHING_CURRENT_ADMIN';
export const SUCCESS_FETCHING_CURRENT_ADMIN = 'SUCCESS_FETCHING_CURRENT_ADMIN';
export const ERROR_FETCHING_CURRENT_ADMIN = 'ERROR_FETCHING_CURRENT_ADMIN';
export const START_UPDATING_CURRENT_ADMIN = 'START_UPDATING_CURRENT_ADMIN';
export const SUCCESS_UPDATING_CURRENT_ADMIN = 'SUCCESS_UPDATING_CURRENT_ADMIN';
export const ERROR_UPDATING_CURRENT_ADMIN = 'ERROR_UPDATING_CURRENT_ADMIN';
export const SET_FIRST_NAME = 'SET_FIRST_NAME';
export const SET_MIDDLE_NAME = 'SET_MIDDLE_NAME';
export const SET_LAST_NAME = 'SET_LAST_NAME';
export const SET_TIME_ZONE = 'SET_TIME_ZONE';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_DEFAULT_PHOTO = 'SET_DEFAULT_PHOTO';
export const SET_PHOTO = 'SET_PHOTO';