import { combineReducers } from "redux";
import { currentAdminReducer } from "./currentAdmin.reducer";
import { currentSpecialistReducer } from "./currentSpecialist.reducer";
import { editCurrentUserReducer } from "./editCurrentUser.reducer";
import { sessionsReducer } from "./session.reducer";
import { auxiliaryReducer } from "./auxiliaryReducer.reducer";


export const rootReducer = combineReducers({
  currentUser: editCurrentUserReducer,
  sessions: sessionsReducer,
  currentSpecialist: currentSpecialistReducer,
  currentAdmin: currentAdminReducer,
  auxiliaryReducer: auxiliaryReducer
})