import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "../CarouselLanding/carouselLanding.css";
import style from "../CarouselLanding/CarouselLanding.module.scss";

function CarouselLanding() {
  const getConfigurableProps = () => ({
    // showArrows: boolean('showArrows', true, tooglesGroupId),
    // showStatus: boolean('showStatus', true, tooglesGroupId),
    // showIndicators: boolean('showIndicators', true, tooglesGroupId),
    // infiniteLoop: boolean('infiniteLoop', true, tooglesGroupId),
    // showThumbs: boolean('showThumbs', true, tooglesGroupId),
    // useKeyboardArrows: boolean('useKeyboardArrows', true, tooglesGroupId),
    // autoPlay: boolean('autoPlay', true, tooglesGroupId),
    // stopOnHover: boolean('stopOnHover', true, tooglesGroupId),
    // swipeable: boolean('swipeable', true, tooglesGroupId),
    // dynamicHeight: boolean('dynamicHeight', true, tooglesGroupId),
    // emulateTouch: boolean('emulateTouch', true, tooglesGroupId),
    // autoFocus: boolean('autoFocus', false, tooglesGroupId),
    // thumbWidth: number('thumbWidth', 100, {}, valuesGroupId),
    // selectedItem: number('selectedItem', 0, {}, valuesGroupId),
    // interval: number('interval', 2000, {}, valuesGroupId),
    // transitionTime: number('transitionTime', 500, {}, valuesGroupId),
    // swipeScrollTolerance: number('swipeScrollTolerance', 5, {}, valuesGroupId),
    // ariaLabel: text('ariaLabel', undefined),
  });
  return (
    <div className={style.carousel_wrapper}>
      <Carousel
        className={style.wrapper}
        infiniteLoop
        autoPlay
        interval={5000}
        //showArrows={true}
        showStatus={false}
        showThumbs={false}
        {...getConfigurableProps()}
      >
        <div key="slide1">
          <h1
            className={style.bigText}
            style={{ paddingTop: "233px", width: "1140px" }}
          >
            “I saw an immediate and marked improvement in my son’s communication
            and interaction. There are not a lot of programs out there that help
            him, but they either try to teach him things he already knows or
            they act like he doesn’t need help. The VRiends program hit the
            Goldilocks zone! The experience provides help right where he needs
            it without being pushy. And because everything takes place in VR, he
            is able to practice difficult, high stress scenarios without the
            risk of failure in front of people his age. VRiends has been
            invaluable to his growth!”
          </h1>
          <span className={style.name__block}>– Melissa</span>
        </div>
        <div
          key="slide2"
          className={style.slides__el}
          style={{ paddingTop: "380px" }}
        >
          <h1>“The best part of VRiends is meeting new friends!”</h1>
          <span className={style.name__block}>– Charlie</span>
        </div>
        <div
          key="slide3"
          className={style.slides__el}
          style={{ paddingTop: "316px" }}
        >
          <h1>
            “VRiends gives my brother a great opportunity to communicate with
            other friends and participate in a lot of fun experiences.”
          </h1>
          <span className={style.name__block}>– Amelie</span>
        </div>
        <div
          key="slide4"
          className={style.slides__el}
          style={{ paddingTop: "252px" }}
        >
          <h1>
            “Our son is not able to socialize one-on-one with people in an
            in-person setting, so he really looks forward to his time on
            VRiends. He has a group and friends he can meet there, and they go
            on really fun adventures.”
          </h1>
          <span className={style.name__block}>– Michael</span>
        </div>
        <div
          key="slide5"
          className={style.slides__el}
          style={{ paddingTop: "380px" }}
        >
          <h1>“You can meet new people and you can make friends easily!”</h1>
          <span className={style.name__block}>– Harry</span>
        </div>
        <div
          key="slide6"
          className={style.slides__el}
          style={{ paddingTop: "380px" }}
        >
          <h1>“This is special, this VR is special.”</h1>
          <span className={style.name__block}>– Rocco</span>
        </div>
      </Carousel>
    </div>
  );
}

export default CarouselLanding;
