import axios from "axios"
import { SERVER_IP, PROFILE_SERVICE_API } from "../../path";
import AuthenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import {
  setCurrentUser,
  setUserBirthday,
  startFetchingCurrentUser,
  errorFetchingCurrentUser
} from "../actionCreators/curentUser.actionCreators"

let accessToken = AuthenticatedUserInformationService.getUserToken();

let studentId = AuthenticatedUserInformationService.getUserId();


//middleware Thunk
export const getCurrentUser = () => async (dispatch) => {
  try {
    dispatch(startFetchingCurrentUser());
    const response = await axios({
      method: 'GET',
      url: `${PROFILE_SERVICE_API}/Student?id=${studentId}`,
      headers: { Authorization: "Bearer " + accessToken },
    })
    const currentUser = response.data;
      if(response.status === 200) {
        if (response.data.photoUrl === null) {
        response.data.photoUrl = '/img/avatar.png'
        dispatch(setCurrentUser(currentUser))
        dispatch(setUserBirthday(currentUser))
      } else {
        dispatch(setCurrentUser(currentUser))
        dispatch(setUserBirthday(currentUser))
      }
     }
  } catch(error) {
    console.log(error);
    dispatch(errorFetchingCurrentUser(error))
  }
}


export const getCurrentUserById = (id) => async (dispatch) => {
  dispatch(startFetchingCurrentUser());
  try {
    const response = await axios({
      method: 'GET',
      url: `${PROFILE_SERVICE_API}/Student?id=${id}`,
      headers: { Authorization: "Bearer " + accessToken },
    })
    const currentUser = response.data

      if(response.status === 200) {
        if (response.data.photoUrl === null) {
        response.data.photoUrl = '/img/avatar.png'
        dispatch(setCurrentUser(currentUser))
        dispatch(setUserBirthday(currentUser))
      } else {
        dispatch(setCurrentUser(currentUser))
        dispatch(setUserBirthday(currentUser))
      }
     }
  } catch(error) {
    console.log(error);
    dispatch(errorFetchingCurrentUser(error))
  }
}

export const newInfoCurrentUser = (newInfoCurrentUser) => async (dispatch) => {
  try {
    const response = await axios({
      method: "PUT",
      url: `${PROFILE_SERVICE_API}/Student`,
      headers: { Authorization: "Bearer " + accessToken },
      data: newInfoCurrentUser
    })
    const newInfo = response.data
  } catch(err) {
    console.log(err)
  }
}
