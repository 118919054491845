import React from "react";

const FirstBenefitsSVG = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 359 225"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M297.667 78.6893C298.425 74.6558 298.821 70.494 298.821 66.2383C298.821 29.656 269.555 0 233.453 0C211.364 0 191.834 11.1023 180.002 28.1008C168.642 18.437 153.99 12.6168 137.998 12.6168C102.242 12.6168 73.1924 41.7059 72.6382 77.8031H72.6301C32.5173 77.8031 0 110.754 0 151.402C0 192.049 32.5173 225 72.6301 225H286.371C326.482 225 359 192.049 359 151.402C359 114.648 332.415 84.1886 297.667 78.6893Z"
        fill="#ACD291"
      />
    </svg>
  );
}

FirstBenefitsSVG.defaultProps = {
  width: 359,
  height: 225
}

export default FirstBenefitsSVG;
