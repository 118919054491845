import React from "react";

function LogoSVG(props) {
  return (
    <svg
      width={142}
      height={19}
      viewBox="0 0 1193 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="mask0_709_22667"
        style={{
          maskType: "luminance",
        }}
        maskUnits="userSpaceOnUse"
        x={-437}
        y={-889}
        width={2001}
        height={2001}
      >
        <path d="M1563.1 -888.65H-436.9V1111.35H1563.1V-888.65Z" fill="white" />
      </mask>
      <g mask="url(#mask0_709_22667)">
        <mask
          id="mask1_709_22667"
          style={{
            maskType: "luminance",
          }}
          maskUnits="userSpaceOnUse"
          x={-437}
          y={-889}
          width={2001}
          height={2001}
        >
          <path
            d="M1563.1 -888.65H-436.9V1111.35H1563.1V-888.65Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask1_709_22667)">
          <path
            d="M329.46 49.2402C322.307 41.9069 310.52 38.2402 294.1 38.2402H152.24C150.631 38.2463 149.058 38.7153 147.709 39.5912C146.359 40.4672 145.29 41.7132 144.63 43.1802C144.63 43.1802 144.63 43.1802 144.63 43.2402L102.1 144.55C101.604 145.921 100.713 147.115 99.54 147.98C98.4921 148.607 97.2901 148.928 96.0694 148.909C94.8486 148.89 93.6575 148.53 92.63 147.87C91.4521 147.032 90.531 145.882 89.97 144.55L46.97 43.2102C46.3711 41.5757 45.8735 39.9059 45.48 38.2102H0C0.276601 39.2643 0.610429 40.3025 1 41.3202C1.39299 42.395 1.85392 43.4438 2.38 44.4602L54.26 160.17C62.2 177.403 76.1 186.02 95.96 186.02C106.147 186.02 114.58 183.903 121.26 179.67C127.94 175.437 133.327 168.937 137.42 160.17L167.63 90.5702L172.7 79.5702V186.04H211.2V142.65L199.47 138.86L192.66 136.66L192.32 136.55L189.44 135.62L211.22 135.82L283.27 136.49L294.54 136.6C308.14 136.6 315.32 135.37 321.47 132.16C323.644 131.007 325.715 129.669 327.66 128.16C328.501 127.484 329.299 126.756 330.05 125.98C330.73 125.32 331.1 124.88 331.1 124.88C331.67 124.247 332.201 123.579 332.69 122.88C337.69 115.827 340.187 106.217 340.18 94.0502V81.8902C340.18 67.4702 336.613 56.5869 329.48 49.2402M240.43 98.1702C235.28 105.63 224.6 109.04 214.22 102.62C212.805 101.745 211.608 100.558 210.72 99.1502C199.34 81.1502 218.54 62.2302 236.72 73.4902C237.03 73.6902 237.34 73.9002 237.63 74.1302C238.652 74.9193 239.528 75.8803 240.22 76.9702C245.08 84.6802 244.34 92.5702 240.47 98.1702M276.23 102.62C274.811 101.742 273.611 100.551 272.72 99.1402C272.52 98.8102 272.33 98.5002 272.15 98.1402C266.81 88.7702 269.57 79.3102 275.99 74.1402C281.7 69.5402 290.29 68.3102 298.68 73.5002C300.097 74.3806 301.299 75.5661 302.2 76.9702C313.57 94.9702 294.36 113.89 276.2 102.63"
            fill="#7C7C7C"
          />
          <path
            d="M284.78 142.78C284.78 142.78 291.1 143.1 298.78 142.78H284.78Z"
            fill="black"
          />
          <path
            d="M332.1 186.02H293.2C292.93 184.02 289.29 164.88 286.48 151.02C285.85 147.92 285.27 145.09 284.78 142.77C284.78 142.77 291.1 143.09 298.78 142.77C307.25 142.43 317.4 141.32 322.6 138.16L323.52 142.77C324.38 147.03 325.32 151.71 326.25 156.37C329.15 170.82 331.97 185.06 332.12 186.01"
            fill="#7C7C7C"
          />
          <path d="M394.6 38.2102H355.9V186.02H394.6V38.2102Z" fill="black" />
          <path
            d="M457.67 76.2702H566.99V38.2102H436.19C422.577 38.2102 415.774 44.6602 415.78 57.5602V166.03C415.78 179.364 422.514 186.03 435.98 186.03H566.98V149.24H457.67C455.41 149.24 454.27 147.97 454.27 145.41V127.98H566.34V96.2602H454.27V79.8802C454.27 77.4702 455.41 76.2702 457.67 76.2702Z"
            fill="black"
          />
          <path
            d="M727.1 38.2102V135.21C727.1 137.77 726.1 139.03 724.1 139.03C723.451 139.041 722.805 138.94 722.19 138.73C721.513 138.427 720.926 137.951 720.49 137.35L650.31 49.2802C645.77 44.0402 641.16 40.3736 636.48 38.2802C631.8 36.1869 626.06 35.1169 619.26 35.0702C614.73 35.0361 610.217 35.6489 605.86 36.8902C601.838 37.9838 598.141 40.0363 595.087 42.8717C592.032 45.7071 589.71 49.2409 588.32 53.1702C586.563 58.1473 585.733 63.404 585.87 68.6802V186.09H623.73V84.5602C623.688 84.0188 623.755 83.4743 623.927 82.9591C624.099 82.4439 624.372 81.9683 624.73 81.5602C625.058 81.2157 625.454 80.9427 625.893 80.7586C626.332 80.5744 626.804 80.4831 627.28 80.4902C627.953 80.5478 628.606 80.7478 629.196 81.0771C629.785 81.4064 630.298 81.8574 630.7 82.4002L709.58 176.4C712.794 180.234 716.921 183.198 721.58 185.02C726.73 186.879 732.176 187.78 737.65 187.68C741.128 187.683 744.578 187.067 747.84 185.86C751.084 184.676 754.027 182.791 756.46 180.34C759.11 177.607 761.155 174.346 762.46 170.77C764.051 166.299 764.81 161.575 764.7 156.83V38.2102H727.1Z"
            fill="black"
          />
          <path
            d="M944.42 80.7402C940.413 71.7992 934.629 63.7665 927.42 57.1302C920.847 51.0612 913.154 46.3328 904.77 43.2102C896.037 39.8769 885.867 38.2102 874.26 38.2102H796.62C790.4 38.2102 787.287 41.1836 787.28 47.1302V176.88C787.28 182.98 790.394 186.027 796.62 186.02H874.26C887.147 186.02 898.34 183.934 907.84 179.76C920.654 174.273 931.554 165.111 939.163 153.431C946.772 141.752 950.749 128.079 950.59 114.14C950.59 103.65 948.53 89.8102 944.43 80.7402M909.77 126.81C908.248 130.631 906.008 134.124 903.17 137.1C899.904 140.613 895.916 143.376 891.48 145.2C886.814 147.2 881.074 148.2 874.26 148.2H828.52C826.69 148.2 825.76 147.2 825.76 145.2V79.2502C825.76 77.2502 826.69 76.2502 828.52 76.2502H874.26C880.76 76.2502 886.17 77.1002 890.42 78.8102C894.465 80.3949 898.191 82.6949 901.42 85.6002C904.708 88.7461 907.35 92.5029 909.2 96.6602C911.12 100.91 912.06 109.52 912.06 114.33C912.09 118.595 911.299 122.825 909.73 126.79"
            fill="black"
          />
          <path
            d="M1122.9 125.54C1120.74 120.506 1117.69 115.902 1113.9 111.95C1109.89 107.852 1105.08 104.625 1099.76 102.47C1094.16 100.13 1087.32 98.9635 1079.23 98.9702H1016.1C1014.34 99.032 1012.59 98.8537 1010.88 98.4402C1009.75 98.1234 1008.7 97.5826 1007.79 96.8502C1005.38 94.8502 1004.18 89.6002 1004.18 86.4802C1004.14 85.0992 1004.39 83.7254 1004.91 82.4438C1005.42 81.1623 1006.2 80.0005 1007.18 79.0302C1008.13 78.1316 1009.26 77.4492 1010.49 77.0302C1012.32 76.4719 1014.22 76.2218 1016.13 76.2902H1119.47V38.2102H1013.9C1004.27 38.2102 996.55 39.3769 990.73 41.7102C985.29 43.8045 980.36 47.0366 976.27 51.1902C972.453 55.133 969.387 59.7382 967.22 64.7802C965.022 69.9595 963.906 75.5337 963.94 81.1602C963.94 87.1602 965.01 95.3302 967.12 100.35C969.24 105.365 972.267 109.945 976.05 113.86C980.181 118.009 985.143 121.236 990.61 123.33C996.51 125.657 1004.27 126.824 1013.9 126.83H1072.39C1074.58 126.743 1076.76 126.996 1078.87 127.58C1080.31 128.032 1081.6 128.873 1082.6 130.01C1083.6 131.016 1084.37 132.213 1084.89 133.531C1085.41 134.848 1085.65 136.256 1085.6 137.67C1085.6 140.23 1084.83 143.78 1083.27 145.67C1082.18 146.871 1080.8 147.785 1079.27 148.33C1077.05 149.07 1074.71 149.408 1072.37 149.33H967.77V186.12H1079.19C1087.69 186.12 1094.89 184.787 1100.78 182.12C1106.43 179.605 1111.47 175.904 1115.56 171.27C1118.85 167.395 1121.47 163.002 1123.32 158.27C1125.24 153.471 1126.22 148.348 1126.2 143.18C1126.22 137.18 1125.1 131.231 1122.9 125.65"
            fill="black"
          />
          <path
            d="M321.41 132.15C321.41 132.15 321.41 132.15 321.41 132.2L321.56 132.12C322.03 131.88 322.49 131.64 322.94 131.39L324.02 130.75L325.02 130.08C325.67 129.63 326.32 129.16 326.92 128.68L327.56 128.17C325.616 129.679 323.545 131.017 321.37 132.17"
            fill="black"
          />
          <g opacity={0.4}>
            <mask
              id="mask2_709_22667"
              style={{
                maskType: "luminance",
              }}
              maskUnits="userSpaceOnUse"
              x={329}
              y={124}
              width={3}
              height={2}
            >
              <path
                d="M331.05 124.76H329.99V125.88H331.05V124.76Z"
                fill="white"
              />
            </mask>
            <g mask="url(#mask2_709_22667)">
              <path
                d="M329.99 125.88C330.34 125.51 330.71 125.15 331.04 124.76C331.04 124.76 330.68 125.19 329.99 125.88Z"
                fill="black"
              />
            </g>
          </g>
          <path
            d="M327.6 128.16L326.96 128.67C326.36 129.15 325.71 129.62 325.06 130.07L324.06 130.74L322.98 131.38C322.53 131.63 322.07 131.87 321.6 132.11L321.45 132.19C321.45 132.19 321.45 132.19 321.45 132.14C323.625 130.987 325.696 129.649 327.64 128.14"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M1150.22 19.8066H1159.84C1161.9 19.8066 1163.66 20.1179 1165.12 20.7405C1166.58 21.363 1167.69 22.2844 1168.46 23.5046C1169.25 24.7124 1169.64 26.2065 1169.64 27.9871C1169.64 29.3442 1169.39 30.5396 1168.89 31.573C1168.4 32.6064 1167.69 33.478 1166.78 34.1877C1165.88 34.885 1164.79 35.4266 1163.53 35.8126L1162.11 36.5037H1153.47L1153.43 32.787H1159.91C1161.03 32.787 1161.97 32.5878 1162.71 32.1893C1163.46 31.7909 1164.02 31.2493 1164.39 30.5645C1164.78 29.8672 1164.97 29.0828 1164.97 28.2112C1164.97 27.2649 1164.79 26.4431 1164.41 25.7458C1164.05 25.0361 1163.49 24.4945 1162.73 24.121C1161.97 23.735 1161.01 23.542 1159.84 23.542H1154.91V47H1150.22V19.8066ZM1165.74 47L1159.35 34.7854L1164.26 34.7667L1170.74 46.7572V47H1165.74Z"
        fill="black"
      />
      <circle
        cx={1159.5}
        cy={33.5}
        r={23.1094}
        stroke="black"
        strokeWidth={4.78125}
      />
    </svg>
  );
}

export default LogoSVG;
