import React, { useEffect, useState } from "react";
import "../CalendarSpecailstInfo/calendarSpecailstInfo.css";
// import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useHistory } from "react-router";
import RightArroySVG from "../../svg/RightArroy/RightArroySVG";
import LeftArroySVG from "../../svg/LeftArroy/LeftArroySVG";

export default function CalendarSpecailstInfo({
  workingDays,
  timeZone,
  startDate,
  setStartDate,
  getTimeSlots,
  setDisabled,
  setStyleButton,
  setActiveTimeSlot,
  setIsLoadingTimeSlots,
  setChangeInputDate,
  currentDate,
  setCurrentDate,
}) {
  // if(workingDays.errorCode === 706) {
  //   // history.push('/sessions')
  //   console.log("erroreCode 706");
  // } else {
  // }
  let arrayDates;

  arrayDates = workingDays?.map((element) => new Date(element));

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [isForward, setIsForward] = useState(true);

  function handleMonthChange(monthBeingViewed) {
    let year = moment(monthBeingViewed).format("YYYY");
    setCurrentDate({
      currentYear: year,
      currentMonth: moment(monthBeingViewed).format("M"),
    });
  }

  return (
    <DatePicker
      useWeekdaysShort={true}
      disabledKeyboardNavigation
      onMonthChange={handleMonthChange}
      showDisabledMonthNavigation
      includeDates={arrayDates}
      inline
      renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
        <div className="header">
          <p>
            {months[date?.getMonth()]} {currentDate?.currentYear}
          </p>
          {isForward && (
            <RightArroySVG
              className="arrow"
              onClick={() => {
                increaseMonth();
                setIsForward(!isForward);
                setIsLoadingTimeSlots(false);
                setStyleButton("button");
                setDisabled(true);
                setStartDate("");
              }}
            />
          )}
          {!isForward && (
            <LeftArroySVG
              className="arrow"
              onClick={() => {
                decreaseMonth();
                setIsForward(!isForward);
                setIsLoadingTimeSlots(false);
                setStyleButton("button");
                setDisabled(true);
                setStartDate("");
              }}
            />
          )}
        </div>
      )}
      selected={startDate}
      onChange={(date) => {
        setStartDate(date);
        getTimeSlots(date);
        setDisabled(true);
        setStyleButton("button");
        setActiveTimeSlot("");
      }}
    />
  );
}
