import axios from "axios";
import { PROFILE_SERVICE_API } from "../../path";
import authenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import { 
  errorFetchingCurrentAdmin, 
  startFetchingCurrentAdmin, 
  successFetchingCurrentAdmin,
  startUpdatingCurrentAdmin,
  errorUpdatingCurrentAdmin,
  successUpdatingCurrentAdmin
} from "../actionCreators/currentAdmin.actionCreators";

let accessToken = authenticatedUserInformationService.getUserToken();

let admintId = authenticatedUserInformationService.getUserId();

export const getCurrentAdmin = () => async(dispatch) => {
  try {
    dispatch(startFetchingCurrentAdmin());
    const response = await axios({
      method: 'GET',
      url: `${PROFILE_SERVICE_API}/Admin?id=${admintId}`,
      headers: { Authorization: "Bearer " + accessToken }
    });
    const currentAdmin = response.data;
    dispatch(successFetchingCurrentAdmin(currentAdmin))
    if (response.status === 200) {
      if (response.data.photoUrl === null) {
        response.data.photoUrl = '/img/avatar.png'
        dispatch(successFetchingCurrentAdmin(currentAdmin))
      } else {
        dispatch(successFetchingCurrentAdmin(currentAdmin))
      }
    }
  } catch (error) {
    dispatch(errorFetchingCurrentAdmin(error));
  }
}

export const updateCurrentAdmin = (currentAdmin) => async(dispatch) => {
  try {
    dispatch(startUpdatingCurrentAdmin());
    const response = await axios({
      method: 'PUT',
      url: `${PROFILE_SERVICE_API}/Admin`,
      //url: `${PROFILE_SERVICE_API}/Specialist?id=${specialistId}`,
      headers: { Authorization: "Bearer " + accessToken },
      data: currentAdmin
    });
    dispatch(successUpdatingCurrentAdmin())
  } catch (error) {
    dispatch(errorUpdatingCurrentAdmin(error));
  }
}

export const getAdminById = (adminId) => async(dispatch) => {
  try {
    dispatch(startFetchingCurrentAdmin());
    const response = await axios({
      method: 'GET',
      url: `${PROFILE_SERVICE_API}/Admin?id=${adminId}`,
      headers: { Authorization: "Bearer " + accessToken }
    });
    const currentAdmin = response.data;
    dispatch(successFetchingCurrentAdmin(currentAdmin))
    if (response.status === 200) {
      if (response.data.photoUrl === null) {
        response.data.photoUrl = '/img/avatar.png'
        dispatch(successFetchingCurrentAdmin(currentAdmin))
      }
    }
  } catch (error) {
    dispatch(errorFetchingCurrentAdmin(error));
  }
}
