import { 
  START_FETCHING_CURRENT_SPECIALIST, 
  SUCCESS_FETCHING_CURRENT_SPECIALIST, 
  ERROR_FETCHING_CURRENT_SPECIALIST,
  SET_FIRST_NAME,
  SET_MIDDLE_NAME,
  SET_LAST_NAME,
  SET_APT,
  SET_CITY,
  SET_DATE_OF_BIRTH,
  SET_DEGREE,
  SET_EDUCATIONAL_INSTITUTION,
  SET_FULL_DESCRIPTION,
  SET_PHONE_NUMBER,
  SET_SHORT_DESCRIPTION,
  SET_SSN,
  SET_STATE,
  SET_STREET,
  SET_ZIP_CODE,
  SET_TIME_ZONE,
  SET_DEFAULT_PHOTO,
  SET_PHOTO,
  ERROR_UPDATING_CURRENT_SPECIALIST,
  START_UPDATING_CURRENT_SPECIALIST,
  SUCCESS_UPDATING_CURRENT_SPECIALIST,
  SET_INHOUSE
} from "../types/currentSpecialist"

const defaultState = {
  currentSpecialist: null,
  loading: false,
  error: false,
  updatingInProcess: false,
  updatingError: false
}

export const currentSpecialistReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch(type) {
    case START_FETCHING_CURRENT_SPECIALIST: {
      return {
        ...state, 
        currentSpecialist: null,
        loading: true,
        error: false
      }
    }
    case SUCCESS_FETCHING_CURRENT_SPECIALIST:{
      return{
        ...state,
        currentSpecialist: payload,
        loading: false
      }
    }
    case ERROR_FETCHING_CURRENT_SPECIALIST:{
      return{
        ...state,
        currentSpecialist: null,
        loading: false,
        error: payload
      }
    }
    case SET_FIRST_NAME:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          firstName: payload      
        }
      }
    } 
    case SET_MIDDLE_NAME:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          middleName: payload      
        }
      }
    }   
    case SET_LAST_NAME:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          lastName: payload      
        }
      }
    }
    case SET_DEGREE:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          degree: payload      
        }
      }
    }    
    case SET_EDUCATIONAL_INSTITUTION:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          educationalInstitution: payload      
        }
      }
    } 
    case SET_DATE_OF_BIRTH:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          dateOfBirthday: payload      
        }
      }
    } 
    case SET_SSN:{
      return{
        ...state, 
        currentSpecialist: {
          ...state.currentSpecialist,
          ssn: payload      
        }
      }
    } 
    case SET_PHONE_NUMBER:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          phoneNumber: payload      
        }
      }
    } 
    case SET_SHORT_DESCRIPTION:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          shortDescription: payload      
        }
      }
    } 
    case SET_FULL_DESCRIPTION:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          description: payload      
        }
      }
    } 
    case SET_STREET:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          street: payload      
        }
      }
    }
    case SET_APT:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          apt: payload      
        }
      }
    } 
    case SET_CITY:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          city: payload      
        }
      }
    }  
    case SET_STATE:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          state: payload      
        }
      }
    }
    case SET_ZIP_CODE:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          zipCode: payload      
        }
      }
    }
    case SET_TIME_ZONE:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          timeZoneName: payload.timeZoneName,
          timeZone: payload.timeZone    
        }
      }
    }
    case SET_INHOUSE:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          isInHouse: payload 
        }
      }
    }
    case SET_DEFAULT_PHOTO:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          photoUrl:'/img/avatar.png',  
        }
      }
    }
    case SET_PHOTO:{
      return{
        ...state,
        currentSpecialist: {
          ...state.currentSpecialist,
          photoUrl: payload,  
        }
      }
    }
    case START_UPDATING_CURRENT_SPECIALIST:{
      return{
        ...state,
        updatingInProcess: true,
        updatingError: false       
      }
    }
    case ERROR_UPDATING_CURRENT_SPECIALIST:{
      return{
        ...state,
        updatingInProcess: false,
        updatingError: payload      
      }
    }
    case SUCCESS_UPDATING_CURRENT_SPECIALIST:{
      return{
        ...state,
        updatingInProcess: false,
        updatingError: false  
      }
    }
    default: {
      return state
    }
  }
}