import React from "react";

const SecondBenefitsSVG = (props) => {
  return (
    <svg
      width={props.width}
      height={props.height}
      viewBox="0 0 358 258"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 247.924C0 247.924 90.3613 206.88 179 239.742C278.044 276.463 358 246.44 358 246.44V19.4232C358 19.4232 278.058 49.3924 179.028 12.7009C90.3689 -20.148 0 21.0975 0 21.0975V247.924Z"
        fill="#F38B8B"
      />
    </svg>
  );
};

SecondBenefitsSVG.defaultProps = {
  width: 358,
  height: 258,
};

export default SecondBenefitsSVG;
