import React, { useEffect, useState } from "react";
import style from "../NavigationVRauth/navigationVRauth.module.scss";
import { SERVER_IP, PROFILE_SERVICE_API } from "../../path";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCurrentUser } from "../../redux/actions/editCurrentUser.action";
import Avatar from "../uiComponents/Avatar/Avatar";
import "./NavigationVRauth.css";
import BurgerCloseSVG from "../../svg/BurgerCloseSVG/BurgerCloseSVG";
import BurgerSVG from "../../svg/BurgerSVG/BurgerSVG";
import LogoSVG from "../../svg/LogoSVG/LogoSVG";

function NavigationVRauth() {
  function logout() {
    window.location = "/logout";
  }

  const currentUser = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();

  const [showDropdown, setShowDropdown] = useState(false);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  document.onclick = function (e) {
    if (e.target.id === "buyButton") {
      setShowDropdown(!showDropdown);
    }
    if (showDropdown === true) setShowDropdown(false);
  };

  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className={style.navigation}>
      <Avatar
        className={style.avatar}
        photoUrl={currentUser?.info?.currentUser?.photoUrl}
      />
      <div className={style.link}>
        <a onClick={logout}>Log out</a>
        <NavLink to="/edit-student" activeClassName="navigationActiveLink">
          Personal info
        </NavLink>
        <div className={style.linkDropdown}>
          <a id="buyButton">
            Buy
            <img src="/img/downArrow.png" alt="" className={style.arrow} />
          </a>
          {showDropdown && (
            <div className={style.dropdown}>
              <div>
                <p className={style.dropdown__txt}>
                  <NavLink to="/rate" activeClassName="navigationActiveLink">
                    One-on-one sessions
                  </NavLink>
                </p>
                <p>
                  <NavLink
                    to="/find-group"
                    activeClassName="navigationActiveLink"
                  >
                    Group sessions
                  </NavLink>
                </p>
              </div>
            </div>
          )}
        </div>
        <NavLink to="/payments" activeClassName="navigationActiveLink">
          Payments
        </NavLink>
        <NavLink to="/sessions" activeClassName="navigationActiveLink">
          Sessions
        </NavLink>
        <NavLink to="/faq" activeClassName="navigationActiveLink">
          FAQ
        </NavLink>
        <NavLink to="/vr" activeClassName="navigationActiveLink">
          VR device
        </NavLink>
      </div>
      <div className={style.logo}>
        <NavLink
          to="/sessions"
          activeClassName="navigationActiveLink"
          onClick={closeMenu}
        >
          {/* <img src="/img/logo.png" alt="logo" /> */}
          <LogoSVG />
        </NavLink>
      </div>
      <div className={style.burger__item} onClick={openMenu}>
        {isOpen ? <BurgerCloseSVG /> : <BurgerSVG />}
      </div>

      {/* mobile */}
      <div
        className={style.burger__menu}
        style={isOpen ? { dispalay: "flex" } : { display: "none" }}
      >
        <hr className={style.hr} />
        <NavLink
          to="/edit-student"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Personal info
        </NavLink>

        <NavLink
          to="/rate"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Buy one-on-one sessions
        </NavLink>
        <NavLink
          to="/find-group"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Buy group sessions
        </NavLink>

        <NavLink
          to="/payments"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Payments
        </NavLink>
        <NavLink
          to="/sessions"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Sessions
        </NavLink>
        <NavLink
          to="/faq"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          FAQ
        </NavLink>
        <NavLink
          to="/vr"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          VR device
        </NavLink>
        <a onClick={logout}>Log out</a>
      </div>
    </div>
  );
}

export default NavigationVRauth;
