import axios from "axios";
import { PROFILE_SERVICE_API } from "../path";
import { basicAxiosErrorHandler } from "./errorHandlers";

class currentTimeZone {

  getTimeZone = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: `${PROFILE_SERVICE_API}/timezone`,
      })
      return response.data;
    } catch (error) {
      basicAxiosErrorHandler((error) =>{
        console.log(error)
      }, error);
    }
  }
}

export default new currentTimeZone();