import React from "react";
import { Link } from "react-router-dom";
import style from "./Footer.module.scss";

function Footer() {
  return (
    <div className={style.footer}>
      <footer className={style.footerWrapper}>
        <div className={style.first__section}>
          <div className={style.pages__wrapper}>
            <div className={style.navigationWrapper}>
              <p className={style.navigationTitle}>Navigation</p>
              <Link to={"/"}>Home</Link>
              <Link to={"/about-us"}>About Us</Link>
              <Link to={"/curriculum-benefits"}>Curriculum & Benefits</Link>
              <Link to={"/for-specialists"}>Work with Us</Link>
            </div>
            <div className={style.navigationWrapper}>
              <Link to={"/find-group"}>Group Sessions</Link>
              <Link to={"/rate"}>One-on-one Sessions</Link>
              <Link to={"/faq"}>FAQ</Link>
              <Link to={"/contact-us"}>Contact us</Link>
            </div>
          </div>
          <div className={style.document__wrapper}>
            <div className={style.legal__document}>
              <p className={style.navigationTitle}>Legal Documents</p>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  "https://vriends-public.s3.us-west-1.amazonaws.com/2022_0526_Terms_of_Use_Client+Website.pdf"
                }
              >
                Terms of Use
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  "https://vriends-public.s3.us-west-1.amazonaws.com/2022_0419_Privacy_Policy.pdf"
                }
              >
                Privacy Policy
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  "https://vriends-public.s3.us-west-1.amazonaws.com/2022_0419_CCPA_Statement.pdf"
                }
              >
                California Privacy Statement
              </a>
              <a
                rel="noreferrer"
                target="_blank"
                href={
                  "https://vriends-public.s3.us-west-1.amazonaws.com/HIPAA_Notice_of_Privacy_Practices.pdf"
                }
              >
                HIPAA Notice
              </a>
            </div>
          </div>
        </div>
        <div className={style.second__section}>
          <div className={style.questions__wrapper}>
            <div className={style.questions__block}>
              <p className={style.navigationTitle}>Have questions?</p>
              <p className={style.questions__text}>
                {" "}
                We’d love to hear from you. Drop us an email at&nbsp;
                <a href="mailto:info@vriends.com">info@vriends.com</a>.
                We&nbsp;look&nbsp;forward to&nbsp;connecting&nbsp;with you!
              </p>
            </div>
          </div>
          <div className={style.speaksWrapper}>
            <div className={style.speaksImg}>
              <div className={style.support__block}>
                <img
                  className={style.img_AS}
                  src="/img/autismSpeaks_1.png"
                  alt="Autism Speaks"
                />
                <p>Partially funded by&nbsp;Autism&nbsp;Speaks.</p>
              </div>
              <div className={style.support__block}>
                <img
                  className={style.img_AWS}
                  src="/img/AWS_logo.png"
                  alt="AWS"
                />
                <p>We are a proud Member&nbsp;of AWS EdStart</p>
              </div>
              <div className={style.support__block}>
                <img className={style.img_NSF} src="/img/NSF.png" alt="NSF" />
                <p>Funded by NSF.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <footer className={style.footerWrapper__mobile}>
        <div className={style.speaksWrapper}>
          <div className={style.speaksImg}>
            <div className={style.support__block}>
              <img
                className={style.img_AS}
                src="/img/autismSpeaks_1.png"
                alt="Autism Speaks"
              />
              <p>Partially funded by&nbsp;Autism&nbsp;Speaks.</p>
            </div>
            <div className={style.support__block}>
              <img
                className={style.img_AWS}
                src="/img/AWS_logo.png"
                alt="AWS"
              />
              <p>We are a proud Member&nbsp;of AWS EdStart</p>
            </div>
            <div className={style.support__block}>
              <img className={style.img_NSF} src="/img/NSF.png" alt="NSF" />
              <p>Funded by NSF.</p>
            </div>
          </div>
        </div>
        <hr className={style.hr} />
        <div className={style.document__wrapper}>
          <div className={style.legal__document}>
            <p className={style.navigationTitle}>Legal Documents</p>
            <a
              rel="noreferrer"
              target="_blank"
              href={
                "https://vriends-public.s3.us-west-1.amazonaws.com/2022_0526_Terms_of_Use_Client+Website.pdf"
              }
            >
              Terms of Use
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={
                "https://vriends-public.s3.us-west-1.amazonaws.com/2022_0419_Privacy_Policy.pdf"
              }
            >
              Privacy Policy
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={
                "https://vriends-public.s3.us-west-1.amazonaws.com/2022_0419_CCPA_Statement.pdf"
              }
            >
              California Privacy Statement
            </a>
            <a
              rel="noreferrer"
              target="_blank"
              href={
                "https://vriends-public.s3.us-west-1.amazonaws.com/HIPAA_Notice_of_Privacy_Practices.pdf"
              }
            >
              HIPAA Notice
            </a>
          </div>
        </div>
        <hr className={style.hr} />
        <div className={style.questions__wrapper}>
          <div className={style.questions__block}>
            <p className={style.navigationTitle}>Have questions?</p>
            <p className={style.questions__text}>
              {" "}
              We’d love to hear from you. Drop us an email at&nbsp;
              <a href="mailto:info@vriends.com">info@vriends.com</a>.
              We&nbsp;look&nbsp;forward to&nbsp;connecting&nbsp;with you!
            </p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
