import React from "react";
import style from "../TitleDescription/titleDescription.module.scss";
import { Link } from "react-router-dom";
import Avatar from "../uiComponents/Avatar/Avatar";

function TitleDescription({
  firstName,
  lastName,
  description,
  type,
  pathname,
  linkText,
  photoUrl,
  isAvatarPermit,
}) {
  if (type === 0) {
    firstName = "Social";
    lastName = "group";
  } else if (type === 1) {
    firstName = "Speech";
    lastName = "group";
  }

  return (
    <div className={style.wrapper}>
      <div className={style.linkM}>
        <Link
          to={{
            pathname,
          }}
        >
          {linkText}
          {/* &lt; See all specialists */}
        </Link>
      </div>
      <h1>
        {firstName} {lastName}
      </h1>
      <p>{description}</p>
      <div className={style.imgM}>
        {isAvatarPermit && (
          <Avatar
            className={style.imgDetailInfo}
            photoUrl={photoUrl}
            alt="logo of group"
          />
        )}
      </div>
    </div>
  );
}

export default TitleDescription;
