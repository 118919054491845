//предлагаю использовать редьюсер для переброски всяких мелких данных, нужных между компонентами
import { SET_SESSIONS_DAY_FOR_SEARCH_IN_ADMIN_SESSIONS } from "../types/auxiliaryReducer.types";
import { createDateFormatMMDDYYYY } from "../../functions/dateFormater/dateFormater";

const defaultState = {
  sessionsDayForSearchInAdminSesseions: createDateFormatMMDDYYYY()
}

export const auxiliaryReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch(type) {
    case SET_SESSIONS_DAY_FOR_SEARCH_IN_ADMIN_SESSIONS: {
      return{
        ...state,
        sessionsDayForSearchInAdminSesseions: payload
      }
    }
    default: {
      return state;
    }
  }
}