import axios from "axios";
import { SERVER_IP } from "../path";
import AuthenticatedUserInformationService from './authenticatedUserInformation.service';
import { axiosConsloeLogErrorHandler } from "./errorHandlers";

class RegistrationService {

  accessToken = AuthenticatedUserInformationService.getUserToken();

  registerAdmin = async (data) => {
    const { email, password, firstName, middleName, lastName, timeZone, timeZoneName, code } = data;
    try {
      const response = await axios({
        method: "POST",
        url: `${SERVER_IP}/auth/Register/admin`,
        headers: { "Content-Type": "application/json" },
        data: {
          email: email,
          password: password,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          timeZone: timeZone,
          timeZoneName: timeZoneName,
          code: code
        }
      })
      return response;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  }

  registerSpecialist = async (data) => {
    const { 
      email, 
      password, 
      firstName, 
      middleName, 
      lastName, 
      timeZone, 
      timeZoneName,  
      educationalInstitution, 
      dateOfBirthday,
      ssn,
      phoneNumber,
      street,
      apt,
      city,
      state,
      zipCode,
      degree
    } = data;

    try {
      const response = await axios({
        method: "POST",
        url: `${SERVER_IP}/auth/Register/specialist`,
        headers: { "Content-Type": "application/json" },
        data: {
          email: email,
          password: password,
          firstName: firstName,
          middleName: middleName,
          lastName: lastName,
          educationalInstitution: educationalInstitution,
          dateOfBirthday: dateOfBirthday,
          ssn: ssn,
          phoneNumber: phoneNumber,
          street: street,
          apt: apt,
          city: city,
          state: state,
          zipCode: zipCode,
          timeZone: timeZone,
          timeZoneName: timeZoneName,
          degree: +degree,
        }
      })
      return response;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  }

  registerStudent = async (data) => {
    const { 
      email,
      password,
      parentFirstName,
      parentLastName,
      studentFirstName,
      studentLastName,
      dateOfBirthday,
      strengths,
      concerns,
      timeZone,
      timeZoneName,
      isFreeTrial
    } = data;

    try {
      const response = await axios({
        method: "POST",
        url: `${SERVER_IP}/auth/Register/student`,
        headers: { "Content-Type": "application/json" },
        data: {
          email: email,
          password: password,
          parentFirstName: parentFirstName,
          parentLastName: parentLastName,
          studentFirstName: studentFirstName,
          studentLastName: studentLastName,
          dateOfBirthday: dateOfBirthday,
          strengths: strengths,
          concerns: concerns,
          timeZone: timeZone,
          timeZoneName: timeZoneName,
          isFreeTrial: isFreeTrial
        }
      })
      return response;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  }

}

const registrationService = new RegistrationService();
export default registrationService;