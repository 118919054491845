import React from "react";
import "../Login/login.css";

function Login() {
  return (
    <div className="wrapper">
      <h1>Login</h1>
      <ul>
        <li>
          <input type="text" placeholder="vriends@example.com" />
        </li>
        <li>
          <input type="password" placeholder="Password" />
        </li>
        <li>
          <button type="button" disabled>Log in</button>
        </li>
      </ul>
      <div className="link">
        <a href="#">Forgot password?</a>
      </div>
    </div>
  );
}

export default Login;
