function BurgerSVGSpec() {
  return (
    <svg
      width="37"
      height="28"
      viewBox="0 0 37 28"
      fill="#white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="37" height="5.41935" rx="2.70968" fill="#d9d9d9" />
      <rect
        y="10.8386"
        width="37"
        height="5.41935"
        rx="2.70968"
        fill="#d9d9d9"
      />
      <rect
        y="22.5806"
        width="37"
        height="5.41935"
        rx="2.70968"
        fill="#d9d9d9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_313_17"
          x1="28.5"
          y1="4.06452"
          x2="22.7416"
          y2="-7.09243"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.473958" />
          <stop offset="0.474058" stopColor="#7C7C7C" />
          <stop offset="1" stopColor="#7C7C7C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_313_17"
          x1="28.5"
          y1="14.9031"
          x2="22.7416"
          y2="3.7462"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.473958" />
          <stop offset="0.474058" stopColor="#7C7C7C" />
          <stop offset="1" stopColor="#7C7C7C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_313_17"
          x1="28.5"
          y1="26.6451"
          x2="22.7416"
          y2="15.4881"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.473958" />
          <stop offset="0.474058" stopColor="#7C7C7C" />
          <stop offset="1" stopColor="#7C7C7C" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default BurgerSVGSpec;
