import style from './headlineBlock.module.scss'
import React from 'react';

const HeadlineBlock = (props) => {

  let fontSize = null;
  let letterSpacing = null;
  let lineHeight = null;
  let fontFamily = null;

  if(props.big){
    fontSize = '110px';
    letterSpacing = '-0.03em';
    lineHeight = '110px';
    fontFamily = 'Roboto Slab';
  } else if(props.reg) {
    fontSize = '64px';
    letterSpacing = '-0.02em';
    lineHeight = '100%';
    fontFamily = 'Roboto Slab';
  } else if(props.small) {
    fontSize = '36px';
    lineHeight = '100%';
    letterSpacing = '0em';
    fontFamily = 'Roboto Slab';
  } else if(props.mini){
    fontSize='20px';
    lineHeight='130%';
    letterSpacing='0.01em';
    fontFamily = 'Roboto';
  } else{
    fontSize = '64px';
    lineHeight = '110%';
    letterSpacing = '-0.02em';
    fontFamily = 'Roboto Slab';
  }

  return(
    <div 
      className={`${style.headline} ${props.className}`}
      style={{
        width: props.width,
        height: props.height,
        fontSize: fontSize,
        letterSpacing: letterSpacing,
        lineHeight: lineHeight,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        fontFamily: fontFamily,
        ...props.style
      }}
    >
      {props.children}
    </div>
  );
}

HeadlineBlock.defaultProps = {
  width: 'auto',
  height: 'auto'
}

export default HeadlineBlock;