import style from "./logoBar.module.scss";
import React from "react";
import { Link, NavLink } from "react-router-dom";
import { useState } from "react";
import BurgerCloseSVG from "../../svg/BurgerCloseSVG/BurgerCloseSVG";
import BurgerSVG from "../../svg/BurgerSVG/BurgerSVG";
import authinticationService from "../../services/Authentication.service";
import authenticatedUserInformationService from "../../services/authenticatedUserInformation.service";

const LogoBar = (props) => {
  const isLogged = authenticatedUserInformationService.isUserLogedIn();
  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };
  return (
    <>
      <div className={style.LogoBar}>
        <Link to="/">
          <div onClick={props.onClick}>
            <img src="/img/logo.png" alt="logo" />
          </div>
        </Link>
        {!isLogged ? (
          <div className={style.burger__item} onClick={openMenu}>
            {isOpen ? <BurgerCloseSVG /> : <BurgerSVG />}
          </div>
        ) : null}
      </div>
      {!isLogged ? (
        <div
          className={style.burger__menu}
          style={isOpen ? { dispalay: "flex" } : { display: "none" }}
        >
          <a
            onClick={openMenu}
            onClick={() => {
              authinticationService.login();
            }}
          >
            Log in
          </a>
          <NavLink
            onClick={openMenu}
            to="/about-us"
            activeClassName="navigationActiveLink"
          >
            About Us
          </NavLink>
          <NavLink
            onClick={openMenu}
            to="/curriculum-benefits"
            activeClassName="navigationActiveLink"
          >
            Curriculum & Benefits
          </NavLink>
          <NavLink
            onClick={openMenu}
            to="/for-specialists"
            activeClassName="navigationActiveLink"
          >
            Work with Us
          </NavLink>

          <NavLink onClick={openMenu} to="/find-group">
            Group Sessions
          </NavLink>
          <NavLink onClick={openMenu} to="/rate">
            One-on-one Sessions
          </NavLink>
          <NavLink
            onClick={openMenu}
            to="/faq"
            activeClassName="navigationActiveLink"
          >
            FAQ
          </NavLink>
          <NavLink
            onClick={openMenu}
            to="/contact-us"
            activeClassName="navigationActiveLink"
          >
            Contact us
          </NavLink>
        </div>
      ) : null}
    </>
  );
};

LogoBar.defaultProps = {
  onClick: () => {},
};

export default LogoBar;
