import dateFnsFormat from "date-fns/format";
import { isSsnValid } from "../../functions/ssnValidator/ssnValidator";

export default function checkFields(values) {
  const reg = /^(0[1-9]|1[012])[- \/\/.](0[1-9]|[12][0-9]|3[01])[- \/\/.](19|20)\d\d$/;

    const notNumberReg = /\d/g;

  let validationObject = {
    firstName: "",
    middleName: "",
    lastName: "",
    education: "",
    birthDate: "",
    ssn: "",
    phone: "",
    street: "",
    apt: "",
    zipCode: "",
    city: "",
    state: "",
    password: "",
    confirmPassword: "",
  };
  if ((!values.firstName) || notNumberReg.test(values.firstName)) {
    validationObject.firstName = "error";
  } else {
    validationObject.firstName = "success";
  }
  if (((!values.middleName) && values.middleName !== null ) || notNumberReg.test(values.middleName)) {
    validationObject.middleName = "error";
  } else {
    validationObject.middleName = "success";
  }
  if (values.middleName === null) {
    validationObject.middleName = "success";
  }
  if ((!values.lastName) || notNumberReg.test(values.lastName)) {
    validationObject.lastName = "error";
  } else {
    validationObject.lastName = "success";
  }
  if (!values.education) {
    validationObject.education = "error";
  } else {
    validationObject.education = "success";
  }
  if (values.birthDate.length <= 10) {
    if (reg.test(values.birthDate)) {
      validationObject.birthDate = "success";
    } else {
      validationObject.birthDate = "error";
    }
  } else {
    validationObject.birthDate = "error";
  }
  const phoneReg = /^\d+(\-\d+)*$/
  if (phoneReg.test(values.phone) === false || values.phone.length < 12) {
    validationObject.phone = "error";
  } else {
    validationObject.phone = "success";
  }
  if (!isSsnValid(values.socialNumber)) {
    validationObject.socialNumber = "error";
  } else {
    validationObject.socialNumber = "success";
  }
  if (!values.street) {
    validationObject.street = "error";
  } else {
    validationObject.street = "success";
  }
  if (!values.apt) {
    validationObject.apt = "error";
  } else {
    validationObject.apt = "success";
  }
  if (!values.zipCode || values.zipCode.length !== 5) {
    validationObject.zipCode = "error";
  } else {
    validationObject.zipCode = "success";
  }
  if (!values.city) {
    validationObject.city = "error";
  } else {
    validationObject.city = "success";
  }
  if (!values.state) {
    validationObject.state = "error";
  } else {
    validationObject.state = "success";
  }

  if (!values.password || values.password.length < 6) {
    validationObject.password = "error";
    validationObject.confirmPassword = "error";
  }
  if (!values.confirmPassword) {
    validationObject.confirmPassword = "error";
  }
  if (values.password.length >= 6 && values.confirmPassword) {
    if (values.password !== values.confirmPassword) {
      validationObject.confirmPassword = "error";
      validationObject.password = "success";
    } else {
      validationObject.confirmPassword = "success";
      validationObject.password = "success";
    }
  }
  if (values.password && !values.confirmPassword) {
    validationObject.confirmPassword = "error";
    validationObject.password = "success";
  }
  return validationObject;
}
