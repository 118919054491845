import React from "react";
import style from "./spinner.module.scss";

export const Spinner = (props) => {
  return (
    <div
      className={style.spinnerContainer}
      style={{
        width: props.width,
        height: props.height,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        //margin: props.margin
      }}
    >
      <img alt="spinner" className={style.spinnerImg} src="/img/loader.png" />
    </div>
  );
};

Spinner.defaultProps = {
  width: "100%",
  height: "100%",
  //margin: '0px',
  marginTop: "0px",
  marginRight: "0px",
  marginLeft: "0px",
  marginBottom: "0px",
};

export default Spinner;
