import { Log, UserManager } from "oidc-client";
import axios from 'axios';
import authenticatedUserInformationService from './authenticatedUserInformation.service';

//OIDC logs eneble
Log.logger = window.console;
Log.level = Log.DEBUG;

class AuthinticationService {

  constructor() {
    this.userManagerSettings = {
      authority: process.env.REACT_APP_BASE_URL,
      client_id: process.env.REACT_APP_CLIENT_ID,
      client_secret: process.env.REACT_APP_SECRET,
      redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT}signin-callback.html`,
      post_logout_redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT}`,
      response_type: "code",
      scope: process.env.REACT_APP_CLIENT_SCOPE,
      loadUserInfo: true,
      silent_redirect_uri: `${process.env.REACT_APP_CLIENT_ROOT}silent-renew.html`,
      automaticSilentRenew: true,
      monitorAnonymousSession: true,
      revokeAccessTokenOnSignout: true,
      filterProtocolClaims: false,
      accessTokenExpiringNotificationTime: 60
    }

    this.userManager = new UserManager(this.userManagerSettings);

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login = () => {
    this.userManager.signinRedirect();
  }

  logout = async () => {
    this.userManager.signoutRedirect().
      then(() => {
        localStorage.clear();
        sessionStorage.clear();
      })
  }
}

const authinticationService = new AuthinticationService();

//Если в хедере есть Authorization, то если пользователь залогинен, то подставляется токен. Authorization может быть любым
axios.interceptors.request.use(function (config) {
  const userLogedIn = authenticatedUserInformationService.isUserLogedIn();
  if (config.headers.Authorization && userLogedIn) {
    const token = authenticatedUserInformationService.getUserToken();
    config.headers.Authorization = 'Bearer ' + token;
  }
  return config;
}, function (error) {
  throw error;
});

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status) {
        if (error.response.status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          authinticationService.login();
        } else {
          throw error;
        }
      } else {
        throw error;
      }
    } else {
      throw error;
    }
  }
);

export default authinticationService;