import axios from "axios";
import { SERVER_IP } from "../path";
import authenticatedUserInformationService from './authenticatedUserInformation.service';
import { axiosConsloeLogErrorHandler } from "./errorHandlers";

class EmailService {

  accessToken = authenticatedUserInformationService.getUserToken();

  generateEmailCode = async (email) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SERVER_IP}/auth/emailcode/generate`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: email
        }
      })
      return response;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  }

  confirmEmail = async (email, code) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SERVER_IP}/auth/emailcode/confirm`,
        headers: {
          "Content-Type": "application/json"
        },
        data: {
          email: email,
          code: code,
        }
      })
      return response;
    } catch (error) {
      throw error;
    }
  }
}

const emailService = new EmailService();
export default emailService; 