export const cityStateSelectorStyles = {
    container: (base, state) => ({
        ...base,
        width: '100%',
        height: 60,
        // borderColor: state.isFocused? '#2b4bf2' :'#000000',
        // borderColor: state.isHovered? '#000000' : '#000000'

    }),
    control: (base, state) => ({
        ...base,
        marginTop: 8,
        height: 60,
        minHeight: 60,
        border: '1.5px solid',
        boxShadow: 'none',
        ':hover': {
            border: '1.5px solid #2b4bf2'
        },
        borderColor: state.isDisabled ? 'gray !important' : '1.5px solid #000000',
        border: state.isFocused ? '1.5px solid #2b4bf2 !important' : '1.5px solid #000000'
    }),
    input: (base) => ({
        ...base,
        height: 54,
        minHeight: 54,
        padding: 0,
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '140%',
        margin: 0,
        marginTop: -3,
        color: '#000000',
        paddingLeft: 1
    }),
    valueContainer: (base) => ({
        ...base,
        padding: 0
    }),
    singleValue: (base) => ({
        ...base,
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '140%',
        margin: 0,
        color: '#000000',
        paddingLeft: 4
    }),
    placeholder: (base) => ({
        ...base,
        paddingLeft: 4,
        fontFamily: "Roboto",
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        lineHeight: '140%',
        margin: 0
    })
}