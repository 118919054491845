import {
  ERROR_FETCHING_CURRENT_ADMIN,
  START_FETCHING_CURRENT_ADMIN,
  SUCCESS_FETCHING_CURRENT_ADMIN,
  SET_FIRST_NAME,
  SET_MIDDLE_NAME,
  SET_LAST_NAME,
  SET_TIME_ZONE,
  SET_DEFAULT_PHOTO,
  SET_PHOTO,
  ERROR_UPDATING_CURRENT_ADMIN,
  START_UPDATING_CURRENT_ADMIN,
  SUCCESS_UPDATING_CURRENT_ADMIN,
} from "../types/currentAdmin";

const defaultState = {
  currentAdmin: null,
  loading: false,
  error: false,
  updatingInProcess: false,
  updatingError: false
}

export const currentAdminReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case START_FETCHING_CURRENT_ADMIN: {
      return {
        ...state,
        currentAdmin: null,
        loading: true,
        error: false
      }
    }
    case SUCCESS_FETCHING_CURRENT_ADMIN: {
      return {
        ...state,
        currentAdmin: payload,
        loading: false
      }
    }
    case ERROR_FETCHING_CURRENT_ADMIN: {
      return {
        ...state,
        currentAdmin: null,
        loading: false,
        error: payload
      }
    }
    case SET_FIRST_NAME: {
      return {
        ...state,
        currentAdmin: {
          ...state.currentAdmin,
          firstName: payload
        }
      }
    }
    case SET_MIDDLE_NAME: {
      return {
        ...state,
        currentAdmin: {
          ...state.currentAdmin,
          middleName: payload
        }
      }
    }
    case SET_LAST_NAME: {
      return {
        ...state,
        currentAdmin: {
          ...state.currentAdmin,
          lastName: payload
        }
      }
    }
    case SET_TIME_ZONE: {
      return {
        ...state,
        currentAdmin: {
          ...state.currentAdmin,
          timeZoneName: payload.timeZoneName,
          timeZone: payload.timeZone
        }
      }
    }
    case SET_DEFAULT_PHOTO: {
      return {
        ...state,
        currentAdmin: {
          ...state.currentAdmin,
          photoUrl: '/img/avatar.png',
        }
      }
    }
    case SET_PHOTO: {
      return {
        ...state,
        currentAdmin: {
          ...state.currentAdmin,
          photoUrl: payload,
        }
      }
    }
    case START_UPDATING_CURRENT_ADMIN: {
      return {
        ...state,
        updatingInProcess: true,
        updatingError: false
      }
    }
    case ERROR_UPDATING_CURRENT_ADMIN: {
      return {
        ...state,
        updatingInProcess: false,
        updatingError: payload
      }
    }
    case SUCCESS_UPDATING_CURRENT_ADMIN: {
      return {
        ...state,
        updatingInProcess: false,
        updatingError: false
      }
    }
    default: {
      return state
    }
  }
}