import style from './textBlock.module.scss'
import React from 'react';

const TextBlock = (props) => {

  let fontSize = null;
  let letterSpacing = null;
  let lineHeight = null;

  if(props.big){
    fontSize = '22px';
    letterSpacing = '0.01em';
    lineHeight = '130%';
  } else if(props.reg) {
    fontSize = '18px';
    letterSpacing = '';
    lineHeight = '140%';
  } else if(props.small) {
    fontSize = '14px';
    lineHeight = '150%';
    letterSpacing = '0em';
  } else{
    fontSize = '18px';
    lineHeight = '140%';
    letterSpacing = '';
  }


  return(
    <div 
      className={`${style.text} ${props.className}`}
      style={{
        width: props.width,
        height: props.height,
        fontSize: fontSize,
        letterSpacing: letterSpacing,
        lineHeight: lineHeight,
        marginTop: props.marginTop,
        marginRight: props.marginRight,
        marginLeft: props.marginLeft,
        marginBottom: props.marginBottom,
        fontFeatureSettings: props.small? "'salt' on, 'ss01' on, 'ss02' on, 'ss03' on" : null,
        ...props.style
      }}
    >
      {props.children}
    </div>
  );
}

TextBlock.defaultProps = {
  width: 'auto',
  height: 'auto'
}

export default TextBlock;