export const initialState = {
  currentUser: {
    loading: false,
    error: false,
    info: {},
    birthday: ''
  },
  sessions: {
    sessions: {},
    currentSpecialistFromSessions: [],
    workingDays: []
  },
  currentSpecialist: {},
  currentAdmin: {
    currentAdmin: {},
    loading: false,
    error: false,
  },
  today: ''
}
