import axios from "axios";
import {
  SESSION_SERVICE_API,
  SESSION_ATTACNMENT_SERVICE_API,
  PROFILE_SERVICE_API,
} from "../path";
import AuthenticatedUserInformationService from "./authenticatedUserInformation.service";
import { uuidv4 } from "../functions/generators/generators";
import { axiosConsloeLogErrorHandler } from "./errorHandlers";
import dateFormat from "dateformat";

class SessionService {
  accessToken = AuthenticatedUserInformationService.getUserToken();
  mp4AbortControler = new AbortController();
  pdfAbortControler = new AbortController();

  getSpecialistsSessionsForCurrentDay = async (specialistId, date) => {
    const handleDate = dateFormat(date, "isoDate") + "T00:00:00";
    try {
      const response = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/Session/specialist?SpecialistId=${specialistId}&Date=${handleDate}`,
        headers: { Authorization: "Bearer " + this.accessToken },
      });
      return response;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  getDetailInfoGroup = async (groupId, timeZone) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/group/info?id=${groupId}&timezone=${timeZone}`,
        headers: { Authorization: "Bearer " + this.accessToken },
      });
      return response;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  getSessionInformation = async (sessionId) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/Session?Id=${sessionId}`,
        headers: { Authorization: "Bearer " + this.accessToken },
      });
      return response;
    } catch (error) {
      return console.log(error);
    }
  };

  getSessionAttachment = async (sessionId) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${SESSION_ATTACNMENT_SERVICE_API}/Attachment?SessionId=${sessionId}`,
        headers: { Authorization: "Bearer " + this.accessToken },
      });
      return response;
    } catch (error) {
      return error;
    }
  };

  addLinkToAttachments = async (
    sessionId,
    linkName,
    link,
    onUploadProgress
  ) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SESSION_ATTACNMENT_SERVICE_API}/Attachment/link?SessionId=${sessionId}&Link=${link}&Name=${linkName}`,
        headers: { Authorization: "Bearer " + this.accessToken },
        onUploadProgress: onUploadProgress,
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  stopAddingPdfToAttachments = () => {
    this.pdfAbortControler.abort();
    this.pdfAbortControler = new AbortController();
  };

  stopAddinMp4ToAttachments = () => {
    this.mp4AbortControler.abort();
    this.mp4AbortControler = new AbortController();
  };

  deleteAttachment = async (attachmentId) => {
    try {
      const response = await axios({
        method: "DELETE",
        url: `${SESSION_ATTACNMENT_SERVICE_API}/Attachment?id=${attachmentId}`,
        headers: { Authorization: "Bearer " + this.accessToken },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  createSpecialistReview = async (sessionId, studentId, review) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/SessionReview/specialist`,
        headers: { Authorization: "Bearer " + this.accessToken },
        data: {
          SessionId: sessionId,
          StudentId: studentId,
          Message: review,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  createStudentFeedback = async (sessionId, feedback, rating) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/SessionReview/student`,
        headers: { Authorization: "Bearer " + this.accessToken },
        data: {
          SessionId: sessionId,
          Rating: rating,
          Message: feedback,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  #getAttachmentAploadLink = async (key, contentType) => {
    try {
      const response = await axios({
        method: "GET",
        url: `${SESSION_ATTACNMENT_SERVICE_API}/Attachment/upload-link?Key=${key}&contentType=${contentType}`,
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  #setInformationAboutMp4ToBack = async (sessionId, mp4Name, key) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SESSION_ATTACNMENT_SERVICE_API}/Attachment/video`,
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
        data: {
          SessionId: sessionId,
          FileName: mp4Name,
          FileKey: key,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  #setInformationAboutPdfToBack = async (sessionId, pdfName, key) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${SESSION_ATTACNMENT_SERVICE_API}/Attachment/pdf`,
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
        data: {
          SessionId: sessionId,
          FileName: pdfName,
          FileKey: key,
        },
      });
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  #postPdfToAmazonServerWithProvaidedLink = async (
    providedLink,
    pdfFormData,
    onUploadProgress
  ) => {
    try {
      const respnse = await axios({
        method: "PUT",
        url: providedLink,
        data: pdfFormData,
        signal: this.pdfAbortControler.signal,
        onUploadProgress: onUploadProgress,
        headers: {
          "Content-Type": "application/pdf",
        },
      });
      return respnse;
    } catch (error) {
      console.log(error);
    }
  };

  #postMp4ToAmazonServerWithProvaidedLink = async (
    providedLink,
    mp4formData,
    onUploadProgress
  ) => {
    try {
      const respnse = await axios({
        method: "PUT",
        url: providedLink,
        data: mp4formData,
        signal: this.mp4AbortControler.signal,
        onUploadProgress: onUploadProgress,
        contentType: "video/mp4",
        headers: {
          "Content-Type": "video/mp4",
        },
      });
      return respnse;
    } catch (error) {
      console.log(error);
    }
  };

  newAddMp4ToAttachments = async (
    mp4formData,
    mp4Name,
    sessionId,
    onUploadProgress
  ) => {
    const key = uuidv4() + "." + mp4Name;
    let firstPartResponse = null;
    let secondPartResponse = null;
    let thirdPartResponse = null;

    firstPartResponse = await this.#getAttachmentAploadLink(key, "video/mp4");

    if (firstPartResponse) {
      const uploadUrl = firstPartResponse.data.url;
      secondPartResponse = await this.#postMp4ToAmazonServerWithProvaidedLink(
        uploadUrl,
        mp4formData,
        onUploadProgress
      );
    }

    if (secondPartResponse) {
      thirdPartResponse = await this.#setInformationAboutMp4ToBack(
        sessionId,
        mp4Name,
        key
      );
      return thirdPartResponse;
    }
  };

  newAddPdfToAttachments = async (
    pdfFormData,
    pdfName,
    sessionId,
    onUploadProgress
  ) => {
    const key = uuidv4() + "." + pdfName;
    let firstPartResponse = null;
    let secondPartResponse = null;
    let thirdPartResponse = null;

    firstPartResponse = await this.#getAttachmentAploadLink(
      key,
      "application/pdf"
    );

    if (firstPartResponse) {
      const uploadUrl = firstPartResponse.data.url;
      secondPartResponse = await this.#postPdfToAmazonServerWithProvaidedLink(
        uploadUrl,
        pdfFormData,
        onUploadProgress
      );
    }

    if (secondPartResponse) {
      thirdPartResponse = await this.#setInformationAboutPdfToBack(
        sessionId,
        pdfName,
        key
      );
      return thirdPartResponse;
    }
  };

  generationSessionCode = async (sessionId) => {
    try {
      const respnse = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/Session/code`,
        data: {
          sessionId: sessionId,
        },
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  cancelSession = async (sessionId) => {
    try {
      const respnse = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/Session/cancel`,
        data: {
          sessionId: sessionId,
        },
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  approveFeedback = async (sessionId, studentId) => {
    try {
      const respnse = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/SessionReview/approve`,
        data: {
          SessionId: sessionId,
          StudentId: studentId,
        },
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  resolveFeedback = async (sessionId, studentId) => {
    try {
      const respnse = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/SessionReview/resolve`,
        data: {
          SessionId: sessionId,
          StudentId: studentId,
        },
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  archiveFeedback = async (sessionId, studentId) => {
    try {
      const respnse = await axios({
        method: "POST",
        url: `${SESSION_SERVICE_API}/SessionReview/archive`,
        data: {
          SessionId: sessionId,
          StudentId: studentId,
        },
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  getGroupInformation = async (sessionId, timeZone) => {
    try {
      const respnse = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/group/info?id=${sessionId}&timezone=${timeZone}`,
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  getGroupBySessionId = async (sessionId, timeZone) => {
    try {
      const respnse = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/group/groupsession/info?SessionId=${sessionId}&TimeZone=${timeZone}`,
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  getNewReviews = async () => {
    try {
      const respnse = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/SessionReview/new`,
        headers: {
          Authorization: "Bearer " + this.accessToken,
        },
      });
      return respnse;
    } catch (error) {
      axiosConsloeLogErrorHandler(error);
    }
  };

  async getFeedbacksOfSpecialist(specialistId) {
    try {
      const response = await axios({
        method: "GET",
        url: `${SESSION_SERVICE_API}/SessionReview/public?SpecialistId=${specialistId}`,
        headers: { Authorization: "Bearer " + this.accessToken },
      });
      const allFeedbacks = response.data;
      return allFeedbacks;
    } catch (err) {
      console.log(err);
    }
  }
}

export default new SessionService();
