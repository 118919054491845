import React, { useEffect } from "react";
import Spinner from "../uiComponents/spinner/Spinner";
import AuthenticatedUserInformationService from "../../services/authenticatedUserInformation.service";

function SpinnerPage(props) {

  //обнуление навбара
  useEffect(() => {
    props.setNoNavBar(true);
    return function () {
      props.setNoNavBar(false);
    }
  }, [])

  let role = AuthenticatedUserInformationService.getUserRole();

  switch (role) {
    case "Student": window.location = "/sessions"
      break
    case "Specialist": window.location = "/specialist-sessions";
      break
    case "Admin": window.location = "/dashboard/new-specialists-accounts"
      break
    default: window.location = '/'
  }

  return <Spinner marginTop='345px' />;
}

export default SpinnerPage;
