import style from './blueButton.module.scss'
import React from 'react';

const BlueButton = (props) => {

  const { marginTop, marginRight, marginLeft, marginBottom, className, width, height, children, ...rest } = props;
  //className типа 
  //{
  //  ...
  //  button{
  //    ...
  //  }
  //}

  return (
    <button
      className={`${style.button} ${className}`}
      style={{
        marginTop: marginTop,
        marginRight: marginRight,
        marginLeft: marginLeft,
        marginBottom: marginBottom,
        width: width,
        height: height
      }}
      {...rest}
    >
      {children}
    </button>
  );
}

export default BlueButton;