export const SERVER_IP = process.env.REACT_APP_BASE_URL;
export const PROFILE_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/prs`;
export const PLAN_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/pls`;
export const SCHEDULE_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/scs`;
export const SESSION_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/ses`;
export const SESSION_ATTACNMENT_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/mes`;
export const PAYMENT_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/pas`;
export const CHECKER_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/api/chs`;
export const NOTIFICATION_API = `${process.env.REACT_APP_BASE_URL}/api/nos`;
export const AUTH_SERVICE_API = `${process.env.REACT_APP_BASE_URL}/auth`;
export const DOCUSIGN_API = `${process.env.REACT_APP_BASE_URL}/api/dss`;
