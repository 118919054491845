import React from "react";

function SeventhBenefitsSVG() {
  return (
    <svg
      width="330"
      height="320"
      viewBox="0 0 330 320"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M64.4797 309.817C73.006 316.016 82.905 319.008 92.7318 319.008C107.521 319.008 122.116 312.23 131.509 299.493C131.536 299.466 131.561 299.438 131.585 299.408C131.608 299.378 131.631 299.348 131.652 299.319L131.684 299.272C131.707 299.241 131.728 299.209 131.749 299.18L164.987 253.421L198.225 299.18C198.258 299.229 198.292 299.267 198.325 299.31C198.34 299.33 198.354 299.35 198.37 299.372C214.026 320.721 244.108 325.424 265.52 309.841C286.956 294.259 291.749 264.131 276.286 242.615L276.252 242.561C276.223 242.516 276.201 242.482 276.165 242.446L242.999 196.663L296.734 179.151C321.976 170.926 335.848 143.644 327.635 118.364C319.423 93.0849 292.206 79.1909 266.941 87.3924L213.207 104.833L213.183 48.219C213.158 21.6369 191.553 0 165.012 0C138.47 0 116.865 21.6369 116.841 48.2431L116.793 104.808L63.0588 87.3682C63.0376 87.3682 63.0117 87.3635 62.9856 87.3561C62.9517 87.3471 62.917 87.3334 62.89 87.32C37.6727 79.2152 10.5526 93.109 2.36363 118.341C-5.82548 143.572 7.95155 170.757 33.0724 179.055C33.0956 179.062 33.1164 179.07 33.1364 179.079L33.2249 179.113L33.2653 179.127L86.9754 196.638L53.8097 242.422C53.7977 242.446 53.7798 242.47 53.7619 242.495C53.7436 242.519 53.7257 242.543 53.7137 242.567C38.2266 264.083 43.0438 294.235 64.4797 309.817Z"
        fill="#67B4F3"
      />
    </svg>
  );
}

export default SeventhBenefitsSVG;
