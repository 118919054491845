import React, { useEffect, useState } from "react";
import style from "../Navigation/navigation.module.scss";
import authinticationService from "../../services/Authentication.service";
import { NavLink, useLocation } from "react-router-dom";
import "./Navigation.css";
import LogoSVG from "../../svg/LogoSVG/LogoSVG";
import BurgerSVG from "../../svg/BurgerSVG/BurgerSVG";
import BurgerCloseSVG from "../../svg/BurgerCloseSVG/BurgerCloseSVG";

function Navigation() {
  let location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div
      // className={
      //   location?.pathname === "/"
      //     ? style.navigationWithoutLine
      //     : style.navigation
      // }
      className={style.navigation}
    >
      <div className={style.logo} onClick={closeMenu}>
        <NavLink to="/">
          <LogoSVG />
        </NavLink>
      </div>
      <div className={style.burger__item} onClick={openMenu}>
        {isOpen ? <BurgerCloseSVG /> : <BurgerSVG />}
      </div>

      <div className={style.link}>
        <NavLink to="/about-us" activeClassName="navigationActiveLink">
          About Us
        </NavLink>
        <NavLink
          to="/curriculum-benefits"
          activeClassName="navigationActiveLink"
        >
          Curriculum & Benefits
        </NavLink>
        <NavLink to="/for-specialists" activeClassName="navigationActiveLink">
          Work with Us
        </NavLink>

        <NavLink to="/find-group">Group Sessions</NavLink>
        <NavLink to="/rate">One-on-one Sessions</NavLink>
        <NavLink to="/faq" activeClassName="navigationActiveLink">
          FAQ
        </NavLink>
        <NavLink to="/contact-us" activeClassName="navigationActiveLink">
          Contact us
        </NavLink>
        <a
          onClick={() => {
            authinticationService.login();
          }}
        >
          Log in
        </a>
      </div>

      {/* mobile */}
      <div
        className={style.burger__menu}
        style={isOpen ? { dispalay: "flex" } : { display: "none" }}
      >
        <hr className={style.hr} />
        <a
          onClick={() => {
            openMenu();
            authinticationService.login();
          }}
        >
          Log in
        </a>
        <NavLink
          onClick={openMenu}
          to="/about-us"
          activeClassName="navigationActiveLink"
        >
          About Us
        </NavLink>
        <NavLink
          onClick={openMenu}
          to="/curriculum-benefits"
          activeClassName="navigationActiveLink"
        >
          Curriculum & Benefits
        </NavLink>
        <NavLink
          onClick={openMenu}
          to="/for-specialists"
          activeClassName="navigationActiveLink"
        >
          Work with Us
        </NavLink>

        <NavLink onClick={openMenu} to="/find-group">
          Group Sessions
        </NavLink>
        <NavLink onClick={openMenu} to="/rate">
          One-on-one Sessions
        </NavLink>
        <NavLink
          onClick={openMenu}
          to="/faq"
          activeClassName="navigationActiveLink"
        >
          FAQ
        </NavLink>
        <NavLink
          onClick={openMenu}
          to="/contact-us"
          activeClassName="navigationActiveLink"
        >
          Contact us
        </NavLink>
      </div>
    </div>
  );
}

export default Navigation;
