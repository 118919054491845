import style from './avatar.module.scss'
import React from 'react';

const Avatar = (props) => {
  return (
    <div
      className={`${props.className} ${style.avatarContainer}`}
    >
      {(props.photoUrl === null || props.photoUrl) ?
        (
          (props.defaultPhoto == 'none' && !props.photoUrl) ?
            <div className={style.emtyAvatar}/>
            :
            <img
              src={props.photoUrl !== null ? props.photoUrl : props.defaultPhoto}
              alt={props.alt}
            />
        )
        :
        <div className={style.loadingImg} />
      }
    </div>
  );
}

Avatar.defaultProps = {
  defaultPhoto: '/img/avatar.png',
  alt: "avatar"
}

export default Avatar;