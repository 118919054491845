import React, { useEffect } from "react";
import style from "./GroupTimeSlots.module.scss";
import moment from "moment";

function TimeSlots({ time, isLoadingTimeSlots, startDate}) {
  function formatAMPM() {
    let strTime
    let currentDay = moment(startDate).format("YYYY-MM-DD");
    const myArr = time?.map((el)=> {
      return el.split("T")
    })
    
    for (let i = 0; i < myArr?.length; i++) {
      if (myArr[i].includes(currentDay)) {
        strTime = myArr[i][1]
      }
    }
    let myNumb = Number(strTime?.slice(0,2))
    let ampm = myNumb >= 12 ? "PM" : "AM";
    myNumb = myNumb % 12;
    myNumb = myNumb ? myNumb : 12;
    myNumb = myNumb + ":00 " + ampm;
    return myNumb;
  }
  const myFunc = formatAMPM(time)

  return (
    <>
      {isLoadingTimeSlots &&
        <div className={style.slot}>
          {myFunc}
        </div>
      }
    </>
  );
}

export default TimeSlots;