import React, { useEffect, useState } from "react";
import style from "../SpecialistRegistration/specialistRegistration.module.scss";
import "react-datepicker/dist/react-datepicker.css";
import { Formik } from "formik";
import checkFields from "./checkFieldsSpecialist";
import { Select as CustomSelect } from "../Select/Select";
import Select from "react-select";
import { city, state } from "../../city";
import Email from "../Email/Email";
import { SERVER_IP } from "../../path";
import CitiesStatesService from "../../services/citiesStates.service";
import { cityStateSelectorStyles } from "./reactSelect.styles";
import { useHistory } from "react-router-dom";
import LogoBar from "../LogoBar/LogoBar";
import dateFnsFormat from "date-fns/format";
import registrationService from "../../services/registration.service";
import BlueButton from "../uiComponents/buttons/BlueButton/BlueButton";
import currentTimeZone from "../../services/timeZone.service";
import Footer from "../Footer/Footer";

const StudentRegistration = (props) => {
  //обнуление навбара
  useEffect(() => {
    props.setNoNavBar(true);
    return function () {
      props.setNoNavBar(false);
    };
  }, []);

  const history = useHistory();

  const [statesOptions, setStatesOptions] = useState([]);
  const [citiesInStateOptions, setCitiesIsStateOptions] = useState([]);
  const [specialistState, setSpecialistState] = useState("");

  useEffect(async () => {
    setStatesOptions(await CitiesStatesService.getAllStates());
  }, []);

  useEffect(async () => {
    if (!(specialistState === "")) {
      setCitiesIsStateOptions(
        await CitiesStatesService.getAllCitiesInState(specialistState)
      );
    }
  }, [specialistState]);

  const [result, setResult] = useState("error");

  const [timeZone, setTimeZone] = useState("-5");
  const [timeZoneName, setTimeZoneName] = useState("0");

  const [nameErrorBlock, setNameErrorBlock] = useState(false);
  const [nameStyle, setNameStyle] = useState("");
  const [iconNameStyle, setIconNameStyle] = useState("");

  const [middleNameErrorBlock, setMiddleNameErrorBlock] = useState(false);
  const [middleNameStyle, setMiddleNameStyle] = useState("");
  const [iconMiddleNameStyle, setIconMiddleNameStyle] = useState("");
  const [disabledInput, setDisabledInput] = useState(false);

  const [lastNameErrorBlock, setLastNameErrorBlock] = useState(false);
  const [lastNameStyle, setLastNameStyle] = useState("");
  const [iconLastNameStyle, setIconLastNameStyle] = useState("");

  const [educationErrorBlock, setEducationErrorBlock] = useState(false);
  const [educationStyle, setEducationStyle] = useState("");
  const [iconEducationStyle, setIconEducationStyle] = useState("");

  const [birthDate, setBirthDate] = useState("");
  const [birthDateErrorBlock, setBirthDateErrorBlock] = useState(false);
  const [birthDateStyle, setBirthDateStyle] = useState("");
  const [iconBirthDateStyle, setIconBirthDateStyle] = useState("");

  const [socialNumberErrorBlock, setSocialNumberErrorBlock] = useState(false);
  const [socialNumberStyle, setSocialNumberStyle] = useState("");
  const [iconSocialNumberStyle, setIconSocialNumberStyle] = useState("");

  const [phoneErrorBlock, setPhoneErrorBlock] = useState(false);
  const [phoneStyle, setPhoneStyle] = useState("");
  const [iconPhoneStyle, setIconPhoneStyle] = useState("");

  const [streetErrorBlock, setStreetErrorBlock] = useState(false);
  const [streetStyle, setStreetStyle] = useState("");
  const [iconStreetStyle, setIconStreetStyle] = useState("");

  const [aptErrorBlock, setAptErrorBlock] = useState(false);
  const [aptStyle, setAptStyle] = useState("");
  const [iconAptStyle, setIconAptStyle] = useState("");

  const [zipCodeErrorBlock, setZipCodeErrorBlock] = useState(false);
  const [zipCodeStyle, setZipCodeStyle] = useState("");
  const [iconZipCodeStyle, setIconZipCodeStyle] = useState("");

  const [cityErrorBlock, setCityErrorBlock] = useState(false);
  const [cityStyle, setCityStyle] = useState("");
  const [iconCityStyle, setIconCityStyle] = useState("");

  const [stateErrorBlock, setStateErrorBlock] = useState(false);
  const [stateStyle, setStateStyle] = useState("");
  const [iconStateStyle, setIconStateStyle] = useState("");

  const [buttonRegisterDisabled, setButtonRegisterDisabled] = useState(true);
  const [buttonRegisterClassName, setButtonRegisterClassName] =
    useState("buttonRegister");
  const [userEmail, setUserEmail] = useState("");

  const [passwordStyle, setPasswordStyle] = useState("");
  const [iconPasswordStyle, setIconPasswordStyle] = useState("");
  const [passwordMessageStyle, setPasswordMessageStyle] = useState("message");

  const [confirmPasswordStyle, setConfirmPasswordStyle] = useState("");
  const [confirmPasswordErrorBlock, setConfirmPasswordErrorBlock] =
    useState(false);
  const [iconConfirmPasswordStyle, setIconConfirmPasswordStyle] = useState("");
  const [passwordMessage, setPasswordMessage] = useState(
    "At least 6 characters."
  );

  const [checkboxStyle, setCheckboxStyle] = useState("empty");

  function changeCheckbox() {
    if (checkboxStyle === "empty") {
      setCheckboxStyle("checked");
      setMiddleNameErrorBlock(false);
      setMiddleNameStyle("disable");
      setIconMiddleNameStyle("");
      setDisabledInput(true);
    } else {
      setCheckboxStyle("empty");
      setMiddleNameErrorBlock(false);
      setMiddleNameStyle("");
      setIconMiddleNameStyle("");
      setDisabledInput(false);
    }
  }

  const [currentTimeZoneFromBack, setCurrentTimeZoneFromBack] = useState([]);

  const getTimeZoneFromBack = async () => {
    const response = await currentTimeZone.getTimeZone();
    setCurrentTimeZoneFromBack(response);
  };

  useEffect(() => {
    getTimeZoneFromBack();
  }, []);

  console.log(currentTimeZoneFromBack);

  const timezoneOptions = [
    {
      value: `GMT${currentTimeZoneFromBack[0]?.timeZone} CentralDaylightTime`,
      key: `GMT${currentTimeZoneFromBack[0]?.timeZone} Central Daylight Time`,
    },
    {
      value: `GMT${currentTimeZoneFromBack[1]?.timeZone} MountainDaylightTime`,
      key: `GMT${currentTimeZoneFromBack[1]?.timeZone} Mountain Daylight Time`,
    },
    {
      value: `GMT${currentTimeZoneFromBack[2]?.timeZone} MountainStandartTime`,
      key: `GMT${currentTimeZoneFromBack[2]?.timeZone} Mountain Standart Time`,
    },
    {
      value: `GMT${currentTimeZoneFromBack[3]?.timeZone} PacificDaylightTime`,
      key: `GMT${currentTimeZoneFromBack[3]?.timeZone} Pacific Daylight Time`,
    },
    {
      value: `GMT${currentTimeZoneFromBack[4]?.timeZone} AlaskaDaylightTime`,
      key: `GMT${currentTimeZoneFromBack[4]?.timeZone} Alaska Daylight Time`,
    },
    {
      value: `GMT${currentTimeZoneFromBack[5]?.timeZone} Hawaii-AleutianStandartTime`,
      key: `GMT${currentTimeZoneFromBack[5]?.timeZone} Hawaii-Aleutian Standart Time`,
    },
  ];

  // const timezoneOptions = [
  //   {
  //     value: "GMT-5 CentralDaylightTime",
  //     key: "GMT-5 Central Daylight Time"
  //   },
  //   {
  //     value: "GMT-6 MountainDaylightTime",
  //     key: "GMT-6 Mountain Daylight Time",
  //   },
  //   {
  //     value: "GMT-7 MountainStandartTime",
  //     key: "GMT-7 Mountain Standart Time",
  //   },
  //   {
  //     value: "GMT-7 PacificDaylightTime",
  //     key: "GMT-7 Pacific Daylight Time"
  //   },
  //   {
  //     value: "GMT-8 AlaskaDaylightTime",
  //     key: "GMT-8 Alaska Daylight Time"
  //   },
  //   {
  //     value: "GMT-10 Hawaii-AleutianStandartTime",
  //     key: "GMT-10 Hawaii-Aleutian Standart Time",
  //   },
  // ];

  function checkData(values) {
    if (disabledInput) {
      values.middleName = null;
    }
    const resultData = checkFields(values);
    setResult(resultData);
    setAptStyle(resultData.apt + "Input");
    setBirthDateStyle(resultData.birthDate + "Input");
    setCityStyle(resultData.city + "Input");
    setConfirmPasswordStyle(resultData.confirmPassword + "Input");
    setEducationStyle(resultData.education + "Input");
    setNameStyle(resultData.firstName + "Input");
    setLastNameStyle(resultData.lastName + "Input");
    setPasswordStyle(resultData.password + "Input");
    setPhoneStyle(resultData.phone + "Input");
    setSocialNumberStyle(resultData.socialNumber + "Input");
    setStateStyle(resultData.state + "Input");
    setStreetStyle(resultData.street + "Input");
    setZipCodeStyle(resultData.zipCode + "Input");

    if (resultData.firstName === "error") {
      setNameErrorBlock(true);
      setIconNameStyle("visibleImg");
    }
    if (resultData.firstName === "success") {
      setNameErrorBlock("");
      setIconNameStyle("visibleImg");
    }
    if (resultData.middleName === "error") {
      if (checkboxStyle === "checked") {
        setMiddleNameErrorBlock("");
        setIconMiddleNameStyle("visibleImg");
        // eslint-disable-next-line no-useless-concat
        setMiddleNameStyle("success" + "Input");
        setIconMiddleName("success");
      } else {
        setMiddleNameErrorBlock(true);
        setIconMiddleNameStyle("visibleImg");
        // eslint-disable-next-line no-useless-concat
        setMiddleNameStyle("error" + "Input");
        setIconMiddleName("error");
      }
    }
    if (resultData.middleName === "success") {
      // setMiddleNameErrorBlock("");
      // setIconMiddleNameStyle("visibleImg");
      // // eslint-disable-next-line no-useless-concat
      // setMiddleNameStyle("success" + "Input");
      // setIconMiddleName("success");
    }
    if (resultData.lastName === "error") {
      setLastNameErrorBlock(true);
      setIconLastNameStyle("visibleImg");
    }
    if (resultData.lastName === "success") {
      setLastNameErrorBlock("");
      setIconLastNameStyle("visibleImg");
    }
    if (resultData.education === "error") {
      setEducationErrorBlock(true);
      setIconEducationStyle("visibleImg");
    }
    if (resultData.education === "success") {
      setEducationErrorBlock("");
      setIconEducationStyle("visibleImg");
    }
    if (resultData.birthDate === "error") {
      setBirthDateErrorBlock(true);
      setIconBirthDateStyle("visibleImg");
    }
    if (resultData.birthDate === "success") {
      setBirthDateErrorBlock("");
      setIconBirthDateStyle("visibleImg");
    }
    if (resultData.socialNumber === "error") {
      setSocialNumberErrorBlock(true);
      setIconSocialNumberStyle("visibleImg");
    }
    if (resultData.socialNumber === "success") {
      setSocialNumberErrorBlock("");
      setIconSocialNumberStyle("visibleImg");
    }
    if (resultData.phone === "error") {
      setPhoneErrorBlock(true);
      setIconPhoneStyle("visibleImg");
    }
    if (resultData.phone === "success") {
      setPhoneErrorBlock("");
      setIconPhoneStyle("visibleImg");
    }
    if (resultData.street === "error") {
      setStreetErrorBlock(true);
      setIconStreetStyle("visibleImg");
    }
    if (resultData.street === "success") {
      setStreetErrorBlock("");
      setIconStreetStyle("visibleImg");
    }
    if (resultData.apt === "error") {
      setAptErrorBlock(true);
      setIconAptStyle("visibleImg");
    }
    if (resultData.apt === "success") {
      setAptErrorBlock("");
      setIconAptStyle("visibleImg");
    }
    if (resultData.zipCode === "error") {
      setZipCodeErrorBlock(true);
      setIconZipCodeStyle("visibleImg");
    }
    if (resultData.zipCode === "success") {
      setZipCodeErrorBlock("");
      setIconZipCodeStyle("visibleImg");
    }
    if (resultData.city === "error") {
      setCityErrorBlock(true);
      setIconCityStyle("visibleImg");
    }
    if (resultData.city === "success") {
      setCityErrorBlock("");
      setIconCityStyle("visibleImg");
    }
    if (resultData.state === "error") {
      setStateErrorBlock(true);
      setIconStateStyle("visibleImg");
    }
    if (resultData.state === "success") {
      setStateErrorBlock("");
      setIconStateStyle("visibleImg");
    }

    if (resultData.password === "error") {
      setIconPasswordStyle("visibleImg");
      setPasswordMessageStyle("errorMessage");
      setPasswordMessage("Error explonation");
    }
    if (resultData.password === "success") {
      setIconPasswordStyle("visibleImg");
      setPasswordMessage("At least 6 characters.");
      setPasswordMessageStyle("message");
    }
    if (resultData.confirmPassword === "error") {
      setConfirmPasswordErrorBlock(true);
      setIconConfirmPasswordStyle("visibleImg");
    }
    if (resultData.confirmPassword === "success") {
      setConfirmPasswordErrorBlock("");
      setIconConfirmPasswordStyle("visibleImg");
    }
    for (let key in resultData) {
      if (typeof resultData[key] === "object") {
        for (let i in resultData[key]) {
          if (resultData[key][i] === "error") {
            return null;
          }
        }
      } else {
        if (resultData[key] === "error") {
          return null;
        }
      }
    }
    try {
      let string = values.timeZone;
      let array = [];
      let timeZone = -5;
      let timeZoneName = 0;
      array = string.split(/(\s+)/);
      switch (array[0].length) {
        case 5:
          // setTimeZone(array[0].substring(3, 5));
          timeZone = array[0].substring(3, 5);
          break;
        case 6:
          // setTimeZone(array[0].substring(3, 6));
          timeZone = array[0].substring(3, 6);
          break;
        default:
          console.log("error");
      }
      switch (array[2]) {
        case "CentralDaylightTime":
          // setTimeZoneName("0");
          timeZoneName = 0;
          break;
        case "MountainDaylightTime":
          // setTimeZoneName("1");
          timeZoneName = 1;
          break;
        case "MountainStandartTime":
          // setTimeZoneName("2");
          timeZoneName = 2;
          break;
        case "PacificDaylightTime":
          // setTimeZoneName("3");
          timeZoneName = 3;
          break;
        case "AlaskaDaylightTime":
          // setTimeZoneName("4");
          timeZoneName = 4;
          break;
        case "Hawaii-AleutianStandartTime":
          // setTimeZoneName("5");
          timeZoneName = 5;
          break;
        default:
          console.log("error");
      }
      registrationService
        .registerSpecialist({
          email: userEmail,
          password: values.password,
          firstName: values.firstName,
          middleName: values.middleName,
          lastName: values.lastName,
          educationalInstitution: values.education,
          dateOfBirthday: dateFnsFormat(
            new Date(values.birthDate),
            "yyyy-MM-dd'T'HH:mm:ss.SSS"
          ),
          ssn: values.socialNumber,
          phoneNumber: values.phone,
          street: values.street,
          apt: values.apt,
          city: values.city,
          state: values.state,
          zipCode: values.zipCode,
          timeZone: timeZone,
          timeZoneName: timeZoneName,
          degree: +values.degree,
        })
        .then((response) => {
          if (response) {
            history.push("/after-reg");
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  function onFocusConfirmPassword() {
    setConfirmPasswordStyle(true);
    setConfirmPasswordErrorBlock(false);
    setIconConfirmPasswordStyle("");
  }

  function onFocusPassword() {
    setPasswordStyle(true);
    setIconPasswordStyle("");
    setPasswordMessage("At least 6 characters.");
    setPasswordMessageStyle("message");
  }

  function onFocusName() {
    setNameStyle(true);
    setNameErrorBlock(false);
    setIconNameStyle("");
  }

  function onFocusMiddleName() {
    setMiddleNameStyle(true);
    setMiddleNameErrorBlock(false);
    setIconMiddleNameStyle("");
  }

  function onFocusLastName() {
    setLastNameStyle(true);
    setLastNameErrorBlock(false);
    setIconLastNameStyle("");
  }

  function onFocusEducation() {
    setEducationStyle(true);
    setEducationErrorBlock(false);
    setIconEducationStyle("");
  }
  function onFocusBirthDate() {
    setBirthDateStyle(true);
    setBirthDateErrorBlock(false);
    setIconBirthDateStyle("");
  }

  function onFocusSocialNumber() {
    setSocialNumberStyle(true);
    setSocialNumberErrorBlock(false);
    setIconSocialNumberStyle("");
  }

  function onFocusPhone() {
    setPhoneStyle(true);
    setPhoneErrorBlock(false);
    setIconPhoneStyle("");
  }

  function onFocusStreet() {
    setStreetStyle(true);
    setStreetErrorBlock(false);
    setIconStreetStyle("");
  }

  function onFocusApt() {
    setAptStyle(true);
    setAptErrorBlock(false);
    setIconAptStyle("");
  }

  function onFocusZipCode() {
    setZipCodeStyle(true);
    setZipCodeErrorBlock(false);
    setIconZipCodeStyle("");
  }

  function onFocusCity() {
    setCityStyle("");
    setCityErrorBlock(false);
    setIconCityStyle("");
  }

  function onFocusState() {
    setStateStyle("");
    setStateErrorBlock(false);
    setIconStateStyle("");
  }

  const degreeOptions = [
    { value: 1, key: "BA" },
    { value: 2, key: "MA" },
    { value: 3, key: "PHD" },
  ];

  const [iconMiddleName, setIconMiddleName] = useState();

  return (
    <>
      <div className={style.studentReg}>
        <LogoBar
          onClick={() => {
            history.push("/");
          }}
        />
        <Formik
          initialValues={{
            email: "",
            password: "",
            confirmPassword: "",
            firstName: "",
            middleName: "",
            lastName: "",
            education: "",
            birthDate: "",
            ssn: "",
            phone: "",
            street: "",
            apt: "",
            city: "",
            state: "",
            zipCode: "",
            degree: 1,
            timeZone: timezoneOptions[0].key,
          }}
          onSubmit={(values) => {
            checkData(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            setFieldValue,
            handleBlur,
            isValid,
            handleSubmit,
            dirty,
          }) => (
            <div className={style.form}>
              <h1>Registration form</h1>
              <h2>Personal details</h2>
              <div className={style.wrapperName}>
                <div className={style.blocksmallInput}>
                  <label>First Name</label>
                  <div className={style.smallInput}>
                    <input
                      className={style[nameStyle]}
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      required
                      onFocus={onFocusName}
                    />
                    <img
                      src={`/img/${result.firstName}.png`}
                      className={style[iconNameStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {nameErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
                <div className={style.blocksmallInput}>
                  <label>Middle Name</label>
                  <div>
                    <div className={style.inputAndErrorBlock}>
                      <div className={style.smallInput}>
                        <input
                          className={style[middleNameStyle]}
                          type="text"
                          name="middleName"
                          value={values.middleName}
                          onChange={handleChange}
                          required
                          onFocus={onFocusMiddleName}
                          disabled={disabledInput}
                        />
                        <img
                          src={`/img/${iconMiddleName}.png`}
                          className={style[iconMiddleNameStyle]}
                          alt="validation icon"
                        />
                      </div>
                      {middleNameErrorBlock && (
                        <div className={style.errorMessage}>
                          Error explonation
                        </div>
                      )}
                    </div>
                    <div className={style.wrapperCheckbox}>
                      <img
                        src={`/img/${checkboxStyle}.png`}
                        onClick={changeCheckbox}
                      />
                      <span>I don’t have middle name</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.wrapperLastName}>
                <label>Last Name</label>
                <div className={style.lastNameInput}>
                  <input
                    className={style[lastNameStyle]}
                    type="text"
                    name="lastName"
                    value={values.lastName}
                    onChange={handleChange}
                    required
                    onFocus={onFocusLastName}
                  />
                  <img
                    src={`/img/${result.lastName}.png`}
                    className={style[iconLastNameStyle]}
                    alt="validation icon"
                  />
                </div>
                {lastNameErrorBlock && (
                  <div className={style.errorMessage}>Error explonation</div>
                )}
              </div>

              <div className={style.wrapperName}>
                <div className={style.blocksmallInput}>
                  <label>Degree</label>
                  <div className={style.flexContainer}>
                    <div className={style.blockDegree}>
                      <CustomSelect options={degreeOptions} name="degree" />
                    </div>
                    <div className={style.timeZoneImg}></div>
                  </div>
                </div>
                <div className={style.blocksmallInput}>
                  <label>Educational institution</label>
                  <div className={style.inputAndErrorBlock}>
                    <div className={style.smallInput}>
                      <input
                        className={style[educationStyle]}
                        type="text"
                        name="education"
                        value={values.education}
                        onChange={handleChange}
                        required
                        onFocus={onFocusEducation}
                      />
                      <img
                        src={`/img/${result.education}.png`}
                        className={style[iconEducationStyle]}
                        alt="validation icon"
                      />
                    </div>
                    {educationErrorBlock && (
                      <div className={style.errorMessage}>
                        Error explonation
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.threeBlocksWrapper}>
                <div className={style.blockBirth} onFocus={onFocusBirthDate}>
                  <label>Date of Birth </label>
                  <div className={style.calendarWrapper}>
                    <input
                      className={style[birthDateStyle]}
                      name="birthDate"
                      type="text"
                      maxLength="10"
                      placeholder="mm/dd/yyyy"
                      onChange={handleChange}
                      onKeyDown={(ev) => {
                        if (ev.code === "Backspace") return;
                        let ipLength = ev.target.value.length;
                        if (ipLength === 2 || ipLength === 5) {
                          ev.target.value += "/";
                        }
                      }}
                    />
                    <img
                      src={`/img/${result.birthDate}.png`}
                      className={style[iconBirthDateStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {birthDateErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
                <div className={style.blockSocialNumberInput}>
                  <label>SSN</label>
                  <div className={style.smallInput}>
                    <input
                      className={style[socialNumberStyle]}
                      type="text"
                      name="socialNumber"
                      maxlength="11"
                      value={values.socialNumber}
                      onChange={handleChange}
                      required
                      onFocus={onFocusSocialNumber}
                      placeholder="000000000"
                      onKeyDown={(ev) => {
                        if (ev.code === "Backspace") return;
                        let ipLength = ev.target.value.length;
                        if (ipLength === 3 || ipLength === 6) {
                          ev.target.value += "-";
                        }
                      }}
                    />
                    <img
                      src={`/img/${result.socialNumber}.png`}
                      className={style[iconSocialNumberStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {socialNumberErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
                <div className={style.blockPhoneInput}>
                  <label>Phone number</label>
                  <div className={style.smallInput}>
                    <input
                      className={style[phoneStyle]}
                      type="text"
                      name="phone"
                      value={values.phoneNumber}
                      onChange={handleChange}
                      required
                      onFocus={onFocusPhone}
                      placeholder="(000) 000-0000"
                      maxLength={12}
                      onKeyDown={(ev) => {
                        if (ev.code === "Backspace") return;
                        let ipLength = ev.target.value.length;
                        if (ipLength === 3 || ipLength === 7) {
                          ev.target.value += "-";
                        }
                      }}
                    />
                    <img
                      src={`/img/${result.phone}.png`}
                      className={style[iconPhoneStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {phoneErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
              </div>

              <hr className={style.difficultHr}/>

              <h2>Address</h2>
              <div className={style.wrapperName}>
                <div className={style.blocksmallInput}>
                  <label>Street</label>
                  <div className={style.smallInput}>
                    <input
                      className={style[streetStyle]}
                      type="text"
                      name="street"
                      value={values.street}
                      onChange={handleChange}
                      required
                      onFocus={onFocusStreet}
                    />
                    <img
                      src={`/img/${result.street}.png`}
                      className={style[iconStreetStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {streetErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
                <div className={style.blocksmallInput}>
                  <label>Apt / Suite / Other</label>
                  <div className={style.inputAndErrorBlock}>
                    <div className={style.smallInput}>
                      <input
                        className={style[aptStyle]}
                        type="text"
                        name="apt"
                        value={values.apt}
                        onChange={handleChange}
                        required
                        onFocus={onFocusApt}
                      />
                      <img
                        src={`/img/${result.apt}.png`}
                        className={style[iconAptStyle]}
                        alt="validation icon"
                      />
                    </div>
                    {aptErrorBlock && (
                      <div className={style.errorMessage}>
                        Error explonation
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className={style.threeBlocksWrapper}>
                <div className={style.blocksmallInputCity}>
                  <label>City</label>
                  <div className={style.containerCity}>
                    <div className={style.blockCity}>
                      <Select
                        id="city"
                        styles={cityStateSelectorStyles}
                        name="city"
                        options={citiesInStateOptions}
                        value={{
                          value: values.city,
                          label: values.city,
                        }}
                        onFocus={onFocusCity}
                        onChange={(option) => {
                          setFieldValue("city", option.value);
                        }}
                        isDisabled={citiesInStateOptions?.length === 0}
                      />
                      <img
                        src={`/img/${result.city}.png`}
                        className={style[iconCityStyle]}
                        alt="validation icon"
                        style={{
                          marginTop: "16px",
                        }}
                      />
                    </div>
                    {cityErrorBlock && (
                      <div className={style.errorMessage}>
                        Error explonation
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.blocksmallInputCity}>
                  <label>State</label>
                  <div className={style.containerCity}>
                    <div className={style.blockCity}>
                      <Select
                        id="state"
                        styles={cityStateSelectorStyles}
                        name="state"
                        options={statesOptions}
                        value={{
                          value: values.state,
                          label: values.state,
                        }}
                        onFocus={onFocusState}
                        onChange={(option) => {
                          setFieldValue("state", option.value);
                          setSpecialistState(option.value);
                          setFieldValue("city", "");
                          setCitiesIsStateOptions([]);
                        }}
                      />
                      <img
                        src={`/img/${result.state}.png`}
                        className={style[iconStateStyle]}
                        alt="validation icon"
                        style={{
                          marginTop: "16px",
                        }}
                      />
                    </div>
                    {stateErrorBlock && (
                      <div className={style.errorMessage}>
                        Error explonation
                      </div>
                    )}
                  </div>
                </div>
                <div className={style.blockZipCodeInput}>
                  <label>Zip code</label>
                  <div className={style.smallInput}>
                    <input
                      maxLength={5}
                      className={style[zipCodeStyle]}
                      type="text"
                      name="zipCode"
                      value={values.zipCodeNumber}
                      onChange={handleChange}
                      required
                      onFocus={onFocusZipCode}
                      placeholder="00000"
                    />
                    <img
                      src={`/img/${result.zipCode}.png`}
                      className={style[iconZipCodeStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {zipCodeErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
              </div>

              <hr className={style.difficultHr}/>

              <h2>Account information</h2>
              <div className={style.wrapperTimeZone}>
                <label>Time zone</label>
                <div className={style.flexContainer}>
                  <div className={style.blockZone}>
                    <CustomSelect options={timezoneOptions} name="timeZone" />
                  </div>
                  <div className={style.timeZoneImg}></div>
                </div>
              </div>

              <div className={style.wrapperPassword}>
                <div className={style.blocksmallInput}>
                  <label>Password</label>
                  <div className={style.smallInput}>
                    <input
                      className={style[passwordStyle]}
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      required
                      onFocus={onFocusPassword}
                      minLength="6"
                    />
                    <img
                      src={`/img/${result.password}.png`}
                      className={style[iconPasswordStyle]}
                      alt="validation icon"
                    />
                  </div>
                  <div className={style[passwordMessageStyle]}>
                    {passwordMessage}
                  </div>
                </div>
                {touched.confirmPassword && errors.confirmPassword && (
                  <p className={"error"}>{errors.confirmPassword}</p>
                )}
                <div className={style.blocksmallInput}>
                  <label>Confirm password</label>
                  <div className={style.smallInput}>
                    <input
                      className={style[confirmPasswordStyle]}
                      type="password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleChange}
                      required
                      onFocus={onFocusConfirmPassword}
                    />
                    <img
                      src={`/img/${result.confirmPassword}.png`}
                      className={style[iconConfirmPasswordStyle]}
                      alt="validation icon"
                    />
                  </div>
                  {confirmPasswordErrorBlock && (
                    <div className={style.errorMessage}>Error explonation</div>
                  )}
                </div>
              </div>
              <Email
                setButtonRegisterDisabled={setButtonRegisterDisabled}
                setButtonRegisterClassName={setButtonRegisterClassName}
                buttonRegisterClassName={buttonRegisterClassName}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
              />

              <p className={style.info}>
                By continuing you agree to our{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://vriends-public.s3.us-west-1.amazonaws.com/2022+0419+Terms+of+Use_Provider.pdf"
                >
                  Terms of Use
                </a>{" "}
                and{" "}
                <a
                  rel="noreferrer"
                  target="_blank"
                  href="https://vriends-public.s3.us-west-1.amazonaws.com/2022_0419_Privacy_Policy.pdf"
                >
                  Privacy Policy
                </a>
                .
              </p>

              <BlueButton
                type="button"
                className={style[buttonRegisterClassName]}
                onClick={handleSubmit}
                disabled={buttonRegisterDisabled}
              >
                Register
              </BlueButton>
            </div>
          )}
        </Formik>
        <hr className={style.hr} />
      </div>
      <Footer />
    </>
  );
};

export default StudentRegistration;
