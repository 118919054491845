import axios from "axios";
import { PAYMENT_SERVICE_API } from "../path";
import AuthenticatedUserInformationService from './authenticatedUserInformation.service';

class PaymentService {
  accessToken = AuthenticatedUserInformationService.getUserToken();

  getGroupSessionPaymentLink = async (studentId, groupId, planId) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${PAYMENT_SERVICE_API}/Payment`,
        data: {
          planId: planId,
          groupId: groupId,
          studentId: studentId,
        }
      })
      return response;
    } catch (error) {
      throw(error);
    }
  }

  getSessionPaymentLink = async (studentId, specialistId, planId) => {
    try {
      const response = await axios({
        method: "POST",
        url: `${PAYMENT_SERVICE_API}/Payment`,
        data: {
          planId: planId,
          specialistId: specialistId,
          studentId: studentId,
        }
      })
      return response;
    } catch (error) {
      throw(error);
    }
  }
}

const paymentService = new PaymentService();
export default paymentService;