import React from 'react'

function RightArroySVG(props) {
  return (
    <svg
      onClick={props.onClick}
      width={props.size}
      height={props.size} 
      viewBox="0 0 24 24" 
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2 12.4854H22" stroke="#2B4BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.25 2.48535L22 12.4854L13.25 22.4854" stroke="#2B4BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
  )
}

RightArroySVG.defaultProps = {
  color: 'none',
  size: 24,
  onClick: ()=>{}
}

export default RightArroySVG