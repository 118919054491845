import React from "react";
import ReactStars from "react-rating-stars-component";
import style from "../StarComponent/starComponent.module.scss";

function StarComponent({ rating }) {

  
  return (
    <div className={style.wrapper}>
      <ReactStars
        activeColor="#2B4BF2"
        size={40}
        count={5}
        isHalf={false}
        // onChange={ratingChanged}
        value={rating}
        edit={false}
        onChange={()=>{console.log(123321123321123321)}}
      />
    </div>
  );
}

export default StarComponent;
