import "./App.css";
import React, { Suspense } from "react";
import LoginReact from "./components/Login/LoginReact";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LandingPage from "./components/LandingPage/LandingPage";
import authenticatedUserInformationService from "./services/authenticatedUserInformation.service";
import NavigationVRauthAdmin from "./components/NavigationVRauthAdmin/NavigationVRauthAdmin";
import Navigation from "./components/Navigation/Navigation";
import PrivatRoute from "./components/PrivatRoute/PrivatRoute";
import { useEffect, useState } from "react";
import NavigationVRauth from "./components/NavigationVRauth/NavigationVRauth";
import NavigationVRauthSpecialist from "./components/NavigationVRauthSpecialist/NavigationVRauthSpecialist";
import PageAfterRegister from "./components/PageAfterRegister/PageAfterRegister";
import { withSuspense } from "./hoc/withSuspense";

// import ForSpecialists from "./components/ForSpecialists/ForSpecialists";
// import CurriculumBenefits from "./components/CurriculumBenefits/CurriculumBenefits";
// import AboutUs from "./components/AboutUs/AboutUs";
// import FAQ from "./components/FAQ/FAQ";
// import ContactUs from "./components/ContactUs/ContactUs";
// import FeedbackSent from "./components/ContactUs/FeedbackSent/FeedbackSent";
// import StudentRegistration from "./components/StudentRegistration/StudentRegistration";
import SpecialistRegistration from "./components/SpecialistRegistration/SpecialistRegistration";
// import UserNeedApprove from "./components/UserNeedApprove/UserNeedApprove";
// import UserNeedSignDocs from "./components/UserNeedSignDocs/UserNeedSignDocs";
// import VRauth from "./components/VRauth/VRauth";
// import VRauthSpecialist from "./components/VRauthSpecialist/VRauthSpecialist";
// import RatesPage from "./components/RatesPage/RatesPage";
// import DetailInfoCard from "./components/DetailInfoCard/DetailInfoCard";
// import SessionsStudent from "./components/SessionsStudent/SessionsStudent";
// import SuccessPayment from "./components/SuccessPayment/SuccessPayment";
// import SuccessBooking from "./components/SuccessBooking/SuccessBooking";
// import ScheduleSession from "./components/ScheduleSession/ScheduleSession";
// import SpinnerPage from "./components/SpinnerPage/SpinnerPage";
// import RescheduleSession from "./components/RescheduleSession/RescheduleSession";
// import ChangeSpecialist from "./components/ChangeSpecialist/ChangeSpecialist";
// import ChangeSpecialistInfo from "./components/ChangeSpecialist/ChangeSpecialistInfo/ChangeSpecialistInfo";
// import AcceptChange from "./components/ChangeSpecialist/AcceptChange/AcceptChange";
// import ResetPassword from "./components/ResetPassword/ResetPassword";
// import RecoveryPassword from "./components/RecoveryPassword/RecoveryPassword";
// import UserProfileEdit from "./components/UserProfileEdit/UserProfileEdit";
// import Payments from "./components/Payments/Payments";
// import PaymentInfo from "./components/Payments/PaymentInfo/PaymentInfo";
// import SpecialistProfileEdit from "./components/SpecialistProfileEdit/SpecialistProfileEdit";
// import SpecialistSessions from "./components/SpecialistSessions/SpecialistSessions";
// import Availability from "./components/Availability/Availability";
// import LogoutPage from "./components/LogoutPage/LogoutPage";
// import UpcomingSession from "./components/UpcomingSession/UpcomingSession";
// import SpecialistComplitedSession from "./components/specialistComplitedSession/SpecialistComplitedSession";
// import ComplitedSessionNote from "./components/ComplitedSessionNote/ComplitedSessionNote";
// import StudentComlitedSession from "./components/StudentComplitedSession/StudentComplitedSession";
// import Dashboard from "./components/Dashboard/Dashboard";
// import AdminGroups from "./components/AdminGroups/AdminGroups";
// import CreateGroup from "./components/CreateGroup/CreateGroup";
// import StudentGroupSession from "./components/StudentGroupSession/StudentGroupSession";
// import AdminRegistration from "./components/AdminRegistration/AdminRegistration";
import GroupDetailInfoCard from "./components/GroupDetailInfoCard/GroupDetailInfoCard";
import Spinner from "./components/uiComponents/spinner/Spinner";
// import AllAdmins from "./components/AllAdmins/AllAdmins";
// import AllSpecialists from "./components/AllSpecialists/AllSpecialists";
// import AllStudents from "./components/AllStudents/AllStudents";
// import InviteAdmin from "./components/InviteAdmin/InviteAdmin";
// import InviteDone from "./components/InviteAdmin/InviteDone/InviteDone";
// import AdminStudentInformation from "./components/AdminStudentInformation/AdminStudentInformation";
// import AdminSpecialistInformation from "./components/AdminSpecialistInformation/AdminSpecialistInformation";
// import AdminPersonalInfoEdit from "./components/AdminPersonalInfoEdit/AdminPersonalInfoEdit";
// import AdminUpcomingSesssion from "./components/AdminUpcomingSesssion/AdminUpcomingSesssion";
// import AdminInProgressSession from "./components/AdminInProgressSession/AdminInProgressSession";
// import AdminComplitedSession from "./components/AdminComplitedSession/AdminComplitedSession";
// import AdminJoinSession from "./components/AdminJoinSession/AdminJoinSession";
// import SuccessfulSessionCancellation from "./components/SuccessfulSessionCancellation/SuccessfulSessionCancellation";
// import GroupUpcomingSession from "./components/GroupUpcomingSession/GroupUpcomingSession";
// import Coupons from "./components/Coupons/Coupons";
// import CreateCoupon from "./components/Coupons/CreateCoupon/CreateCoupon";
// import CreatedCoupon from "./components/Coupons/CreateCoupon/CreatedCoupon/CreatedCoupon";
// import AdminPayments from "./components/AdminPayments/AdminPayments";
// import AdminNewSesialist from "./components/AdminNewSpecialist/AdminNewSpecialist";
// import SucsessSpecialistApprove from "./components/SucsessSpecialistApprove/SucsessSpecialistApprove";
// import SucsessSpecialistDecline from "./components/SucsessSpecialistDecline/SucsessSpecialistDecline";
// import SucsessSpecialistVerify from "./components/SucsessSpecialistVerify/SucsessSpecialistVerify";
// import CheckrSeeDetails from "./components/CheckrSeeDetails/CheckrSeeDetails";
// import Props from "./components/Props/Props";
// import AddPropCategory from "./components/AddPropCategory/AddPropCategory";
// import EditCategory from "./components/EditCategory/EditCategory";
// import AfterSpecialistRegistration from "./components/PageAfterRegister/PageAfterRegister";
// import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
// import AdminInformationPage from "./components/AdminInformationPage/AdminInformationPage";
// import TemporaryLoginLink from "./components/TemporaryLoginLink/TemporaryLoginLink";
// import CookiesComponent from "./components/CookiesComponent/CookiesComponent";

const ForSpecialists = React.lazy(() =>
  import("./components/ForSpecialists/ForSpecialists")
);
const CurriculumBenefits = React.lazy(() =>
  import("./components/CurriculumBenefits/CurriculumBenefits")
);
const AboutUs = React.lazy(() => import("./components/AboutUs/AboutUs"));
const FAQ = React.lazy(() => import("./components/FAQ/FAQ"));
const ContactUs = React.lazy(() => import("./components/ContactUs/ContactUs"));
const FeedbackSent = React.lazy(() =>
  import("./components/ContactUs/FeedbackSent/FeedbackSent")
);
const StudentRegistration = React.lazy(() =>
  import("./components/StudentRegistration/StudentRegistration")
);
// const SpecialistRegistration = React.lazy(() =>
//   import("./components/SpecialistRegistration/SpecialistRegistration")
// );
const UserNeedApprove = React.lazy(() =>
  import("./components/UserNeedApprove/UserNeedApprove")
);
const UserNeedSignDocs = React.lazy(() =>
  import("./components/UserNeedSignDocs/UserNeedSignDocs")
);
const VRauth = React.lazy(() => import("./components/VRauth/VRauth"));
const VRauthSpecialist = React.lazy(() =>
  import("./components/VRauthSpecialist/VRauthSpecialist")
);
const RatesPage = React.lazy(() => import("./components/RatesPage/RatesPage"));
const DetailInfoCard = React.lazy(() =>
  import("./components/DetailInfoCard/DetailInfoCard")
);
const SessionsStudent = React.lazy(() =>
  import("./components/SessionsStudent/SessionsStudent")
);
const SuccessPayment = React.lazy(() =>
  import("./components/SuccessPayment/SuccessPayment")
);
const SuccessBooking = React.lazy(() =>
  import("./components/SuccessBooking/SuccessBooking")
);
const ScheduleSession = React.lazy(() =>
  import("./components/ScheduleSession/ScheduleSession")
);
const SpinnerPage = React.lazy(() =>
  import("./components/SpinnerPage/SpinnerPage")
);
const RescheduleSession = React.lazy(() =>
  import("./components/RescheduleSession/RescheduleSession")
);
const ChangeSpecialist = React.lazy(() =>
  import("./components/ChangeSpecialist/ChangeSpecialist")
);
const ChangeSpecialistInfo = React.lazy(() =>
  import(
    "./components/ChangeSpecialist/ChangeSpecialistInfo/ChangeSpecialistInfo"
  )
);
const AcceptChange = React.lazy(() =>
  import("./components/ChangeSpecialist/AcceptChange/AcceptChange")
);
const ResetPassword = React.lazy(() =>
  import("./components/ResetPassword/ResetPassword")
);
const RecoveryPassword = React.lazy(() =>
  import("./components/RecoveryPassword/RecoveryPassword")
);
const UserProfileEdit = React.lazy(() =>
  import("./components/UserProfileEdit/UserProfileEdit")
);
const Payments = React.lazy(() => import("./components/Payments/Payments"));
const PaymentInfo = React.lazy(() =>
  import("./components/Payments/PaymentInfo/PaymentInfo")
);
const SpecialistProfileEdit = React.lazy(() =>
  import("./components/SpecialistProfileEdit/SpecialistProfileEdit")
);
const SpecialistSessions = React.lazy(() =>
  import("./components/SpecialistSessions/SpecialistSessions")
);
const Availability = React.lazy(() =>
  import("./components/Availability/Availability")
);
const LogoutPage = React.lazy(() =>
  import("./components/LogoutPage/LogoutPage")
);
const UpcomingSession = React.lazy(() =>
  import("./components/UpcomingSession/UpcomingSession")
);
const SpecialistComplitedSession = React.lazy(() =>
  import("./components/specialistComplitedSession/SpecialistComplitedSession")
);
const ComplitedSessionNote = React.lazy(() =>
  import("./components/ComplitedSessionNote/ComplitedSessionNote")
);
const StudentComlitedSession = React.lazy(() =>
  import("./components/StudentComplitedSession/StudentComplitedSession")
);
const Dashboard = React.lazy(() => import("./components/Dashboard/Dashboard"));
const AdminGroups = React.lazy(() =>
  import("./components/AdminGroups/AdminGroups")
);
const CreateGroup = React.lazy(() =>
  import("./components/CreateGroup/CreateGroup")
);
const StudentGroupSession = React.lazy(() =>
  import("./components/StudentGroupSession/StudentGroupSession")
);
const AdminRegistration = React.lazy(() =>
  import("./components/AdminRegistration/AdminRegistration")
);
// const GroupDetailInfoCard = React.lazy(() =>
//   import("./components/GroupDetailInfoCard/GroupDetailInfoCard")
// );
const AllAdmins = React.lazy(() => import("./components/AllAdmins/AllAdmins"));
const AllSpecialists = React.lazy(() =>
  import("./components/AllSpecialists/AllSpecialists")
);
const AllStudents = React.lazy(() =>
  import("./components/AllStudents/AllStudents")
);
const InviteAdmin = React.lazy(() =>
  import("./components/InviteAdmin/InviteAdmin")
);
const InviteDone = React.lazy(() =>
  import("./components/InviteAdmin/InviteDone/InviteDone")
);
const AdminStudentInformation = React.lazy(() =>
  import("./components/AdminStudentInformation/AdminStudentInformation")
);
const AdminSpecialistInformation = React.lazy(() =>
  import("./components/AdminSpecialistInformation/AdminSpecialistInformation")
);
const AdminPersonalInfoEdit = React.lazy(() =>
  import("./components/AdminPersonalInfoEdit/AdminPersonalInfoEdit")
);
const AdminUpcomingSesssion = React.lazy(() =>
  import("./components/AdminUpcomingSesssion/AdminUpcomingSesssion")
);
const AdminInProgressSession = React.lazy(() =>
  import("./components/AdminInProgressSession/AdminInProgressSession")
);
const AdminComplitedSession = React.lazy(() =>
  import("./components/AdminComplitedSession/AdminComplitedSession")
);
const AdminJoinSession = React.lazy(() =>
  import("./components/AdminJoinSession/AdminJoinSession")
);
const SuccessfulSessionCancellation = React.lazy(() =>
  import(
    "./components/SuccessfulSessionCancellation/SuccessfulSessionCancellation"
  )
);
const GroupUpcomingSession = React.lazy(() =>
  import("./components/GroupUpcomingSession/GroupUpcomingSession")
);
const Coupons = React.lazy(() => import("./components/Coupons/Coupons"));
const CreateCoupon = React.lazy(() =>
  import("./components/Coupons/CreateCoupon/CreateCoupon")
);
const CreatedCoupon = React.lazy(() =>
  import("./components/Coupons/CreateCoupon/CreatedCoupon/CreatedCoupon")
);
const AdminPayments = React.lazy(() =>
  import("./components/AdminPayments/AdminPayments")
);
const AdminNewSesialist = React.lazy(() =>
  import("./components/AdminNewSpecialist/AdminNewSpecialist")
);
const SucsessSpecialistApprove = React.lazy(() =>
  import("./components/SucsessSpecialistApprove/SucsessSpecialistApprove")
);
const SucsessSpecialistDecline = React.lazy(() =>
  import("./components/SucsessSpecialistDecline/SucsessSpecialistDecline")
);
const SucsessSpecialistVerify = React.lazy(() =>
  import("./components/SucsessSpecialistVerify/SucsessSpecialistVerify")
);
const CheckrSeeDetails = React.lazy(() =>
  import("./components/CheckrSeeDetails/CheckrSeeDetails")
);
const Props = React.lazy(() => import("./components/Props/Props"));
const AddPropCategory = React.lazy(() =>
  import("./components/AddPropCategory/AddPropCategory")
);
const EditCategory = React.lazy(() =>
  import("./components/EditCategory/EditCategory")
);
const AfterSpecialistRegistration = React.lazy(() =>
  import("./components/PageAfterRegister/PageAfterRegister")
);
const PrivacyPolicy = React.lazy(() =>
  import("./components/PrivacyPolicy/PrivacyPolicy")
);
const AdminInformationPage = React.lazy(() =>
  import("./components/AdminInformationPage/AdminInformationPage")
);
const TemporaryLoginLink = React.lazy(() =>
  import("./components/TemporaryLoginLink/TemporaryLoginLink")
);
const CookiesComponent = React.lazy(() =>
  import("./components/CookiesComponent/CookiesComponent")
);
//////////////////////
function App() {
  const isCookiesAccept = localStorage.getItem("isCookiesAccept");
  const [cookies, setCookies] = useState(isCookiesAccept);

  let role = authenticatedUserInformationService.getUserRole();
  const [noNavBar, setNoNavBar] = useState();
  const [NavBar, setNavBar] = useState();

  useEffect(() => {
    if (noNavBar) {
      setNavBar((prev) => {
        return null;
      });
    } else {
      switch (role) {
        case "Admin":
          setNavBar((prev) => {
            return <NavigationVRauthAdmin />;
          });
          break;
        case "Student":
          setNavBar((prev) => {
            return <NavigationVRauth />;
          });
          break;
        case "Specialist":
          setNavBar((prev) => {
            return <NavigationVRauthSpecialist />;
          });
          break;
        default:
          setNavBar((prev) => {
            return <Navigation />;
          });
          break;
      }
    }
  }, [noNavBar, role]);

  return (
    <Router>
      <Suspense fallback={<Spinner marginTop={"300px"} />}>
        {NavBar}
        <Switch>
          <Route exact path="/">
            {authenticatedUserInformationService.isUserLogedIn() ? (
              <SpinnerPage setNoNavBar={setNoNavBar} />
            ) : (
              <LandingPage />
            )}
          </Route>
          <Route
            exact
            path="/for-specialists"
            render={withSuspense(ForSpecialists)}
          />
          <Route
            exact
            path="/curriculum-benefits"
            render={withSuspense(CurriculumBenefits)}
          ></Route>
          <Route path="/about-us" render={withSuspense(AboutUs)}></Route>
          <Route path="/faq" render={withSuspense(FAQ)}></Route>
          <Route
            exact
            path="/contact-us"
            render={withSuspense(ContactUs)}
          ></Route>
          <Route
            exact
            path="/feedback-sent"
            render={withSuspense(FeedbackSent)}
          ></Route>
          <Route exact path="/student-reg">
            <StudentRegistration setNoNavBar={setNoNavBar} />
          </Route>
          <Route exact path="/specialist-reg">
            <SpecialistRegistration setNoNavBar={setNoNavBar} />
          </Route>
          <Route exact path="/user-need-approve">
            <UserNeedApprove setNoNavBar={setNoNavBar} />
          </Route>
          <Route exact path="/user-need-sign-docs">
            <UserNeedSignDocs setNoNavBar={setNoNavBar} />
          </Route>

          <PrivatRoute exact path="/vr" forAllReles>
            <VRauth />
          </PrivatRoute>
          <PrivatRoute exact path="/vr-specialist" forRole="Specialist">
            <VRauthSpecialist />
          </PrivatRoute>
          <Route exact path="/rate">
            <RatesPage />
          </Route>
          <Route exact path="/spec-card/:id">
            <DetailInfoCard />
          </Route>
          <PrivatRoute exact path="/sessions" forRole="Student">
            <SessionsStudent />
          </PrivatRoute>
          <Route exact path="/success-payment">
            <SuccessPayment setNoNavBar={setNoNavBar} />
          </Route>
          <PrivatRoute
            exact
            path="/success-booking/:sessionId"
            forRole="Student"
          >
            <SuccessBooking />
          </PrivatRoute>
          <PrivatRoute
            exact
            path="/schedule-session/:sessionId"
            forRole="Student"
          >
            <ScheduleSession />
          </PrivatRoute>
          <PrivatRoute exact path="/loading" forAllReles>
            <SpinnerPage setNoNavBar={setNoNavBar} />
          </PrivatRoute>
          <PrivatRoute
            exact
            path="/reschedule-session/:sessionId"
            forRole="Student"
          >
            <RescheduleSession />
          </PrivatRoute>
          <PrivatRoute exact path="/change-specialist" forRole="Student">
            <ChangeSpecialist />
          </PrivatRoute>
          <PrivatRoute
            exact
            path="/change-specialist-info/:id"
            forRole="Student"
          >
            <ChangeSpecialistInfo />
          </PrivatRoute>
          <PrivatRoute exact path="/accept-change" forRole="Student">
            <AcceptChange />
          </PrivatRoute>
          <Route exact path="/reset-password">
            <ResetPassword setNoNavBar={setNoNavBar} />
          </Route>
          <Route exact path="/passwordrecovery">
            <RecoveryPassword setNoNavBar={setNoNavBar} />
          </Route>
          <PrivatRoute exact path="/edit-student" forRole="Student">
            <UserProfileEdit />
          </PrivatRoute>
          <PrivatRoute exact path="/payments" forRole="Student">
            <Payments />
          </PrivatRoute>
          <PrivatRoute exact path="/paymentInfo" forRole="Student">
            <PaymentInfo />
          </PrivatRoute>
          <PrivatRoute exact path="/edit-specialist" forRole="Specialist">
            <SpecialistProfileEdit />
          </PrivatRoute>
          <PrivatRoute exact path="/specialist-sessions" forRole="Specialist">
            <SpecialistSessions />
          </PrivatRoute>
          <PrivatRoute exact path="/availability" forRole="Specialist">
            <Availability />
          </PrivatRoute>
          <PrivatRoute exact path="/logout" forAllReles>
            <LogoutPage setNoNavBar={setNoNavBar} />
          </PrivatRoute>
          <PrivatRoute
            exact
            path="/upcoming-session/:sessionId"
            forRole="Specialist"
          >
            <UpcomingSession />
          </PrivatRoute>
          <PrivatRoute
            exact
            path="/specialist-complited-session/:sessionId"
            forRole="Specialist"
          >
            <SpecialistComplitedSession />
          </PrivatRoute>
          <PrivatRoute exact path="/complited-session-note" forAllReles>
            <ComplitedSessionNote />
          </PrivatRoute>
          <PrivatRoute
            exact
            path="/student-complited-session/:sessionId"
            forRole="Student"
          >
            <StudentComlitedSession />
          </PrivatRoute>
          <PrivatRoute path="/dashboard" forRole="Admin">
            <Dashboard />
          </PrivatRoute>
          <PrivatRoute exact path="/admin-groups" forRole="Admin">
            <AdminGroups />
          </PrivatRoute>
          <PrivatRoute path="/create-group" forRole="Admin">
            <CreateGroup />
          </PrivatRoute>
          {/* <PrivatRoute
          path="/create-group/choose-specialist"
          forRole="Admin"
        ></PrivatRoute> */}
          <Route exact path="/find-group">
            <StudentGroupSession />
          </Route>
          {/* для любого? */}
          <Route exact path="/admin-reg">
            <AdminRegistration setNoNavBar={setNoNavBar} />
          </Route>
          <Route exact path="/group-card/:groupId">
            <GroupDetailInfoCard />
          </Route>
          {/* <PrivatRoute
            path="/create-group/schedule-sessions"
            forRole="Admin"
          ></PrivatRoute> */}
          <PrivatRoute exact path="/all-admins" forRole="Admin">
            <AllAdmins />
          </PrivatRoute>
          <PrivatRoute exact path="/all-specialists" forRole="Admin">
            <AllSpecialists />
          </PrivatRoute>
          <PrivatRoute exact path="/all-students" forRole="Admin">
            <AllStudents />
          </PrivatRoute>
          <PrivatRoute exact path="/invite-admin" forRole="Admin">
            <InviteAdmin />
          </PrivatRoute>
          <PrivatRoute exact path="/invite-done" forRole="Admin">
            <InviteDone />
          </PrivatRoute>
          <PrivatRoute path="/student-info/:studentId" forRole="Admin">
            <AdminStudentInformation />
          </PrivatRoute>
          <PrivatRoute path="/specialist-info/:specialistId" forRole="Admin">
            <AdminSpecialistInformation />
          </PrivatRoute>
          <PrivatRoute path="/admin-personal-info" forRole="Admin">
            <AdminPersonalInfoEdit />
          </PrivatRoute>
          <PrivatRoute
            path={`/admin-upcoming-session/:sessionId`}
            exact
            forRole="Admin"
          >
            <AdminUpcomingSesssion />
          </PrivatRoute>
          <PrivatRoute
            path={`/admin-in-progress-session/:sessionId`}
            exact
            forRole="Admin"
          >
            <AdminInProgressSession />
          </PrivatRoute>
          <PrivatRoute
            path={`/admin-complited-session/:sessionId`}
            exact
            forRole="Admin"
          >
            <AdminComplitedSession />
          </PrivatRoute>
          <PrivatRoute
            path={`/admin-join-session/:sessionId`}
            exact
            forRole="Admin"
          >
            <AdminJoinSession />
          </PrivatRoute>
          <PrivatRoute
            path={`/successful-session-cancellation`}
            exact
            forRole="Admin"
          >
            <SuccessfulSessionCancellation />
          </PrivatRoute>
          <PrivatRoute
            path={`/group-upcoming-session/:sessionId`}
            exact
            forRole="Specialist"
          >
            <GroupUpcomingSession />
          </PrivatRoute>
          <PrivatRoute path={`/coupons`} exact forRole="Admin">
            <Coupons />
          </PrivatRoute>
          <PrivatRoute path={`/create-coupon`} exact forRole="Admin">
            <CreateCoupon />
          </PrivatRoute>
          <PrivatRoute path={`/created-coupon`} exact forRole="Admin">
            <CreatedCoupon />
          </PrivatRoute>
          <PrivatRoute path={`/admin-payments`} exact forRole="Admin">
            <AdminPayments />
          </PrivatRoute>
          <PrivatRoute
            path={`/admin-new-specialist/:specialistId`}
            exact
            forRole="Admin"
          >
            <AdminNewSesialist />
          </PrivatRoute>
          <PrivatRoute
            path={`/sucsess-specialist-approve/:specialistId`}
            exact
            forRole="Admin"
          >
            <SucsessSpecialistApprove />
          </PrivatRoute>
          <PrivatRoute
            path={`/sucsess-specialist-decline/:specialistId`}
            exact
            forRole="Admin"
          >
            <SucsessSpecialistDecline />
          </PrivatRoute>
          <PrivatRoute
            path={`/sucsess-specialist-verify/:specialistId`}
            exact
            forRole="Admin"
          >
            <SucsessSpecialistVerify />
          </PrivatRoute>
          <PrivatRoute
            path={`/checkr-see-details/:reportId`}
            exact
            forRole="Admin"
          >
            <CheckrSeeDetails />
          </PrivatRoute>
          <PrivatRoute path={`/props`} exact forRole="Admin">
            <Props />
          </PrivatRoute>
          <PrivatRoute path={`/add-prop`} forRole="Admin">
            <AddPropCategory />
          </PrivatRoute>
          <PrivatRoute path={`/edit-category/:categoryId`} forRole="Admin">
            <EditCategory />
          </PrivatRoute>
          <Route exact path="/after-reg">
            <AfterSpecialistRegistration setNoNavBar={setNoNavBar} />
          </Route>
          <Route exact path="/privacy-policy">
            <PrivacyPolicy setNoNavBar={setNoNavBar} />
          </Route>
          <PrivatRoute path="/admin-info/:adminId" forRole="Admin">
            <AdminInformationPage />
          </PrivatRoute>
          <Route exact path="/temp-login">
            <TemporaryLoginLink setNoNavBar={setNoNavBar} />
          </Route>
        </Switch>
        {!cookies && <CookiesComponent setCookies={setCookies} />}
      </Suspense>
    </Router>
  );

  ///////////////////

  // return (
  //   <Router>
  //     {NavBar}
  //     <Switch>
  //       <Route exact path="/">
  //         {authenticatedUserInformationService.isUserLogedIn() ? (
  //           <SpinnerPage setNoNavBar={setNoNavBar} />
  //         ) : (
  //           <LandingPage />
  //         )}
  //       </Route>
  //       <Route exact path="/for-specialists">
  //         <ForSpecialists />
  //       </Route>
  //       <Route exact path="/curriculum-benefits">
  //         <CurriculumBenefits />
  //       </Route>
  //       <Route exact path="/about-us">
  //         <AboutUs />
  //       </Route>
  //       <Route path="/faq">
  //         <FAQ />
  //       </Route>
  //       <Route exact path="/contact-us">
  //         <ContactUs />
  //       </Route>
  //       <Route exact path="/feedback-sent">
  //         <FeedbackSent />
  //       </Route>
  //       <Route exact path="/student-reg">
  //         <StudentRegistration setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/specialist-reg">
  //         <SpecialistRegistration setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/specialist-reg">
  //         <SpecialistRegistration setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/user-need-approve">
  //         <UserNeedApprove setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/user-need-sign-docs">
  //         <UserNeedSignDocs setNoNavBar={setNoNavBar} />
  //       </Route>

  //       <PrivatRoute exact path="/vr" forAllReles>
  //         <VRauth />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/vr-specialist" forRole="Specialist">
  //         <VRauthSpecialist />
  //       </PrivatRoute>
  //       <Route exact path="/rate">
  //         <RatesPage />
  //       </Route>
  //       <Route exact path="/spec-card/:id">
  //         <DetailInfoCard />
  //       </Route>
  //       <PrivatRoute exact path="/sessions" forRole="Student">
  //         <SessionsStudent />
  //       </PrivatRoute>
  //       <Route exact path="/success-payment">
  //         <SuccessPayment setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <PrivatRoute exact path="/success-booking/:sessionId" forRole="Student">
  //         <SuccessBooking />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         exact
  //         path="/schedule-session/:sessionId"
  //         forRole="Student"
  //       >
  //         <ScheduleSession />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/loading" forAllReles>
  //         <SpinnerPage setNoNavBar={setNoNavBar} />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         exact
  //         path="/reschedule-session/:sessionId"
  //         forRole="Student"
  //       >
  //         <RescheduleSession />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/change-specialist" forRole="Student">
  //         <ChangeSpecialist />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/change-specialist-info/:id" forRole="Student">
  //         <ChangeSpecialistInfo />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/accept-change" forRole="Student">
  //         <AcceptChange />
  //       </PrivatRoute>
  //       <Route exact path="/reset-password">
  //         <ResetPassword setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/passwordrecovery">
  //         <RecoveryPassword setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <PrivatRoute exact path="/edit-student" forRole="Student">
  //         <UserProfileEdit />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/payments" forRole="Student">
  //         <Payments />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/paymentInfo" forRole="Student">
  //         <PaymentInfo />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/edit-specialist" forRole="Specialist">
  //         <SpecialistProfileEdit />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/specialist-sessions" forRole="Specialist">
  //         <SpecialistSessions />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/availability" forRole="Specialist">
  //         <Availability />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/logout" forAllReles>
  //         <LogoutPage setNoNavBar={setNoNavBar} />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         exact
  //         path="/upcoming-session/:sessionId"
  //         forRole="Specialist"
  //       >
  //         <UpcomingSession />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         exact
  //         path="/specialist-complited-session/:sessionId"
  //         forRole="Specialist"
  //       >
  //         <SpecialistComplitedSession />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/complited-session-note" forAllReles>
  //         <ComplitedSessionNote />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         exact
  //         path="/student-complited-session/:sessionId"
  //         forRole="Student"
  //       >
  //         <StudentComlitedSession />
  //       </PrivatRoute>
  //       <PrivatRoute path="/dashboard" forRole="Admin">
  //         <Dashboard />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/admin-groups" forRole="Admin">
  //         <AdminGroups />
  //       </PrivatRoute>
  //       <PrivatRoute path="/create-group" forRole="Admin">
  //         <CreateGroup />
  //       </PrivatRoute>
  //       {/* <PrivatRoute
  //         path="/create-group/choose-specialist"
  //         forRole="Admin"
  //       ></PrivatRoute> */}
  //       <Route exact path="/find-group">
  //         <StudentGroupSession />
  //       </Route>
  //       {/* для любого? */}
  //       <Route exact path="/admin-reg">
  //         <AdminRegistration setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/group-card/:groupId">
  //         <GroupDetailInfoCard />
  //       </Route>
  //       <PrivatRoute
  //         path="/create-group/schedule-sessions"
  //         forRole="Admin"
  //       ></PrivatRoute>
  //       <PrivatRoute exact path="/all-admins" forRole="Admin">
  //         <AllAdmins />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/all-specialists" forRole="Admin">
  //         <AllSpecialists />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/all-students" forRole="Admin">
  //         <AllStudents />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/invite-admin" forRole="Admin">
  //         <InviteAdmin />
  //       </PrivatRoute>
  //       <PrivatRoute exact path="/invite-done" forRole="Admin">
  //         <InviteDone />
  //       </PrivatRoute>
  //       <PrivatRoute path="/student-info/:studentId" forRole="Admin">
  //         <AdminStudentInformation />
  //       </PrivatRoute>
  //       <PrivatRoute path="/specialist-info/:specialistId" forRole="Admin">
  //         <AdminSpecialistInformation />
  //       </PrivatRoute>
  //       <PrivatRoute path="/admin-personal-info" forRole="Admin">
  //         <AdminPersonalInfoEdit />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/admin-upcoming-session/:sessionId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <AdminUpcomingSesssion />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/admin-in-progress-session/:sessionId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <AdminInProgressSession />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/admin-complited-session/:sessionId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <AdminComplitedSession />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/admin-join-session/:sessionId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <AdminJoinSession />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/successful-session-cancellation`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <SuccessfulSessionCancellation />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/group-upcoming-session/:sessionId`}
  //         exact
  //         forRole="Specialist"
  //       >
  //         <GroupUpcomingSession />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/coupons`} exact forRole="Admin">
  //         <Coupons />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/create-coupon`} exact forRole="Admin">
  //         <CreateCoupon />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/created-coupon`} exact forRole="Admin">
  //         <CreatedCoupon />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/admin-payments`} exact forRole="Admin">
  //         <AdminPayments />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/admin-new-specialist/:specialistId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <AdminNewSesialist />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/sucsess-specialist-approve/:specialistId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <SucsessSpecialistApprove />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/sucsess-specialist-decline/:specialistId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <SucsessSpecialistDecline />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/sucsess-specialist-verify/:specialistId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <SucsessSpecialistVerify />
  //       </PrivatRoute>
  //       <PrivatRoute
  //         path={`/checkr-see-details/:reportId`}
  //         exact
  //         forRole="Admin"
  //       >
  //         <CheckrSeeDetails />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/props`} exact forRole="Admin">
  //         <Props />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/add-prop`} forRole="Admin">
  //         <AddPropCategory />
  //       </PrivatRoute>
  //       <PrivatRoute path={`/edit-category/:categoryId`} forRole="Admin">
  //         <EditCategory />
  //       </PrivatRoute>
  //       <Route exact path="/after-reg">
  //         <AfterSpecialistRegistration setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <Route exact path="/privacy-policy">
  //         <PrivacyPolicy setNoNavBar={setNoNavBar} />
  //       </Route>
  //       <PrivatRoute path="/admin-info/:adminId" forRole="Admin">
  //         <AdminInformationPage />
  //       </PrivatRoute>
  //       <Route exact path="/temp-login">
  //         <TemporaryLoginLink setNoNavBar={setNoNavBar} />
  //       </Route>
  //     </Switch>
  //     {!cookies && <CookiesComponent setCookies={setCookies} />}
  //   </Router>
  // );
}

export default App;
