import React from "react";

function LeftArroySVG(props) {
  return (
    <svg
      onClick={props.onClick} 
      width={props.size}
      height={props.size} 
      viewBox="0 0 24 24" 
      fill={props.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22 12.4854L2 12.4854" stroke="#2B4BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.75 22.4854L2.00003 12.4854L10.75 2.48535" stroke="#2B4BF2" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

LeftArroySVG.defaultProps = {
  color: 'none',
  size: 24,
  onClick: ()=>{}
}
  
export default LeftArroySVG