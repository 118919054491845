import React, { useState } from "react";
import style from "../Email/email.module.scss";
import { SERVER_IP } from "../../path";
import emailService from "../../services/email.service";
import { emailReg } from "../../const/regular";
import BlueButton from "../uiComponents/buttons/BlueButton/BlueButton";

function Email({
  setButtonRegisterDisabled,
  setButtonRegisterClassName,
  userEmail,
  setUserEmail,
}) {
  const [emailNotification, setEmailNotification] = useState(
    "We will send code to confirm your email"
  );
  const [emailField, setEmailField] = useState(false);
  const [emailPlaceholder, setemailPlaceholder] = useState(
    "vriends@example.com"
  );
  const [input, setInput] = useState("");
  const [codeInput, setCodeInput] = useState("");

  function handleChangeEmail(event) {
    if (canChangeEmail === true) {
      const dataInput = event.target.value;
      setInput(dataInput);
      setUserEmail(dataInput);
    } else {
      setInput(event.target.value);
      setCodeInput(event.target.value);
    }
  }
  const [buttonClass, setButtonClass] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [buttonCodeDisabled, setButtonCodeDisabled] = useState(true);
  const [errorBlock, setErrorBlock] = useState("Error explonation");
  const [emailConfirm, setEmailConfirm] = useState(false);

  function onFocusButtonEmail() {
    setSendCodeAgain(false);
    setButtonClass("buttonEmail");
    setEmailField("emailFocus");
    setEmailError(false);
    setButtonCodeDisabled(false);
  }

  let responseBodyObject;
  let responseStatus;

  const [canChangeEmail, setCanChangeEmail] = useState(true);

  const [sendCodeAgain, setSendCodeAgain] = useState(false);

  const [canSendCodeAgain, setCanSendCodeAgain] = useState(false);

  const [buttonEmailName, setButtonEmailName] = useState("Send code");

  function generateCode() {
    setInput("");
    try {
      emailService.generateEmailCode(userEmail).then((response) => {
        setCanChangeEmail(false);
        return response.data;
      });
    } catch (error) {
      console.log(error);
    }
  }

  function confirmCode() {
    emailService
      .confirmEmail(userEmail, codeInput)
      .then((response) => {
        if (response) {
          setEmailConfirm(true);
          setSendCodeAgain(true);
          setCanSendCodeAgain(false);
          setButtonRegisterDisabled(false);
          setButtonRegisterClassName("activeButton");
        }
      })
      .catch((error) => {
        if (error.response) {
          if (
            error.response.data.errorCode === 702 ||
            error.response.data.errorCode === 705
          ) {
            setEmailField("emailError");
            setButtonCodeDisabled(true);
            setErrorBlock("Sorry, the code is wrong");
            setEmailError(true);
            setButtonClass("");
            setSendCodeAgain(false);
            setCanSendCodeAgain(true);
          } else {
            setEmailField("emailError");
            setButtonCodeDisabled(true);
            setErrorBlock("Sorry, the code is expired");
            setEmailError(true);
            setButtonClass("");
            setSendCodeAgain(false);
            setCanSendCodeAgain(true);
          }
        } else {
          setEmailField("emailError");
          setButtonCodeDisabled(true);
          setErrorBlock("Sorry, the code is expired");
          setEmailError(true);
          setButtonClass("");
          setSendCodeAgain(false);
          setCanSendCodeAgain(true);
        }
      });
  }

  function checkEmailCode() {
    if (buttonEmailName === "Send code") {
      if (emailReg.test(input) === false) {
        setEmailField("emailError");
        setEmailError(true);
        setButtonCodeDisabled(true);
        setButtonClass("");
      } else {
        setUserEmail(input);
        generateCode();
        setEmailNotification(
          `We sent code to ${userEmail}.  It will work for 2 minutes.`
        );
        setemailPlaceholder("Code from the email");
        setButtonClass("");
        setButtonEmailName("Confirm email");
        setErrorBlock("Sorry, the code is wrong");
      }
    } else {
      confirmCode();
    }
  }

  return (
    <div className={style.emailWrapper}>
      <h3>Email</h3>
      {emailConfirm && (
        <div>
          <div className={style.confirmEmail}>
            <img src="/img/success.png" alt="success icon" />
            <p>Your email confirmed!</p>
          </div>
          <p className={style.wrapper}>{userEmail}</p>
          <p className={style.message__wrapper}>
            After completing this registration form, you’ll recieve documents,
            that need to be signed, so&nbsp;you can start enjoying the Vriends
            services.{" "}
          </p>
        </div>
      )}
      {!emailConfirm && (
        <>
          <p>{emailNotification}</p>
          <div className={style.flexContainer}>
            <div className={style.inputButtonWrapper}>
              <input
                className={style[emailField]}
                type="email"
                placeholder={emailPlaceholder}
                value={input}
                onChange={handleChangeEmail}
                onFocus={onFocusButtonEmail}
                required
              />
              <button
                onClick={checkEmailCode}
                className={style[buttonClass]}
                disabled={buttonCodeDisabled}
              >
                {buttonEmailName}
              </button>
            </div>
            {canSendCodeAgain && (
              <div className={style.warning}>
                Did not recieve the email? Check your spam filter or{" "}
                <a
                  onClick={() => {
                    generateCode();
                  }}
                >
                  send
                </a>{" "}
                it again.
              </div>
            )}
          </div>
        </>
      )}
      {emailError && <div className={style.errorMessage}>{errorBlock}</div>}
    </div>
  );
}

export default Email;
