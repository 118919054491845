import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import LeftArroySVG from "../../svg/LeftArroy/LeftArroySVG";
import RightArroySVG from "../../svg/RightArroy/RightArroySVG";
import style from "../FeedBack/feedBack.module.scss";
import StarComponent from '../StarComponent/StarComponent'
import axios from "axios";
import sessionService from "../../services/session.service";
import Avatar from "../uiComponents/Avatar/Avatar";
import StyledReactStars from "../uiComponents/StyledReactStars/StyledReactStars";

function FeedBack() {
  const { id } = useParams()

  const [feedbacks, setFeedbacks] = useState([])
  const [indexFeedbacks, setIndexFeedbacks] = useState(0)

  useEffect(async () => {
    if (id) {
      const response = await sessionService.getFeedbacksOfSpecialist(id)
      setFeedbacks(response)
    }
  }, [])

  function onClickLeft() {
    setIndexFeedbacks(prev => {
      if (prev === 0) {
        return feedbacks.length - 1
      } else {
        return prev - 1
      }
    })
  }

  function onClickRight() {
    setIndexFeedbacks(prev => {
      if (prev === feedbacks.length - 1) {
        return 0
      } else {
        return prev + 1
      }
    })
  }

  return (
    <>
      <div>
        {
          feedbacks?.map((el, i) => {
            return (
              <div className={style.feedbackBlock} style={{ display: i === indexFeedbacks ? "flex" : "none" }}>
                <button className={style.leftArroy}>
                  <LeftArroySVG onClick={onClickLeft} />
                </button>
                <div className={style.wrapper}>
                  <StyledReactStars className={style.feedback__stars} value={el.rating} starsColor={'#2B4BF2'} edit={false}/>
                  {/* <StarComponent rating={el.rating} /> */}
                  <p className={style.wrapper__txt}>{el.feedback}</p>
                  <div className={style.textFeedback}>
                    <Avatar
                      className={style.imgFeedback}
                      photoUrl="/img/Bear-small.png"
                    />
                    <p>{el.firstName}</p>
                  </div>
                </div>
                <button className={style.rightArroy}>
                  <RightArroySVG onClick={onClickRight} />
                </button>
              </div>
            )
          })
        }
      </div>
    </>
  );
}

export default FeedBack;
