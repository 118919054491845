import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { getCurrentSpecialist } from "../../redux/actions/currentSpecialist.action";
import style from "../NavigationVRauthSpecialist/NavigationVRauthSpecialist.module.scss";
import AuthenticatedUserInformationService from "../../services/authenticatedUserInformation.service";
import DayPickerInput from "react-day-picker/DayPickerInput";
import "react-day-picker/lib/style.css";
import authinticationService from "../../services/Authentication.service";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { dataWithoutZeroReg } from "../../const/regular";
import { setSessionsDayForSearchInAdminSessions } from "../../redux/actionCreators/auxiliaryReducer.actionCreator";
import Avatar from "../uiComponents/Avatar/Avatar";
import "./NavigationVRauthSpecialist.css";
import BurgerCloseSVG from "../../svg/BurgerCloseSVG/BurgerCloseSVG";
import BurgerSVG from "../../svg/BurgerSVG/BurgerSVG";
import LogoSVG from "../../svg/LogoSVG/LogoSVG";

function NavigationVRauthSpecialistSessions() {
  const location = useLocation();

  // const [barStyle, setBarStyle] = useState({
  //   style: "link",
  //   visibleInput: false,
  // });

  let accessToken = AuthenticatedUserInformationService.getUserToken();

  let specialistId = AuthenticatedUserInformationService.getUserId();

  function logout() {
    authinticationService.logout();
  }
  const dispatch = useDispatch();

  const { currentSpecialist } = useSelector((state) => state.currentSpecialist);
  // const { sessionsDayForSearchInAdminSesseions } = useSelector(
  //   (state) => state.auxiliaryReducer
  // );

  // useEffect(() => {
  //   if (location.pathname === "/specialist-sessions") {
  //     setBarStyle({
  //       style: "linkSessions",
  //       visibleInput: true,
  //     });
  //   } else {
  //     setBarStyle({
  //       style: "link",
  //       visibleInput: false,
  //     });
  //   }
  // }, [location]);

  useEffect(() => {
    dispatch(getCurrentSpecialist());
  }, []);

  function getData(day, modifiers, dayPickerInput) {
    const inputData = dayPickerInput.state.value;
    if (dataWithoutZeroReg.test(inputData)) {
      dispatch(setSessionsDayForSearchInAdminSessions(inputData));
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const openMenu = () => {
    setIsOpen(!isOpen);
    if (isOpen === true) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div className={style.navigation}>
      <Avatar className={style.avatar} photoUrl={currentSpecialist?.photoUrl} />
      <div className={style.link}>
        <Link onClick={logout}>Log Out</Link>
        <NavLink to="/edit-specialist" activeClassName="navigationActiveLink">
          Personal info
        </NavLink>
        <NavLink to="/availability" activeClassName="navigationActiveLink">
          My availability
        </NavLink>
        <NavLink to="/faq" activeClassName="navigationActiveLink">
          FAQ
        </NavLink>
        <NavLink to="/vr-specialist" activeClassName="navigationActiveLink">
          VR devices
        </NavLink>
        <NavLink
          to="/specialist-sessions"
          activeClassName="navigationActiveLink"
        >
          Sessions
        </NavLink>
      </div>
      <div className={style.logo} onClick={closeMenu}>
        <Link to="/specialist-sessions">
          {/* <img src="/img/logo.png" alt="logo" onClick={closeMenu} /> */}
          <LogoSVG />
        </Link>
      </div>

      <div className={style.burger__item} onClick={openMenu}>
        {isOpen ? <BurgerCloseSVG /> : <BurgerSVG />}
      </div>

      <div
        className={style.burger__menu}
        style={isOpen ? { dispalay: "flex" } : { display: "none" }}
      >
        <hr className={style.hr} />
        <NavLink
          to="/edit-specialist"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Personal info
        </NavLink>
        <NavLink
          to="/availability"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          My availability
        </NavLink>
        <NavLink
          to="/faq"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          FAQ
        </NavLink>
        <NavLink
          to="/vr-specialist"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          VR devices
        </NavLink>
        <NavLink
          to="/specialist-sessions"
          activeClassName="navigationActiveLink"
          onClick={openMenu}
        >
          Sessions
        </NavLink>
        <Link onClick={logout}>Log Out</Link>
      </div>
    </div>
  );
}

export default NavigationVRauthSpecialistSessions;
