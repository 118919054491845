function BurgerCloseSVGSpec() {
  return (
    <svg
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.000732422"
        y="26.1626"
        width="37"
        height="5.41935"
        rx="2.70968"
        transform="rotate(-45 0.000732422 26.1626)"
        fill="#d9d9d9"
      />
      <rect
        x="4.83179"
        y="-0.000244141"
        width="37"
        height="5.41935"
        rx="2.70968"
        transform="rotate(45 4.83179 -0.000244141)"
        fill="#d9d9d9"
      />
    </svg>
  );
}

export default BurgerCloseSVGSpec;
