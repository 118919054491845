//Service for getting nedded information about users in storege
import { SERVER_IP } from "../path";

class AuthenticatedUserInformationService {

  getUserInfo = () => {
    let userInfo = JSON.parse(
      window.sessionStorage.getItem(
        `oidc.user:${SERVER_IP}:${process.env.REACT_APP_CLIENT_ID}`
      )
    );

    return userInfo;
  }

  isUserLogedIn = () => {
    const userInfo = this.getUserInfo();

    if (userInfo === null) {
      return false;
    } else {
      return true;
    }
  }

  getUserRole = () => {
    if (this.isUserLogedIn()) {
      const userInfo = this.getUserInfo();

      const userRole = userInfo?.profile?.role;

      return userRole;
    }
  };

  getUserToken = () => {
    if (this.isUserLogedIn()) {
      const userInfo = this.getUserInfo();

      const accessToken = userInfo?.access_token;

      return accessToken;
    }
  }

  getUserId = () => {
    if (this.isUserLogedIn()) {
      const userInfo = this.getUserInfo();

      const userId = userInfo?.profile.sub;

      return userId;
    }
  }
}

const authenticatedUserInformationService = new AuthenticatedUserInformationService();
export default authenticatedUserInformationService;