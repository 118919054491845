import React, { useState, useEffect } from "react";
import style from "../DateTimeLoose/dateTimeLoose.module.scss";
import CalendarSpecialistInfo from "../CalendarSpecailstInfo/CalendarSpecailstInfo";
import { timezoneOptions } from "../../timezoneOptions";

function DateTimeLoose({
  timeZone,
  workingDays,
  startDate,
  setStartDate,
  setTimeZone,
  getTimeSlots,
  isShowSelect = true,
  setDisabled,
  setStyleButton,
  setActiveTimeSlot,
  setIsLoadingTimeSlots,
  timeZoneName,
  currentDate,
  setCurrentDate,
}) {
  const [hyphen, setHyphen] = useState("");
  useEffect(() => {
    if (timeZone === "0") {
      setHyphen("-");
    } else {
      setHyphen("");
    }
  }, [timeZone]);

  
  function changeTimeZoneHandler (event) {
    setTimeZone(event.target.value);
    getTimeSlots()
  }

  return (
    <>
      <div className={style.wrapperTitle}>
        <h3>Dates and time</h3>
        {isShowSelect && (
          <select className={style.timeZone} onChange={changeTimeZoneHandler}>
            {timezoneOptions.map((option, i) => (
              <option key={i} value={option.value}>
                {option.key}
              </option>
            ))}
          </select>
        )}
        {!isShowSelect && (
          <p>
            GMT{hyphen}
            {timeZone} {timeZoneName}
          </p>
        )}
      </div>
      <CalendarSpecialistInfo
        timeZone={timeZone}
        workingDays={workingDays}
        startDate={startDate}
        setStartDate={setStartDate}
        getTimeSlots={getTimeSlots}
        setDisabled={setDisabled}
        setStyleButton={setStyleButton}
        setActiveTimeSlot={setActiveTimeSlot}
        setIsLoadingTimeSlots={setIsLoadingTimeSlots}
        currentDate={currentDate}
        setCurrentDate={setCurrentDate}
      />
    </>
  );
}

export default DateTimeLoose;
